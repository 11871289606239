import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Avatar,
  Button,
} from 'antd';
import '../Restaurant/Restaurant.css';
import customizationButton from '../../assests/customizationButton.svg';
import Back from '../../assests/Back.svg';
import { FiEdit2 } from 'react-icons/fi';
import Delete from '../../assests/Delete.svg';
import { falseyValueCases, ImageEnv } from '../CommonLogics/CommonMethods';
import {
  getCategoryItem,
  deleteCategoryItem,
} from '../../Actions/CategoryItemAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import menuButton from '../../assests/menuButton.svg';
import {
  deleteCustomization,
  getCustomizations,
} from '../../Actions/CustomizationAction';
const CustomizationDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    editId,
    setIsModalVisible,
    categoryDetailId,
    deleteCategoryItemId,
    currentPage,
    setIsType,
    isType,
  } = props;
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { orgId, categoryId, categoryItemId, customizationId } = useParams();

  const { Category, CategoryItem, setCategoryId, Customization } = useSelector(
    (state) => {
      const { Category, setCategoryId } = state.category;
      const { CategoryItem } = state.categoryItem;
      const { Customization } = state.customization;
      return {
        Category,
        CategoryItem,
        setCategoryId,
        Customization,
      };
    }
  );

  const handleCloseDrawer = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations`
    );
  };

  const onHandleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={true}
      width={width > 400 ? '400px' : '100%'}
    >
      {Customization && (
        <div>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>
                Customization Type
              </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {Customization?.customization_type}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>
                Customization Name{' '}
              </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {Customization?.name}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>
                Maximum selected items alloted
              </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {Customization?.max_selected}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Is required?</Typography>
              <Typography className='detailsubheading'>
                {' '}
                {Customization?.required ? 'Yes' : 'No'}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>
                Customization Items added
              </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {Customization?.customization_items?.length}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={16}>
              <Button
                size='large'
                className='detailsButton'
                onClick={() =>
                  onHandleNavigate(
                    `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations/${customizationId}/customizationItems`
                  )
                }
                icon={<img src={menuButton} alt='props' />}
              >
                Customization Items
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default CustomizationDetails;
