import API from '../Utils/API';

export const getTaxation = (url) => (dispatch) => {
  dispatch({ type: 'GET_TAXATION_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_TAXATION_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_TAXATION_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createTaxation =
  (data, successCreateTaxation, failureCreateTaxation) => (dispatch) => {
    dispatch({ type: 'CREATE_TAXATION_REQUEST' });
    API.post('api/taxations', data)
      .then((response) => {
        dispatch({ type: 'CREATE_TAXATION_SUCCESS', payload: response.data });
        successCreateTaxation && successCreateTaxation();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_TAXATION_FAILURE',
          payload: error.response.data,
        });
        failureCreateTaxation && failureCreateTaxation(error.response.data);
      });
  };
export const deleteTaxation =
  (TaxationId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_TAXATION_REQUEST' });
    API.delete(`api/taxations/${TaxationId}`)
      .then((response) => {
        dispatch({ type: 'DELETE_TAXATION_SUCCESS', payload: response.data });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_TAXATION_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateTaxation =
  (data, successUpdateCallBack, failureUpdateTaxation) => (dispatch) => {
    dispatch({ type: 'UPDATE_TAXATION_REQUEST' });
    API.put(`api/taxations/${data.id}`, data)
      .then((response) => {
        dispatch({ type: 'UPDATE_TAXATION_SUCCESS', payload: response.data });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_TAXATION_FAILURE',
          payload: error.response.data,
        });
        failureUpdateTaxation && failureUpdateTaxation(error.response.data);
      });
  };
export const getStateById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_TAXATION_BY_ID_REQUEST' });
  API.get(`api/taxations/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_TAXATION_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_TAXATION_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
