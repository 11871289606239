import React, { useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Button,
  Spin,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import Delete from '../../assests/Delete.svg';
import { FiEdit2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import menuButton from '../../assests/menuButton.svg';
import SvgComponent from '../../Utils/svgComponent';

import { useDispatch, useSelector } from 'react-redux';
import { deleteItemType, getItemTypes } from '../../Actions/itemTypesAction';
const ItemTypeDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    setIsModalVisible,
    itemTypeDetailId,
    currentPage,
  } = props;
  const navigate = useNavigate();
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { itemType, isItemTypeByIdloading } = useSelector((state) => {
    const { itemType, isItemTypeByIdloading } = state.itemType;
    return {
      itemType,
      isItemTypeByIdloading,
    };
  });
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setDrawerVisible(false);
  };
  const handleDelete = () => {
    dispatch(deleteItemType(itemTypeDetailId, successDelete, failureDelete));
  };
  const successDelete = () => {
    setDrawerVisible(false);
    dispatch(getItemTypes(`api/item_types?page=${currentPage}`));
    setTimeout(() => {
      message.success(`Item type Delete Successfully`);
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };

  return (
    <Spin spinning={isItemTypeByIdloading}>
      <Drawer
        closeIcon={<img src={Back} alt='props' />}
        closable={false}
        title={
          <div>
            <Row>
              <Col span={2}>
                <img
                  src={Back}
                  alt='props'
                  onClick={handleCloseDrawer}
                  style={{ cursor: 'pointer' }}
                />
              </Col>
              <Col span={22}>
                <Typography className='drawerHeading'>Show</Typography>
              </Col>
              <Col span={24} style={{ marginTop: '25px' }}>
                <Row>
                  <Col span={8}>
                    <Button
                      className='editButtonDrawer'
                      onClick={() => handleEdit(itemTypeDetailId, itemType)}
                    >
                      <FiEdit2 className='editIcon' />
                      Edit
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Popconfirm
                      title={
                        <Typography className='popconfirmTitle'>
                          Do you really want to delete this Category?
                        </Typography>
                      }
                      // icon={<Typography className='warning'>Warning!</Typography>}
                      onConfirm={() => handleDelete()}
                      okButtonProps={{ className: 'popconfirmButton' }}
                      cancelButtonProps={{ className: 'popconfirmButton' }}
                      okText='Confirm'
                      cancelText='Cancel'
                      placement='topLeft'
                    >
                      <Button className='deleteButtonDrawer'>
                        <img
                          src={Delete}
                          className='deleteIcon'
                          width='20px'
                          height='16px'
                        />
                        Delete
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        }
        placement='right'
        onClose={handleCloseDrawer}
        open={drawerVisible}
        width={width > 400 ? '400px' : '100%'}
      >
        {itemType && (
          <div>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>
                  Prest Admin icon
                </Typography>
                <Typography
                  className='detailsubheading'
                  style={{ width: '64px', height: '64px' }}
                >
                  {' '}
                  {itemType?.admin_type_image_url && (
                    <SvgComponent
                      url={itemType?.admin_type_image_url}
                    ></SvgComponent>
                  )}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>
                  Prest User icon
                </Typography>
                <Typography
                  className='detailsubheading'
                  style={{ width: '64px', height: '64px' }}
                >
                  {' '}
                  {itemType?.user_type_image_url && (
                    <SvgComponent
                      url={itemType?.user_type_image_url}
                    ></SvgComponent>
                  )}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>Name</Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {itemType?.name}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>Veg?</Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {itemType?.is_veg === true ? 'Yes' : 'No'}
                </Typography>
              </Col>
            </Row>
          </div>
        )}
      </Drawer>
    </Spin>
  );
};
export default ItemTypeDetails;
