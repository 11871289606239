import API from '../Utils/API';

export const getCustomizations = (url) => (dispatch) => {
  dispatch({ type: 'GET_CUSTOMIZATION_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_CUSTOMIZATION_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_CUSTOMIZATION_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createCustomization =
  (data, successCreateCustomization, failureCreateCustomization) =>
  (dispatch) => {
    dispatch({ type: 'CREATE_CUSTOMIZATION_REQUEST' });
    API.post('api/customizations', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_CUSTOMIZATION_SUCCESS',
          payload: response.data,
        });
        successCreateCustomization && successCreateCustomization(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_CUSTOMIZATION_FAILURE',
          payload: error.response.data,
        });
        failureCreateCustomization &&
          failureCreateCustomization(error.response.data);
      });
  };
export const deleteCustomization =
  (CustomizationId, successDeleteCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_CUSTOMIZATION_REQUEST' });
    API.delete(`api/customizations/${CustomizationId}`)
      .then((response) => {
        dispatch({
          type: 'DELETE_CUSTOMIZATION_SUCCESS',
          payload: response?.data,
        });
        successDeleteCallback && successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_CUSTOMIZATION_FAILURE',
          payload: error?.response?.data,
        });
        failureCallback && failureCallback();
      });
  };

export const updateCustomization =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_CUSTOMIZATION_REQUEST' });
    API.put(`api/customizations/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_CUSTOMIZATION_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_CUSTOMIZATION_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const publishCustomization =
  (data, successPublishCallBack) => (dispatch) => {
    dispatch({ type: 'PUBLISH_CUSTOMIZATION_REQUEST' });
    API.put(`api/published_customizations/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'PUBLISH_CUSTOMIZATION_SUCCESS',
          payload: response.data,
        });
        successPublishCallBack && successPublishCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'PUBLISH_CUSTOMIZATION_FAILURE',
          payload: error.response.data,
        });
      });
  };
export const getCustomizationById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_CUSTOMIZATION_BY_ID_REQUEST' });
  API.get(`api/customizations/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_CUSTOMIZATION_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CUSTOMIZATION_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const getOrganizationCustomizations =
  (url, successCallback) => (dispatch) => {
    dispatch({ type: 'GET_ORGANIZATION_CUSTOMIZATION_REQUEST' });
    API.get(url)
      .then((response) => {
        dispatch({
          type: 'GET_ORGANIZATION_CUSTOMIZATION_SUCCESS',
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        try {
          dispatch({
            type: 'GET_ORGANIZATION_CUSTOMIZATION_FAILURE',
            payload: error.response.data,
          });
        } catch {}
      });
  };
export const copyCustomization =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'COPY_CUSTOMIZATION_REQUEST' });
    API.post(`api/copy_customizations`, data)
      .then((response) => {
        dispatch({
          type: 'COPY_CUSTOMIZATION_SUCCESS',
          payload: response.data,
        });
        successCallback && successCallback(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'COPY_CUSTOMIZATION_FAILURE',
          payload: error?.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
export const reOrderCustomization =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'REORDER_CUSTOMIZATION_REQUEST' });
    API.put(`api/re_order_customization/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'REORDER_CUSTOMIZATION_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'REORDER_CUSTOMIZATION_FAILURE',
          payload: error.response.data,
        });
      });
  };
