import React, { useEffect, useState } from 'react';
import { Row, Col, Drawer, Typography, message, Avatar, Button } from 'antd';
import '../Restaurant/Restaurant.css';
import customizationButton from '../../assests/customizationButton.svg';
import Back from '../../assests/Back.svg';
import { falseyValueCases, ImageEnv } from '../CommonLogics/CommonMethods';
import {
  getCategoryItem,
  deleteCategoryItem,
} from '../../Actions/CategoryItemAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import SvgComponent from '../../Utils/svgComponent';
import ReactPlayer from 'react-player';
import '@google/model-viewer';
const CategoryItemDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    editId,
    setIsModalVisible,
    categoryDetailId,
    deleteCategoryItemId,
    currentPage,
    setIsType,
    isType,
  } = props;
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { orgId, categoryId, categoryItemId } = useParams();

  const handleCloseDrawer = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}/categoryItems`);
  };
  const { Category, CategoryItem, setCategoryId } = useSelector((state) => {
    const { Category, setCategoryId } = state.category;
    const { CategoryItem } = state.categoryItem;
    return {
      Category,
      CategoryItem,
      setCategoryId,
    };
  });

  const handleEdit = (id) => {
    setIsType('new_item');
    setEditId(id);
    setIsModalVisible(true);
    setDrawerVisible(false);
  };
  const handleDelete = () => {
    dispatch(
      deleteCategoryItem(deleteCategoryItemId, successDelete, failureDelete)
    );
  };
  const successDelete = () => {
    setDrawerVisible(false);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
    setTimeout(() => {
      message.success(`Category Delete Successfully`);
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const onHandleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={!falseyValueCases(drawerVisible) ? drawerVisible : true}
      width={width > 400 ? '400px' : '100%'}
    >
      {Category && (
        <div>
          <Row
            gutter={[10, 10]}
            style={{ marginTop: '10px', marginBottom: '25px' }}
          >
            {CategoryItem?.item_image_url && (
              <Col
                style={{
                  width: '100px',
                  height: '100px',
                }}
                span={8}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${CategoryItem?.item_image_url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: '10px',
                    overflow: 'hidden',
                  }}
                ></div>
              </Col>
            )}
            {CategoryItem?.item_3d_image_url && (
              <Col span={8}>
                <model-viewer
                  src={CategoryItem?.item_3d_image_url}
                  alt='A 3D model'
                  auto-rotate
                  camera-controls
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '10px',
                  }}
                ></model-viewer>
              </Col>
            )}
            {CategoryItem?.item_video_url && (
              <Col
                style={{
                  width: '100px',
                  height: '100px',
                }}
                span={8}
              >
                <ReactPlayer
                  url={CategoryItem?.item_video_url}
                  controls={true}
                  width='100%'
                  height='100%'
                  style={{
                    borderRadius: '10px',
                    overflow: 'hidden',
                  }}
                />
              </Col>
            )}
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={16}>
              <Typography className='categoryItemHeading'>
                {CategoryItem?.item?.name}
              </Typography>
            </Col>
            <Col span={8} style={{ textAlign: 'end' }}>
              <Typography className='categoryItemHeading'>
                ₹{CategoryItem?.item?.display_price}
              </Typography>
              <Typography className='categoryItemDetailsType'>
                {CategoryItem?.item?.is_gst && '(Taxable Item)'}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Row>
                <Col span={9}>
                  <Typography className='detailsubheading2'>
                    Contains:
                  </Typography>
                </Col>
                <Col span={15}>
                  <Row>
                    {CategoryItem?.item?.items_item_types.map((data, i) => (
                      <Col span={3} key={i} style={{ marginBottom: '10px' }}>
                        <SvgComponent
                          url={data?.admin_type_image_url}
                          width='20px'
                          height='15px'
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Row>
                <Col span={9}>
                  <Typography className='detailsubheading2'>
                    Category:
                  </Typography>
                </Col>
                <Col span={15}>
                  <Typography className='detailsubheading'>
                    {Category?.name}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Row>
                <Col span={9}>
                  <Typography className='detailsubheading2'>
                    Customization:
                  </Typography>
                </Col>
                <Col span={15}>
                  <Typography>
                    {CategoryItem?.customizations?.map((customization, i) => (
                      <Col span={24} style={{ marginBottom: '10px' }} key={i}>
                        <Row>
                          <Col>
                            <Typography className='detailsubheading'>
                              {customization?.name}
                            </Typography>
                          </Col>
                          <Col span={16}>
                            <Typography className='categoryItemDetailsType'>
                              ({customization?.customization_type})
                            </Typography>
                          </Col>
                          <Col>
                            <Typography className='categoryItemDetailsMax'>
                              Select any {customization?.max_selected}
                            </Typography>
                          </Col>
                          <Col span={24} style={{ marginTop: '10px' }}>
                            {customization?.customization_items?.map(
                              (customizationItem, i) => (
                                <Col span={24} key={i}>
                                  <Row className='customizationItemDetails'>
                                    <Col span={17}>
                                      <Typography>
                                        {customizationItem?.name}
                                      </Typography>
                                    </Col>
                                    <Col>
                                      <Typography>
                                        ₹{customizationItem?.display_price}
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Col>
                              )
                            )}
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Row>
                <Col span={9}>
                  <Typography className='detailsubheading2'>
                    Description:
                  </Typography>
                </Col>
                <Col span={15}>
                  <Typography className='customizationItemDetails'>
                    {CategoryItem?.item?.description}
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={14}>
              <Button
                size='large'
                className='detailsButton'
                onClick={() =>
                  onHandleNavigate(
                    `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations`
                  )
                }
                icon={<img src={customizationButton} alt='props' />}
              >
                Customizations
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default CategoryItemDetails;
