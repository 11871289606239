import API from '../Utils/API';

export const getCfdImages = (url) => (dispatch) => {
  dispatch({ type: 'GET_CFD_IMAGES_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_CFD_IMAGES_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_CFD_IMAGES_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const createCfdImage =
  (data, successCreateCfdImage, failureCreateCfdImage) => (dispatch) => {
    dispatch({ type: 'CREATE_CFD_IMAGES_REQUEST' });
    let formData = new FormData();
    data.screen_image &&
      formData.append(
        'organization_carousel_screen[screen_image]',
        data.screen_image
      );
    API.post('api/organization_carousel_screens', formData)
      .then((response) => {
        dispatch({
          type: 'CREATE_CFD_IMAGES_SUCCESS',
          payload: response.data,
        });
        successCreateCfdImage && successCreateCfdImage();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_CFD_IMAGES_FAILURE',
          payload: error.response.data,
        });
        failureCreateCfdImage && failureCreateCfdImage(error.response.data);
      });
  };

export const deleteCfdImage = (cfdId, successDeleteCallback) => (dispatch) => {
  dispatch({ type: 'DELETE_CFD_IMAGES_REQUEST' });
  API.delete(`/api/organization_carousel_screens/${cfdId}`)
    .then((response) => {
      dispatch({ type: 'DELETE_CFD_IMAGES_SUCCESS', payload: response.data });
      successDeleteCallback();
    })
    .catch((error) => {
      dispatch({
        type: 'DELETE_CFD_IMAGES_FAILURE',
        payload: error.response.data,
      });
    });
};

export const updateCfdImage = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_CFD_IMAGES_REQUEST' });
  let formData = new FormData();
  data.screen_image &&
    formData.append(
      'organization_carousel_screen[screen_image]',
      data.screen_image
    );
  API.put(`api/organization_carousel_screens/${data.id}`, formData)
    .then((response) => {
      dispatch({ type: 'UPDATE_CFD_IMAGES_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_CFD_IMAGES_FAILURE',
        payload: error.response.data,
      });
    });
};

export const getCfdImageById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_CFD_IMAGES_BY_ID_REQUEST' });
  API.get(`api/organization_carousel_screens/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_CFD_IMAGES_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CFD_IMAGES_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};

export const reOrderCfdImage = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'REORDER_CFD_IMAGES_REQUEST' });
  API.put(`api/re_order_organization_carousel_screen/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'REORDER_CFD_IMAGES_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack(response.data);
    })
    .catch((error) => {
      dispatch({
        type: 'REORDER_CFD_IMAGES_FAILURE',
        payload: error.response.data,
      });
    });
};
