import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Button,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import Delete from '../../assests/Delete.svg';
import { FiEdit2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { deleteItemType, getItemTypes } from '../../Actions/itemTypesAction';
import { deleteCfdImage, getCfdImages } from '../../Actions/CfdImagesAction';
const CfdImageDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    setIsModalVisible,
    cfdImageId,
  } = props;
  const navigate = useNavigate();
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const { allCfdImages, isCfdImagesLoading } = useSelector((state) => {
    const { allCfdImages, isCfdImagesLoading } = state.CfdImages;
    return {
      allCfdImages,
      isCfdImagesLoading,
    };
  });
  const the_cfd_image = _.filter(allCfdImages, function (o) {
    return o.id === cfdImageId;
  });
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setDrawerVisible(false);
  };
  const handleDelete = () => {
    dispatch(deleteCfdImage(cfdImageId, successDelete, failureDelete));
  };
  const successDelete = () => {
    setDrawerVisible(false);
    dispatch(getCfdImages(`api/organization_carousel_screens`));
    setTimeout(() => {
      message.success(`Item type Delete Successfully`);
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
            <Col span={24} style={{ marginTop: '25px' }}>
              <Row>
                <Col span={8}>
                  <Button
                    className='editButtonDrawer'
                    onClick={() => handleEdit(cfdImageId)}
                  >
                    <FiEdit2 className='editIcon' />
                    Edit
                  </Button>
                </Col>
                <Col span={8}>
                  <Popconfirm
                    title={
                      <Typography className='popconfirmTitle'>
                        Do you really want to delete this Category?
                      </Typography>
                    }
                    onConfirm={() => handleDelete()}
                    okButtonProps={{ className: 'popconfirmButton' }}
                    cancelButtonProps={{ className: 'popconfirmButton' }}
                    okText='Confirm'
                    cancelText='Cancel'
                    placement='topLeft'
                  >
                    <Button className='deleteButtonDrawer'>
                      <img
                        src={Delete}
                        className='deleteIcon'
                        width='20px'
                        height='16px'
                      />
                      Delete
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {the_cfd_image && (
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            {the_cfd_image[0]?.screen_image_url && (
              <img
                src={the_cfd_image[0]?.screen_image_url}
                height='200px'
                width='300px'
              />
            )}
          </Col>
        </Row>
      )}
    </Drawer>
  );
};
export default CfdImageDetails;
