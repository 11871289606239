import constants from '../Utils/constants';
const initialState = {
  isSurveyQuestionloading: false,
  allSurveyQuestion: [],
  isCreateSurveyQuestionoading: false,
  isDeleteSurveyQuestionLoading: false,
  isUpdateSurveyQuestionLoading: false,
  setSurveyQuestionId: '',
  SurveyQuestion: {},
  isSurveyQuestionByIdLoading: false,
  Error: '',
};

export const surveyQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SURVEY_QUESTION_REQUEST:
      return {
        ...state,
        isSurveyQuestionloading: true,
      };
    case constants.GET_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        isSurveyQuestionloading: false,
        allSurveyQuestion: action.payload,
      };
    case constants.GET_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        isSurveyQuestionloading: false,
      };
    case constants.CREATE_SURVEY_QUESTION_REQUEST:
      return {
        ...state,
        isCreateSurveyQuestionoading: true,
      };
    case constants.CREATE_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        isCreateSurveyQuestionoading: false,
      };
    case constants.CREATE_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        isCreateSurveyQuestionoading: false,
        Error: action.payload?.name,
      };
    case constants.DELETE_SURVEY_QUESTION_REQUEST:
      return {
        ...state,
        isDeleteSurveyQuestionLoading: true,
      };
    case constants.DELETE_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        isDeleteSurveyQuestionLoading: false,
      };
    case constants.DELETE_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        isDeleteSurveyQuestionLoading: false,
      };
    case constants.UPDATE_SURVEY_QUESTION_REQUEST:
      return {
        ...state,
        isUpdateSurveyQuestionLoading: true,
      };
    case constants.UPDATE_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        isUpdateSurveyQuestionLoading: false,
      };
    case constants.UPDATE_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        isUpdateSurveyQuestionLoading: false,
      };
    // case constants.REORDER_SURVEY_QUESTION_REQUEST:
    //   return {
    //     ...state,
    //     isUpdateSurveyQuestionLoading: true,
    //   };
    // case constants.REORDER_SURVEY_QUESTION_SUCCESS:
    //   return {
    //     ...state,
    //     isUpdateSurveyQuestionLoading: false,
    //   };
    // case constants.REORDER_SURVEY_QUESTION_FAILURE:
    //   return {
    //     ...state,
    //     isUpdateSurveyQuestionLoading: false,
    //   };
    case constants.GET_SURVEY_QUESTION_BY_ID_REQUEST:
      return {
        ...state,
        isSurveyQuestionByIdLoading: true,
      };
    case constants.GET_QR_CODES_BY_ID_SUCCESS:
      return {
        ...state,
        isSurveyQuestionByIdLoading: false,
        SurveyQuestion: action.payload,
      };
    case constants.GET_QR_CODES_BY_ID_FAILURE:
      return {
        ...state,
        isSurveyQuestionByIdLoading: false,
      };
    case constants.GET_SELECTED_QR_CODES_ID:
      return {
        ...state,
        setSurveyQuestionId: action.payload,
      };
    default:
      return state;
  }
};
