import API from '../Utils/API';

export const getOrderProcess = (url, successCallback) => (dispatch) => {
  dispatch({ type: 'GET_ORDER_PROCESS_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_ORDER_PROCESS_SUCCESS', payload: response.data });
      successCallback && successCallback();
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_ORDER_PROCESS_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createOrderProcess =
  (data, successCreateOrderProcess, failureCreateOrderProcess) =>
  (dispatch) => {
    dispatch({ type: 'CREATE_ORDER_PROCESS_REQUEST' });
    API.post('api/organization_order_processes', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_ORDER_PROCESS_SUCCESS',
          payload: response.data,
        });
        successCreateOrderProcess && successCreateOrderProcess();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_ORDER_PROCESS_FAILURE',
          payload: error.response.data,
        });
        failureCreateOrderProcess &&
          failureCreateOrderProcess(error.response.data);
      });
  };
export const deleteOrderProcess =
  (OrderProcessId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_ORDER_PROCESS_REQUEST' });
    API.delete(`api/organization_order_processes/${OrderProcessId}`)
      .then((response) => {
        dispatch({
          type: 'DELETE_ORDER_PROCESS_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_ORDER_PROCESS_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateOrderProcess =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_ORDER_PROCESS_REQUEST' });
    API.put(`api/organization_order_processes/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_ORDER_PROCESS_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_ORDER_PROCESS_FAILURE',
          payload: error.response.data,
        });
      });
  };
export const getOrderProcessById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_ORDER_PROCESS_BY_ID_REQUEST' });
  API.get(`api/organization_order_processes/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_ORDER_PROCESS_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_ORDER_PROCESS_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const reOrderOrderProcess =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'REORDER_ORDER_PROCESS_REQUEST' });
    API.put(`api/re_order_process/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'REORDER_ORDER_PROCESS_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'REORDER_ORDER_PROCESS_FAILURE',
          payload: error.response.data,
        });
      });
  };
