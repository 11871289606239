import constants from '../Utils/constants';
const initialState = {
  isCustomizationloading: false,
  allCustomization: [],
  allOrganizationCustomization: [],
  isCreateCustomizationloading: false,
  isDeleteCustomizationLoading: false,
  isUpdateCustomizationLoading: false,
  isPublishCustomizationLoading: false,
  isCustomizationByIdLoading: false,
  Customization: {},
  isCustomizationByIdLoading: false,
  isReorderCustomizationLoading: false,
};

export const customizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        isCustomizationloading: true,
      };
    case constants.GET_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        isCustomizationloading: false,
        allCustomization: action.payload,
      };
    case constants.GET_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        isCustomizationloading: false,
      };
    case constants.CREATE_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        isCreateCustomizationloading: true,
      };
    case constants.CREATE_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        isCreateCustomizationloading: false,
      };
    case constants.CREATE_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        isCreateCustomizationloading: false,
      };
    case constants.DELETE_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        isDeleteCustomizationLoading: true,
      };
    case constants.DELETE_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        isDeleteCustomizationLoading: false,
      };
    case constants.DELETE_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        isDeleteCustomizationLoading: false,
      };
    case constants.UPDATE_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        isUpdateCustomizationLoading: true,
      };
    case constants.UPDATE_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        isUpdateCustomizationLoading: false,
      };
    case constants.UPDATE_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        isUpdateCustomizationLoading: false,
      };
    case constants.PUBLISH_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        isPublishCustomizationLoading: true,
      };
    case constants.PUBLISH_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        isPublishCustomizationLoading: false,
      };
    case constants.PUBLISH_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        isPublishCustomizationLoading: false,
      };
    case constants.GET_CUSTOMIZATION_BY_ID_REQUEST:
      return {
        ...state,
        isCustomizationByIdLoading: true,
      };
    case constants.GET_CUSTOMIZATION_BY_ID_SUCCESS:
      return {
        ...state,
        isCustomizationByIdLoading: false,
        Customization: action?.payload,
      };
    case constants.GET_CUSTOMIZATION_BY_ID_FAILURE:
      return {
        ...state,
        isCustomizationByIdLoading: false,
      };
    case constants.GET_ORGANIZATION_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        isCustomizationloading: true,
      };
    case constants.GET_ORGANIZATION_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        isCustomizationloading: false,
        allOrganizationCustomization: action.payload,
      };
    case constants.GET_ORGANIZATION_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        isCustomizationloading: false,
      };
    case constants.COPY_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        isCreateCustomizationloading: true,
      };
    case constants.COPY_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        isCreateCustomizationloading: false,
      };
    case constants.COPY_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        isCreateCustomizationloading: false,
      };
    case constants.REORDER_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        isReorderCustomizationLoading: true,
      };
    case constants.REORDER_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        isReorderCustomizationLoading: false,
      };
    case constants.REORDER_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        isReorderCustomizationLoading: false,
      };
    default:
      return state;
  }
};
