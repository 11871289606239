import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
} from 'antd';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import publish from '../../assests/publish.svg';
import unpublish from '../../assests/unpublish.svg';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getPaymentMode,
  deletePaymentMode,
  getPaymentModeById,
  updatePaymentMode,
} from '../../Actions/paymentModeAction';
import { useNavigate } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import filtericon from '../../assests/filtericon.svg';
import dragvertical from '../../assests/dragvertical.svg';
import PaymentModeModalForm from './PaymentModeModalForm';
import PaymentModeDetails from './PaymentModeDetails';
const PaymentMode = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [paymentModeDetailId, setpaymentModeDetailId] = useState('');
  let UnpublishData = {};
  let PublishData = {};
  const { isPaymentModeloading, restaurant, setRestaurantId, allPaymentMode } =
    useSelector((state) => {
      const { restaurant, setRestaurantId } = state.restaurant;
      const { allPaymentMode, isPaymentModeloading } = state.paymentMode;
      return {
        restaurant,
        isPaymentModeloading,
        setRestaurantId,
        allPaymentMode,
      };
    });
  const { length } = allPaymentMode || {};
  useEffect(() => {
    dispatch(getPaymentMode(`api/payment_modes?page=${currentPage}`));
  }, [dispatch, currentPage]);
  const handleSortButtonClick = () => {
    const sortedResults = [...allPaymentMode].sort((a, b) => {
      const nameA = a.mode.toLowerCase();
      const nameB = b.mode.toLowerCase();
      if (sortOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setSortedData(sortedResults);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`PaymentMode Delete Successfully`);
    }, 1000);
    dispatch(getPaymentMode(`api/payment_modes?page=${currentPage}`));
  };
  const handleOpen = (Id) => {
    setpaymentModeDetailId(Id);
    localStorage.setItem('organization_id', setRestaurantId);
    dispatch(getPaymentModeById(Id));
    setDrawerVisible(true);
    dispatch({
      type: 'GET_SELECTED_PAYMENT_MODE_ID',
      payload: Id,
    });
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getPaymentModeById(''));
    dispatch({
      type: 'GET_SELECTED_PAYMENT_MODE_ID',
      payload: '',
    });
  };
  const handlePublishMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updatePaymentMode(PublishData, successUpdatePublishMethod, faliureDelete)
    );
  };
  const handleUnpublishMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updatePaymentMode(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success('Your Payment Mode Published Successfully');
    }, 1000);
    dispatch(getPaymentMode(`api/payment_modes?page=${currentPage}`));
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success('Your Payment Mode Unpublished Successfully');
    }, 1000);
    dispatch(getPaymentMode(`api/payment_modes?page=${currentPage}`));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const Columns = [
    {
      title: 'Mode',
      dataIndex: 'mode',
      width: '10%',
      render: (Id, { id, mode }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${mode} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {mode}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'id',
      width: '2%',
      render: (id, { published }) => {
        return (
          <Row justify='space-around'>
            <Col>
              {published ? (
                <Tooltip title='Click for Unpublish'>
                  <img
                    src={publish}
                    alt='prop'
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '15%',
                      padding: '0px 5px 0px 5px',
                    }}
                    onClick={() => handlePublishMethod(id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title='Click for Publish'>
                  <img
                    src={unpublish}
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '15%',
                      padding: '0px 5px 0px 5px',
                    }}
                    onClick={() => handleUnpublishMethod(id)}
                    alt='prop'
                  />
                </Tooltip>
              )}
            </Col>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deletePaymentMode]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={isPaymentModeloading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <PaymentModeModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></PaymentModeModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate(-1)}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Payment Modes
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Payment Mode</Typography>
              </Col>

              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={(id) => setIsModalVisible(id)}
                      icon={<img src={plus} alt='props' />}
                    >
                      Create New
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className='filterButton'
                      onClick={handleSortButtonClick}
                      icon={<img src={filtericon} alt='props' />}
                    >
                      Filter
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <PaymentModeDetails
                  setDrawerVisible={setDrawerVisible}
                  drawerVisible={drawerVisible}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  setEditId={setEditId}
                  editId={editId}
                  paymentModeDetailId={paymentModeDetailId}
                  currentPage={currentPage}
                ></PaymentModeDetails>
                <CustomTable
                  columns={Columns}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={sortOrder === 'asc' ? allPaymentMode : sortedData}
                />
              </Col>

              {allPaymentMode && length > 0 ? (
                <Col span={24}>
                  <Row justify='center'>
                    <Pagination
                      total={allPaymentMode?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default PaymentMode;
