import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  Spin,
  Tooltip,
  Breadcrumb,
  Select,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import { useNavigate, useParams } from 'react-router-dom';
import Search from 'antd/lib/transfer/search';
import alphabeticalAscending from '../../assests/alphabeticalAscending.svg';
import alphabeticalDescending from '../../assests/alphabeticalDescending.svg';
import retentionAscending from '../../assests/retentionAscending.svg';
import retentionDescending from '../../assests/retentionDescending.svg';
import totalVisitAscending from '../../assests/totalVisitAscending.svg';
import totalVisitDescending from '../../assests/totalVisitDescending.svg';
import {
  getCustomerById,
  getCustomerOrders,
  getCustomers,
} from '../../Actions/CustomerAction';
import CustomerDetails from './CustomerDetails';
import { FaHashtag, FaIndianRupeeSign } from 'react-icons/fa6';

const Customers = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customerId, setCustomerId] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const { restaurant, setRestaurantId, isCustomerLoading, allCustomers } =
    useSelector((state) => {
      const { restaurant, setRestaurantId } = state.restaurant;
      const { allCustomers, isCustomerLoading } = state.customer;
      return {
        restaurant,
        isCustomerLoading,
        setRestaurantId,
        allCustomers,
      };
    });

  const { length } = allCustomers.customers || {};
  const { orgId } = useParams();

  const [changedCustomers, setChangedCustomers] = useState();

  useEffect(() => {
    dispatch(getCustomers(`api/organization_customers?page=${currentPage}`));
  }, [dispatch]);

  useEffect(() => {
    const Alphabetically = _.orderBy(
      allCustomers.customers,
      ['name'],
      ['desc']
    );
    setChangedCustomers(Alphabetically);
  }, [allCustomers]);

  const handleOpen = (Id) => {
    setCustomerId(Id);
    dispatch(getCustomerById(Id));
    dispatch(getCustomerOrders(`api/customer_orders?customer_id=${Id}`));
    localStorage.setItem('organization_id', orgId);
    setIsModalVisible(true);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const handleCloseOrganization = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const options = [
    {
      value: 'alphabeticalDescending',
      label: (
        <Typography className='selectOptions'>
          Alphabetically
          <img src={alphabeticalDescending} style={{ paddingLeft: '12px' }} />
        </Typography>
      ),
    },
    {
      value: 'alphabeticalAscending',
      label: (
        <Typography className='selectOptions'>
          Alphabetically
          <img src={alphabeticalAscending} style={{ paddingLeft: '12px' }} />
        </Typography>
      ),
    },
    {
      value: 'retentionDescending',
      label: (
        <Typography className='selectOptions'>
          Retention Rate
          <img src={retentionDescending} style={{ paddingLeft: '12px' }} />
        </Typography>
      ),
    },
    {
      value: 'retentionAscending',
      label: (
        <Typography className='selectOptions'>
          Retention Rate
          <img src={retentionAscending} style={{ paddingLeft: '12px' }} />
        </Typography>
      ),
    },
    {
      value: 'totalVisitDescending',
      label: (
        <Typography className='selectOptions'>
          Total Visits
          <img src={totalVisitDescending} style={{ paddingLeft: '37px' }} />
        </Typography>
      ),
    },
    {
      value: 'totalVisitAscending',
      label: (
        <Typography className='selectOptions'>
          Total Visits
          <img src={totalVisitAscending} style={{ paddingLeft: '37px' }} />
        </Typography>
      ),
    },
  ];

  const handleChangeCustomer = (value) => {
    const arr1 = _.orderBy(allCustomers.customers, ['name'], ['asc']);
    const arr2 = _.orderBy(allCustomers.customers, ['name'], ['desc']);
    const arr3 = _.orderBy(
      allCustomers.customers,
      ['customer_retention.yearly'],
      ['asc']
    );
    const arr4 = _.orderBy(
      allCustomers.customers,
      ['customer_retention.yearly'],
      ['desc']
    );
    const arr5 = _.orderBy(allCustomers.customers, ['total_orders'], ['asc']);
    const arr6 = _.orderBy(allCustomers.customers, ['total_orders'], ['desc']);
    if (value === 'alphabeticalAscending') {
      setChangedCustomers(arr1);
    } else if (value === 'alphabeticalDescending') {
      setChangedCustomers(arr2);
    } else if (value === 'retentionAscending') {
      setChangedCustomers(arr3);
    } else if (value === 'retentionDescending') {
      setChangedCustomers(arr4);
    } else if (value === 'totalVisitAscending') {
      setChangedCustomers(arr5);
    } else if (value === 'totalVisitDescending') {
      setChangedCustomers(arr6);
    }
  };

  const Columns = [
    {
      title: 'Phone number/Customer name',
      dataIndex: 'name',
      width: '13%',
      render: (Id, { id, name, phone_with_country_code }) => {
        return (
          <Row>
            <Col style={{ cursor: 'pointer' }}>
              <Tooltip
                title={`View ${name} Details`}
                onClick={() => handleOpen(id)}
              >
                <Typography>{phone_with_country_code}</Typography>
                <Typography>{name}</Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Total orders',
      dataIndex: 'total_orders',
      width: '5%',
      render: (total_orders) => {
        return (
          <Row>
            <Col>
              <Typography>
                <FaHashtag fontSize='10px' />
                {total_orders}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Total spend',
      dataIndex: 'total_spend',
      width: '5%',
      render: (total_spend) => {
        return (
          <Row>
            <Col>
              <Typography>
                <FaIndianRupeeSign fontSize='10px' />
                {total_spend}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Customer retention',
      dataIndex: 'customer_retention',
      width: '6%',
      render: (customer_retention) => {
        return (
          <Row>
            <Col>
              <Typography>{customer_retention.monthly}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Average order type',
      dataIndex: 'average_order_type',
      width: '6%',
      render: (average_order_type) => {
        return (
          <Row>
            <Col>
              <Typography>{average_order_type}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Average order value',
      dataIndex: 'average_order_value',
      width: '7%',
      render: (average_order_value) => {
        return (
          <Row>
            <Col>
              <Typography>
                <FaIndianRupeeSign fontSize='10px' />
                {average_order_value}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  const showLoading = isCustomerLoading;
  return (
    <Spin spinning={showLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <CustomerDetails
              restaurant={restaurant}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              customerId={customerId}
              setCustomerId={setCustomerId}
            ></CustomerDetails>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseOrganization()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Customers
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={11}>
                <Typography className='heading'>Customers</Typography>
              </Col>
              <Col span={4}>
                <Select
                  defaultValue={{
                    label: options[0].label,
                    value: 'alphabeticalDescending',
                  }}
                  showArrow={false}
                  style={{ width: '150px' }}
                  options={options}
                  onChange={handleChangeCustomer}
                />
              </Col>
              <Col span={9}>
                <Search placeholder='Search customer name' />
              </Col>
              <Col>
                <CustomTable
                  columns={Columns}
                  selectedRow={selectedRow}
                  currentPage={currentPage}
                  setSelectedRow={setSelectedRow}
                  data={changedCustomers}
                />
                {changedCustomers && length > 0 ? (
                  <Col span={24}>
                    <Row justify='center' style={{ margin: 30 }}>
                      <Pagination
                        total={allCustomers?.length}
                        onChange={(e) => setCurrentPage(e)}
                        responsive={true}
                        size='large'
                        current={currentPage}
                        showSizeChanger={false}
                      />
                    </Row>
                  </Col>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Customers;
