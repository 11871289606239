import API from '../Utils/API';

export const getStations = (url) => (dispatch) => {
  dispatch({ type: 'GET_STATIONS_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_STATIONS_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_STATIONS_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createStations =
  (data, successCreateStations, failureCreateStations) => (dispatch) => {
    dispatch({ type: 'CREATE_STATIONS_REQUEST' });
    API.post('api/stations', data)
      .then((response) => {

        dispatch({ type: "CREATE_STATIONS_SUCCESS", payload: response.data });
        successCreateStations && successCreateStations(response);

      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_STATIONS_FAILURE',
          payload: error.response.data,
        });
        failureCreateStations && failureCreateStations(error.response.data);
      });
  };
export const deleteStations =
  (StationsId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_STATIONS_REQUEST' });
    API.delete(`api/stations/${StationsId}`)
      .then((response) => {
        dispatch({ type: 'DELETE_STATIONS_SUCCESS', payload: response.data });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_STATIONS_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateStations = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_STATIONS_REQUEST' });
  API.put(`api/stations/${data.id}`, data)
    .then((response) => {

      dispatch({ type: "UPDATE_STATIONS_SUCCESS", payload: response.data });
      successUpdateCallBack && successUpdateCallBack(response);

    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_STATIONS_FAILURE',
        payload: error.response.data,
      });
    });
};
export const getStationsById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_STATIONS_BY_ID_REQUEST' });
  API.get(`api/stations/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_STATIONS_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_STATIONS_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const reOrderStations = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'REORDER_STATIONS_REQUEST' });
  API.put(`api/re_order_category/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'REORDER_STATIONS_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'REORDER_STATIONS_FAILURE',
        payload: error.response.data,
      });
    });
};
