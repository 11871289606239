import API from '../Utils/API';

export const getPaymentMode = (url) => (dispatch) => {
  dispatch({ type: 'GET_PAYMENT_MODE_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_PAYMENT_MODE_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_PAYMENT_MODE_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createPaymentMode =
  (data, successCreatePaymentMode, failureCreatePaymentMode) => (dispatch) => {
    dispatch({ type: 'CREATE_PAYMENT_MODE_REQUEST' });
    API.post('api/payment_modes', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_PAYMENT_MODE_SUCCESS',
          payload: response.data,
        });
        successCreatePaymentMode && successCreatePaymentMode();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_PAYMENT_MODE_FAILURE',
          payload: error.response.data,
        });
        failureCreatePaymentMode &&
          failureCreatePaymentMode(error.response.data);
      });
  };
export const deletePaymentMode =
  (PaymentModeId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_PAYMENT_MODE_REQUEST' });
    API.delete(`api/payment_modes/${PaymentModeId}`)
      .then((response) => {
        dispatch({
          type: 'DELETE_PAYMENT_MODE_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_PAYMENT_MODE_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updatePaymentMode =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_PAYMENT_MODE_REQUEST' });
    API.put(`api/payment_modes/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_PAYMENT_MODE_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_PAYMENT_MODE_FAILURE',
          payload: error.response.data,
        });
      });
  };
export const getPaymentModeById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_PAYMENT_MODE_BY_ID_REQUEST' });
  API.get(`api/payment_modes/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_PAYMENT_MODE_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_PAYMENT_MODE_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
