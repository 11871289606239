import constants from '../Utils/constants';
const initialState = {
  isCountryloading: false,
  isCreateCountryoading: false,
  isDeleteCountryLoading: false,
  isUpdateCountryLoading: false,
  allCountry: [],
  allCountries: [],
  setCountryId: '',
  country: {},
  error: '',
  isCountryByIdLoading: false,
};

export const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_COUNTRY_REQUEST:
      return {
        ...state,
        isCountryloading: true,
      };
    case constants.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        isCountryloading: false,
        allCountry: action.payload,
      };
    case constants.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        isCountryloading: false,
      };
    case constants.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isCountryloading: true,
      };
    case constants.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isCountryloading: false,
        allCountries: action.payload,
      };
    case constants.GET_COUNTRY_FAILURE:
      return {
        ...state,
        isCountryloading: false,
      };
    case constants.CREATE_COUNTRY_REQUEST:
      return {
        ...state,
        isCreateCountryoading: true,
      };
    case constants.CREATE_COUNTRY_SUCCESS:
      return {
        ...state,
        isCreateCountryoading: false,
      };
    case constants.CREATE_COUNTRY_FAILURE:
      return {
        ...state,
        isCreateCountryoading: false,
        error: action.payload?.name,
      };
    case constants.DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        isDeleteCountryLoading: true,
      };
    case constants.DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        isDeleteCountryLoading: false,
      };
    case constants.DELETE_COUNTRY_FAILURE:
      return {
        ...state,
        isDeleteCountryLoading: false,
      };
    case constants.UPDATE_COUNTRY_REQUEST:
      return {
        ...state,
        isUpdateCountryLoading: true,
      };
    case constants.UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        isUpdateCountryLoading: false,
      };
    case constants.UPDATE_COUNTRY_FAILURE:
      return {
        ...state,
        isUpdateCountryLoading: false,
        error: action.payload?.taxation_name,
      };
    case constants.GET_COUNTRY_BY_ID_REQUEST:
      return {
        ...state,
        isCountryByIdLoading: true,
      };
    case constants.GET_COUNTRY_BY_ID_SUCCESS:
      return {
        ...state,
        isCountryByIdLoading: false,
        country: action.payload,
      };
    case constants.GET_COUNTRY_BY_ID_FAILURE:
      return {
        ...state,
        isCountryByIdLoading: false,
      };
    case constants.GET_SELECTED_COUNTRY_ID:
      return {
        ...state,
        setCountryId: action.payload,
      };
    default:
      return state;
  }
};
