import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Breadcrumb,
  Select,
  DatePicker,
  Tooltip,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  FaCaretDown,
  FaHashtag,
  FaIndianRupeeSign,
  FaRegCircleUser,
} from 'react-icons/fa6';
import foodRestaurant from '../../assests/foodRestaurant.svg';
import sortDateAscending from '../../assests/sortDateAscending.svg';
import sortDateDescending from '../../assests/sortDateDescending.svg';
import sortOrderAscending from '../../assests/sortOrderAscending.svg';
import sortOrderDescending from '../../assests/sortOrderDescending.svg';
import takeAway from '../../assests/takeAway.svg';
import Search from 'antd/lib/input/Search';
import CustomTable from '../CustomTable/CustomTable';
import { getCustomers } from '../../Actions/CustomerAction';
import { DateRangePicker } from 'react-date-range';
import { IoMdCalendar } from 'react-icons/io';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { startOfMonth, endOfMonth, parseISO } from 'date-fns';

const CustomerDetails = (props) => {
  const {
    setIsModalVisible,
    restaurant,
    setCurrentPage,
    currentPage,
    customerId,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { customer, allCustomerOrders } = useSelector((state) => {
    const { customer, allCustomerOrders } = state.customer;
    return {
      customer,
      allCustomerOrders,
    };
  });
  const [dates, setDates] = useState([]);
  const rangeRef = useRef(null);
  const pickerRef = useRef(null);
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });
  const [allOrders, setAllOrders] = useState([]);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: 'selection',
  });
  const [selectedButton, setSelectedButton] = useState('set1');

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr?.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setSelectionRange({ startDate, endDate, key: 'selection' });
    setIsPickerOpen(true);
    filterData(startDate, endDate);
  };

  const filterData = (startDate, endDate) => {
    const filtered = allCustomerOrders.customer_orders.filter((item) => {
      const itemDate = parseDate(item.order_created_date_time.created_date);
      return itemDate >= startDate && itemDate <= endDate;
    });
    setAllOrders(filtered);
  };

  const togglePicker = () => {
    if (!isPickerOpen) {
      const rect = rangeRef.current.getBoundingClientRect();
      setPickerPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
    setIsPickerOpen(!isPickerOpen);
  };

  const handleClickOutside = (event) => {
    if (
      rangeRef.current &&
      !rangeRef.current.contains(event.target) &&
      pickerRef.current &&
      !pickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  useEffect(() => {
    setAllOrders(
      _.orderBy(
        allCustomerOrders.customer_orders,
        ['order_created_date_time.created_time'],
        ['desc']
      )
    );
  }, [allCustomerOrders]);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const handleCloseCustomers = () => {
    setAllOrders('');
    setIsModalVisible(false);
    dispatch(getCustomers(`api/organization_customers?page=${currentPage}`));
  };

  const handleButtonClick = (dataType) => {
    setSelectedButton(dataType);
  };

  const Columns = [
    {
      title: 'Order ID',
      dataIndex: 'order_number',
      width: '9%',
      render: (Id, { id, order_number }) => {
        return (
          <Row>
            <Col>
              <Typography>{order_number}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Items',
      dataIndex: 'items',
      width: '12%',
      render: (items) => {
        return (
          <Row>
            <Col>
              <Typography>{items}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '7%',
      render: (status) => {
        return (
          <Row>
            <Col>
              <Typography>{status}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Date Created',
      dataIndex: 'order_created_date_time',
      width: '10%',
      render: (order_created_date_time) => {
        return (
          <Row>
            <Col>
              <Typography>{order_created_date_time.created_time}</Typography>
              <Typography>{order_created_date_time.created_date}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type',
      width: '8%',
      render: (order_type) => {
        return (
          <Row>
            <Col>
              <Typography>{order_type}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Payment Modes',
      dataIndex: 'payment_modes',
      width: '7%',
      render: (payment_modes) => {
        return (
          <Row>
            <Col>
              <Typography>{payment_modes}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Bill Amount',
      dataIndex: 'bill_amount',
      width: '6%',
      render: (bill_amount) => {
        return (
          <Row>
            <Col>
              <Typography>
                <FaIndianRupeeSign fontSize='10px' />
                {bill_amount}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  const options = [
    {
      value: 'dateCreatedAscending',
      label: (
        <Typography className='selectOptions'>
          Date created
          <img src={sortDateAscending} style={{ paddingLeft: '10px' }} />
        </Typography>
      ),
    },
    {
      value: 'dateCreatedDescending',
      label: (
        <Typography className='selectOptions'>
          Date created
          <img src={sortDateDescending} style={{ paddingLeft: '10px' }} />
        </Typography>
      ),
    },
    {
      value: 'orderValueDescending',
      label: (
        <Typography className='selectOptions'>
          Order value
          <img src={sortOrderAscending} style={{ paddingLeft: '20px' }} />
        </Typography>
      ),
    },
    {
      value: 'orderValueAscending',
      label: (
        <Typography className='selectOptions'>
          Order value
          <img src={sortOrderDescending} style={{ paddingLeft: '20px' }} />
        </Typography>
      ),
    },
  ];

  const handleChangeOrders = (value) => {
    const arr1 = _.orderBy(
      allCustomerOrders.customer_orders,
      [
        'order_created_date_time.created_date',
        'order_created_date_time.created_time',
      ],
      ['desc', 'desc']
    );
    const arr2 = _.orderBy(
      allCustomerOrders.customer_orders,
      [
        'order_created_date_time.created_date',
        'order_created_date_time.created_time',
      ],
      ['asc', 'asc']
    );
    const arr3 = _.orderBy(
      allCustomerOrders.customer_orders,
      ['bill_amount'],
      ['desc']
    );
    const arr4 = _.orderBy(
      allCustomerOrders.customer_orders,
      ['bill_amount'],
      ['asc']
    );
    if (value === 'dateCreatedAscending') {
      setAllOrders(arr1);
    } else if (value === 'dateCreatedDescending') {
      setAllOrders(arr2);
    } else if (value === 'orderValueDescending') {
      setAllOrders(arr3);
    } else if (value === 'orderValueAscending') {
      setAllOrders(arr4);
    }
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              // onClick={() => handleCloseOrganization()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseCustomers()}
              className='breadcrumRestaurant'
            >
              Customers
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {customer.phone_with_country_code} | {customer.name}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {customer.phone_with_country_code} | {customer.name}
          </Typography>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='customers'>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Total Spend</Typography>
          <Typography className='subHeading'>
            <FaIndianRupeeSign style={{ fontSize: '18px' }} />
            {customer.total_spend}
          </Typography>
        </Col>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Total Orders</Typography>
          <Typography className='subHeading'>
            {customer.total_orders}
          </Typography>
        </Col>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Average Order Value</Typography>
          <Typography className='subHeading'>
            <FaIndianRupeeSign style={{ fontSize: '18px' }} />
            {customer.average_order_value}
          </Typography>
        </Col>
        <Col span={4} className='customerDetails'>
          <Typography className='customerData'>Surveys Completed</Typography>
          <Typography className='subHeading'></Typography>
        </Col>
        <Col span={4}>
          <Typography className='customerData'>Average Order Type</Typography>
          <Typography className='subHeading'>
            {customer.average_order_type}
          </Typography>
        </Col>
        <Col span={1}>
          <Typography style={{ marginTop: '10px' }}>
            <img src={foodRestaurant} />{' '}
          </Typography>
          <Typography style={{ marginTop: '8px' }}>
            <img src={takeAway} />
          </Typography>
        </Col>
        <Col span={4} style={{ marginTop: '20px' }}>
          <Typography className='customerData'>Customer Retention</Typography>
          <Typography className='subHeading'>
            {customer.customer_retention?.yearly}
          </Typography>
        </Col>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        <Col span={5}>
          <Typography className='customerData'>Customer Information</Typography>
          <Row className='customerInformation'>
            <Col span={24}>
              <FaRegCircleUser
                style={{ fontSize: '30px', marginBottom: '10px' }}
              />
              <Typography className='customerHeading'>Name</Typography>
              <Typography className='customerData'>{customer.name}</Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>Email </Typography>
              <Typography className='customerData'>{customer.email}</Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>Phone</Typography>
              <Typography className='customerData'>
                {customer.phone_with_country_code}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>First Visited</Typography>
              <Typography className='customerData'>
                {customer.first_visited}
              </Typography>
            </Col>
            <Col span={24}>
              <Typography className='customerHeading'>Relation</Typography>
              <Typography className='customerData'>
                {customer.relation}
              </Typography>
            </Col>
          </Row>
        </Col>
        <Col span={18} style={{ marginLeft: '15px' }}>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={3}>
              <Button
                className={`${
                  selectedButton === 'set1' ? 'orderButton' : 'orderButton2'
                }`}
                onClick={() => handleButtonClick('set1')}
              >
                Orders
              </Button>
            </Col>
            <Col span={3}>
              <Button
                className={`${
                  selectedButton === 'set2' ? 'orderButton' : 'orderButton2'
                }`}
                onClick={() => handleButtonClick('set2')}
              >
                Surveys
              </Button>
            </Col>
            <Col span={5}>
              <Search placeholder='Search order' />
            </Col>
            <Col span={5}>
              <Select
                defaultValue={{
                  label: options[0].label,
                  value: 'dateCreatedAscending',
                }}
                showArrow={false}
                style={{
                  marginLeft: '15px',
                  width: '140px',
                  borderRadius: '10px',
                }}
                options={options}
                onChange={handleChangeOrders}
              />
            </Col>
            <Col span={8}>
              <Typography
                ref={rangeRef}
                onClick={togglePicker}
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  border: '1px solid #7b7b7b',
                  borderRadius: '4px',
                  padding: '4px 33px',
                  fontFamily: 'circular-400',
                  wordSpacing: '-5px',
                }}
              >
                <IoMdCalendar
                  style={{ position: 'absolute', left: '3%', fontSize: '20px' }}
                />
                {`${formatDate(selectionRange.startDate)} - ${formatDate(
                  selectionRange.endDate
                )}`}
                <FaCaretDown
                  style={{
                    position: 'absolute',
                    marginLeft: '7px',
                    fontSize: '18px',
                  }}
                />
              </Typography>
            </Col>
          </Row>
          {isPickerOpen && (
            <div
              ref={pickerRef}
              style={{ position: 'absolute', zIndex: 100, marginLeft: '214px' }}
            >
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                rangeColors={['#3a3a3a']}
              />
            </div>
          )}
          <Row className='ordersTable'>
            <Col>
              <CustomTable
                columns={Columns}
                data={selectedButton === 'set1' ? allOrders : []}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CustomerDetails;
