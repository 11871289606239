import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Typography, message, Spin, Breadcrumb } from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { useNavigate } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import LayoutModalForm from './LayoutModalForm';
import { deleteLayout, getLayout } from '../../Actions/LayoutAction';

const Layout = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const { restaurant, setRestaurantId, allLayout, isLayoutLoading } =
    useSelector((state) => {
      const { allLayout, isLayoutLoading } = state.layout;
      const { restaurant, setRestaurantId } = state.restaurant;
      return {
        restaurant,
        allLayout,
        setRestaurantId,
        isLayoutLoading,
      };
    });
  useEffect(() => {
    dispatch(getLayout(`api/organization_layouts`));
  }, [dispatch]);

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleCreate = () => {
    setIsModalVisible(true);
    setEditId('');
  };

  const successDeleteLayout = () => {
    dispatch(getLayout(`api/organization_layouts`));
    setTimeout(() => {
      message.success(`Your Layout Deleted Successfully`);
    }, 1000);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const Columns = [
    {
      title: 'Layout name',
      dataIndex: 'layout_name',
      width: '16%',
      render: (layout_name) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {layout_name}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Tables added',
      dataIndex: 'table_count',
      width: '5%',
      render: (table_count) => {
        return (
          <Row>
            <Col>
              <Typography>{table_count}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      width: '2%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteLayout]}
                customMethod={successDeleteLayout}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isLayoutLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <LayoutModalForm
              restaurant={restaurant}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
            ></LayoutModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate(-1)}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Layout
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Layout</Typography>
              </Col>

              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleCreate}
                      icon={<img src={plus} alt='props' />}
                    >
                      Add new layout
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <CustomTable
                  columns={Columns}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={allLayout}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Layout;
