import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Avatar,
  Button,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import _ from 'lodash';
import { FiEdit2 } from 'react-icons/fi';
import Delete from '../../assests/Delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmployee, getEmployee } from '../../Actions/EmployeeAction';
const EmployeeDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    editId,
    setIsModalVisible,
    employeeId,
    setEmployeeId,
    currentPage,
  } = props;
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { allEmployees } = useSelector((state) => {
    const { allEmployees } = state.employees;
    return {
      allEmployees,
    };
  });
  const the_employee = _.filter(allEmployees?.users, function (o) {
    return o.id === employeeId;
  });
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setDrawerVisible(false);
  };
  const organizationUserId = the_employee[0]?.organization_users[0]?.id;
  const handleDelete = () => {
    dispatch(deleteEmployee(organizationUserId, successDelete, failureDelete));
  };
  const successDelete = () => {
    setDrawerVisible(false);
    dispatch(getEmployee(`api/organization_employee_users`));
    setTimeout(() => {
      message.success(`Employee Deleted Successfully`);
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
            <Col span={24} style={{ marginTop: '25px' }}>
              <Row>
                <Col span={8}>
                  <Button
                    className='editButtonDrawer'
                    onClick={() => handleEdit(employeeId)}
                  >
                    <FiEdit2 className='editIcon' />
                    Edit
                  </Button>
                </Col>
                <Col span={8}>
                  <Popconfirm
                    title={
                      <Typography className='popconfirmTitle'>
                        Do you really want to delete this Category?
                      </Typography>
                    }
                    icon={<Typography className='warning'>Warning!</Typography>}
                    onConfirm={() => handleDelete()}
                    okButtonProps={{ className: 'popconfirmButton' }}
                    cancelButtonProps={{ className: 'popconfirmButton' }}
                    okText='Confirm'
                    cancelText='Cancel'
                    placement='topLeft'
                  >
                    <Button className='deleteButtonDrawer'>
                      <img
                        src={Delete}
                        className='deleteIcon'
                        width='20px'
                        height='16px'
                      />
                      Delete
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {the_employee && (
        <div>
          <Avatar
            shape='square'
            size={125}
            className='image'
            src={the_employee[0]?.user_image_url}
          ></Avatar>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>First Name</Typography>
              <Typography className='detailsubheading'>
                {the_employee[0]?.first_name}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Last Name </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {the_employee[0]?.last_name}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Contact Number </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {the_employee[0]?.phone}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Employee Role</Typography>
              <Typography className='detailsubheading'>
                {the_employee[0]?.organization_users[0]?.user_type.join(', ')}
              </Typography>
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default EmployeeDetails;
