import constants from '../Utils/constants';
const initialState = {
  isItemTypeloading: false,
  isCreateItemTypeloading: false,
  isItemTypeByIdloading: false,
  allItemType: [],
  setItemTypeId: '',
  itemType: {},
};

export const itemTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ITEM_TYPE_REQUEST:
      return {
        ...state,
        isItemTypeloading: true,
      };
    case constants.GET_ITEM_TYPE_SUCCESS:
      return {
        ...state,
        isItemTypeloading: false,
        allItemType: action.payload,
      };
    case constants.GET_ITEM_TYPE_FAILURE:
      return {
        ...state,
        isItemTypeloading: false,
      };
    case constants.CREATE_ITEM_TYPE_REQUEST:
      return {
        ...state,
        isCreateItemTypeloading: true,
      };
    case constants.CREATE_ITEM_TYPE_SUCCESS:
      return {
        ...state,
        isCreateItemTypeloading: false,
      };
    case constants.CREATE_ITEM_TYPE_FAILURE:
      return {
        ...state,
        isCreateItemTypeloading: false,
      };
    case constants.DELETE_ITEM_TYPE_REQUEST:
      return {
        ...state,
        isItemTypeloading: true,
      };
    case constants.DELETE_ITEM_TYPE_SUCCESS:
      return {
        ...state,
        isItemTypeloading: false,
      };
    case constants.DELETE_ITEM_TYPE_FAILURE:
      return {
        ...state,
        isItemTypeloading: false,
      };
    case constants.UPDATE_ITEM_TYPE_REQUEST:
      return {
        ...state,
        isItemTypeloading: true,
      };
    case constants.UPDATE_ITEM_TYPE_SUCCESS:
      return {
        ...state,
        isItemTypeloading: false,
      };
    case constants.UPDATE_ITEM_TYPE_FAILURE:
      return {
        ...state,
        isItemTypeloading: false,
      };
    case constants.GET_SELECTED_ITEM_TYPE_ID:
      return {
        ...state,
        setItemTypeId: action.payload,
      };
    case constants.GET_ITEM_TYPE_BY_ID_REQUEST:
      return {
        ...state,
        isItemTypeByIdloading: true,
      };
    case constants.GET_ITEM_TYPE_BY_ID_SUCCESS:
      return {
        ...state,
        isItemTypeByIdloading: false,
        itemType: action.payload,
      };
    case constants.GET_ITEM_TYPE_BY_ID_FAILURE:
      return {
        ...state,
        isItemTypeByIdloading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
