import constants from '../Utils/constants';
const initialState = {
  isEmployeeLoading: false,
  isCreateEmployeeLoading: false,
  isDeleteEmployeeLoading: false,
  isUpdateEmployeeLoading: false,
  allEmployees: [],
  employees: {},
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_EMPLOYEE_REQUEST:
      return {
        ...state,
        isEmployeeLoading: true,
      };
    case constants.GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isEmployeeLoading: false,
        allEmployees: action.payload,
      };
    case constants.GET_EMPLOYEE_FAILURE:
      return {
        ...state,
        isEmployeeLoading: false,
      };
    case constants.CREATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        isCreateEmployeeLoading: true,
      };
    case constants.CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isCreateEmployeeLoading: false,
      };
    case constants.CREATE_EMPLOYEE_FAILURE:
      return {
        ...state,
        isCreateEmployeeLoading: false,
      };
    case constants.DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        isDeleteEmployeeLoading: true,
      };
    case constants.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isDeleteEmployeeLoading: false,
      };
    case constants.DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        isDeleteEmployeeLoading: false,
      };
    case constants.UPDATE_EMPLOYEE_ADD_TYPE_REQUEST:
      return {
        ...state,
        isUpdateEmployeeLoading: true,
      };
    case constants.UPDATE_EMPLOYEE_ADD_TYPE_SUCCESS:
      return {
        ...state,
        isUpdateEmployeeLoading: false,
      };
    case constants.UPDATE_EMPLOYEE_ADD_TYPE_FAILURE:
      return {
        ...state,
        isUpdateEmployeeLoading: false,
      };
    case constants.UPDATE_EMPLOYEE_REMOVE_TYPE_REQUEST:
      return {
        ...state,
        isUpdateEmployeeLoading: false,
      };
    case constants.UPDATE_EMPLOYEE_REMOVE_TYPE_SUCCESS:
      return {
        ...state,
        isUpdateEmployeeLoading: false,
      };
    case constants.UPDATE_EMPLOYEE_REMOVE_TYPE_FAILURE:
      return {
        ...state,
        isUpdateEmployeeLoading: false,
      };
    default:
      return state;
  }
};
