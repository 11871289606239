import React, { useEffect, useState } from 'react';
import {
  Space,
  Row,
  Col,
  Typography,
  message,
  Button,
  Input,
  Checkbox,
  Upload,
  Card,
  Spin,
  Image,
  DatePicker,
} from 'antd';
import mime from 'mime';
import moment from 'moment';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useSelector } from 'react-redux';
import { IoMdImage } from 'react-icons/io';
import { LuAsterisk } from 'react-icons/lu';
import './Restaurant.css';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { MdDeleteOutline } from 'react-icons/md';
import { Controller, useForm } from 'react-hook-form';
import {
  updateRestaurantDetails,
  updateOrganizationFssaiDetailAction,
  getRestaurantDetails,
  getRestaurant,
} from '../../Actions/RestaurantAction';
import { falseyValueCases } from '../CommonLogics/CommonMethods';
import { deleteImage } from '../../Actions/ImageAction';
import PdfViewer from '../../Utils/PdfViewer';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const FSSAIDetails = (props) => {
  const {
    restaurantDetailId,
    setrestaurantDetailId,
    setTabkey,
    editId,
    setEditId,
    setIsModalVisible,
  } = props;
  const dispatch = useDispatch();

  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');
  const [newFiles, setNewFiles] = useState();
  const [newFiles2, setNewFiles2] = useState();

  const [fileAllowed, setFileAllowed] = useState(false);
  const [fileAllowed2, setFileAllowed2] = useState(false);

  const [isDocumentVisible, setIsDocumentVisible] = useState(true);
  const [editDocumentId, setEditDocumentId] = useState('');
  const IsEmpty = _.isEmpty(newFiles);

  const { restaurant, restaurantdeatils, isUpdateRestaurantLoading } =
    useSelector((state) => {
      const { restaurant, restaurantdeatils, isUpdateRestaurantLoading } =
        state.restaurant;
      return {
        restaurant,
        restaurantdeatils,
        isUpdateRestaurantLoading,
      };
    });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    register,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      fssai_registration_number: restaurantdeatils?.fssai_registration_number,
      gst_number: restaurantdeatils?.gst_number,
    },
  });
  useEffect(() => {
    if (!editId) {
      setValue('fssai_registration_number', '');
      setValue('gst_number', '');
    }
  }, []);

  const [changedDate, setChangedDate] = useState(restaurantdeatils.expiry_date);
  const [isFssai, setisFssai] = useState(
    restaurantdeatils?.is_fssai ? restaurantdeatils?.is_fssai : false
  );

  const [Gstdocument, setGstdocument] = useState(
    restaurant?.organizations_detail?.gst_document_key
      ? restaurant?.organizations_detail?.gst_document_key
      : null
  );

  const handleisFssaiMethod = (checkBoxEvent) => {
    setisFssai(checkBoxEvent.target.checked);
  };
  const handleClose = () => {
    reset(preloadedRestaurantValue);
  };

  const selectedDocument = _.filter(
    restaurant?.organizations_detail?.fssai_document,
    // restaurantdeatils?.fssai_document,
    function (o) {
      return o.id === editDocumentId;
    }
  );
  const watching = watch();

  const [errorMessage, setErrorMessage] = useState(null);
  // const [fileAllowed, setFileAllowed] = useState(false);
  const [isGst, setIsGst] = useState(
    restaurantdeatils?.is_gst ? restaurantdeatils?.is_gst : false
  );
  const [isInclusiveGst, setIsInclusiveGst] = useState(
    restaurant?.organizations_detail?.inclusive_gst
      ? restaurant?.organizations_detail?.inclusive_gst
      : false
  );
  const [isSeparateGst, setIsSeparateGst] = useState(
    restaurant?.organizations_detail?.separate_gst
      ? restaurant?.organizations_detail?.separate_gst
      : false
  );
  const handleIsGstMethod = (checkBoxEvent) => {
    setIsGst(checkBoxEvent.target.checked);
  };
  const handleIsInclusiveGstMethod = (checkBoxEvent) => {
    setIsInclusiveGst(checkBoxEvent.target.checked);
  };
  const handleIsSeparateGstMethod = (checkBoxEvent) => {
    setIsSeparateGst(checkBoxEvent.target.checked);
  };
  const onUploadGstDocument = (data) => {
    setGstdocument(data?.target?.files?.[0]);
    setFileAllowed2(true);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const orgCgstOnChangeMethod = (event) => {
    const isValueInteger = !isNaN(
      parseInt(event.target.value) + parseInt(watching.org_s_gst)
    );
    setValue(
      'total_gst',
      isValueInteger
        ? parseFloat(event.target.value) + parseFloat(watching.org_s_gst)
        : ''
    );
    setErrorMessage(null);
  };

  const orgSgstOnChangeMethod = (event) => {
    const isValueInteger = !isNaN(
      parseInt(event.target.value) + parseInt(watching.org_c_gst)
    );
    setValue(
      'total_gst',
      isValueInteger
        ? parseFloat(event.target.value) + parseFloat(watching.org_c_gst)
        : ''
    );
    setErrorMessage(null);
  };
  const handleEditRestaurantMethod = (data) => {
    data.id = restaurantdeatils?.id;
    data.is_gst = isGst;
    // data.inclusive_gst = isInclusiveGst;
    // data.separate_gst = isSeparateGst;
    // data.gst_document = Gstdocument;
    data.expiry_date = changedDate;
    data.is_fssai = isFssai;
    if (!falseyValueCases(newFiles)) {
      data.fssai_document = newFiles;
    }
    if (!falseyValueCases(newFiles2)) {
      data.gst_document = newFiles2;
    }
    // data.fssai_document_key =newFiles;
    // data.fssai_document_key = fssaiDocument;
    // const isCgstSgstValid =
    //   parseInt(data.total_gst) >=
    //   parseInt(data.org_s_gst) + parseInt(data.org_c_gst);
    // data.total_gst = parseInt(data.org_c_gst) + parseInt(data.org_s_gst);
    //   data.total_gst = data.org_c_gst && data.org_s_gst ? data.total_gst : 0;
    //  if (isCgstSgstValid && isSeparateGst) {
    //   dispatch(updateOrganizationFssaiDetailAction(data, UpdateCallBack, faliureUpdate));
    //   //   } else if (!isCgstSgstValid && isSeparateGst) {
    //   //     setErrorMessage("CGST and SGST values can't be greater than GST value.");
    //  } else {
    // setTabkey("4");
    dispatch(
      updateOrganizationFssaiDetailAction(data, UpdateCallBack, faliureUpdate)
    );
  };

  const UpdateCallBack = () => {
    // reset();
    setTabkey('1');
    // setIsModalVisible(false);
    // setNewFiles([]);
    // setNewFiles2([]);

    // setFileAllowed(false);
    // setFileAllowed2(false);

    // setEditId('');
    // setValue('is_fssai', '');
    // setValue('fssai_document_key', '');
    // setValue('fssai_registration_number', '');
    // setValue('gst_number', '');
    // setValue('is_gst', '');
    // setValue('org_c_gst', '');
    // setValue('org_i_gst', '');
    // setValue('org_s_gst', '');
    // setValue('total_gst', '');
    // setValue('separate_gst', '');
    // setValue('gst_document_key', '');

    // clearErrors();
    // dispatch(getRestaurantDetails());

    setTimeout(() => {
      message.success('Your Restaurant Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.error || failureResponse?.message
          : 'Something went wrong.'
      );
    }, 1000);
  };
  const propsForUpload = {
    action: handleEditRestaurantMethod,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const propsForUpload2 = {
    action: handleEditRestaurantMethod,
    // action: handleCreateDocument,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles2(file);
      return false;
    },
    multiple: false,
  };
  // useEffect(() => {
  //   if (!restaurantDetailId) {
  //     setrestaurantDetailId("");
  //   }
  // }, [setValue, !restaurantDetailId]);
  useEffect(() => {
    if (!restaurantdeatils?.id) {
      // setEditId('');
    }
  }, [setValue, !restaurantdeatils?.id]);
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #f6f6f6 inset',
  };

  const handleDeleteImage = (key) => {
    dispatch(deleteImage(key, successCallbackImageDelete));
  };

  const successCallbackImageDelete = () => {
    setTabkey('1');
    setTimeout(() => {
      message.success('Your Image deleted Successfully');
    }, 1000);
    // dispatch(getRestaurantDetails());
  };
  const mimeType1 = mime.getType(restaurantdeatils?.fssai_document_name);
  const mimeType2 = mime.getType(restaurantdeatils?.gst_document_name);

  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };

  const handleDateChange = (date, dateString) => {
    setChangedDate(dateString);
  };

  return (
    <Spin spinning={isUpdateRestaurantLoading}>
      <Card
        style={{
          backgroundColor: '#f6f6f6',
          border: 'rgb(229, 229, 229,1)',
        }}
        // scrollable={{ x: 1000, y:500 }}
      >
        <form
          style={{
            maxHeight: '100%',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          onSubmit={handleSubmit(handleEditRestaurantMethod)}
        >
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row>
                <Col span={1}>
                  <Controller
                    name='is_fssai'
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox
                        onChange={handleisFssaiMethod}
                        checked={isFssai}
                      />
                    )}
                  />
                </Col>
                <Col span={22}>
                  <Typography className='formHeading'>
                    Do you have Fssai Number&nbsp;?
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                FSSAI Document
                <LuAsterisk className='asterisk' />
              </Typography>
            </Col>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <div
              // style={{
              //   border: '1px solid black',
              //   width: '50%',
              // }}
              >
                <Row justify='space-between'>
                  <Col>
                    <Upload
                      disabled={!isFssai}
                      {...propsForUpload}
                      listType='picture-card'
                      maxCount={1}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <IoMdImage
                              size='3.5em'
                              color='#7B7B7B'
                              title='Add Image'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadText'>
                            Upload document
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  </Col>
                  {newFiles
                    ? ''
                    : editId &&
                      restaurantdeatils?.fssai_document_url &&
                      (mimeType1 === 'application/pdf' ? (
                        <Col>
                          <PdfViewer
                            url={restaurantdeatils?.fssai_document_url}
                          />
                          <MdDeleteOutline
                            onClick={() =>
                              handleDeleteImage(
                                restaurantdeatils?.fssai_document_key
                              )
                            }
                            style={{
                              width: '20px',
                              height: '20px',
                              position: 'absolute',
                              top: '11%',
                              cursor: 'pointer',
                            }}
                          />
                        </Col>
                      ) : (
                        <Col>
                          <Image
                            src={restaurantdeatils?.fssai_document_url}
                            width='100px'
                            height='103px'
                          />
                          <MdDeleteOutline
                            onClick={() =>
                              handleDeleteImage(
                                restaurantdeatils?.fssai_document_key
                              )
                            }
                            style={{
                              width: '20px',
                              height: '20px',
                              position: 'absolute',
                              top: '73%',
                              cursor: 'pointer',
                            }}
                          />
                        </Col>
                      ))}
                </Row>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                FSSAI Number
                <LuAsterisk className='asterisk' />
              </Typography>
              <Controller
                as={<Input disabled={!isFssai} style={inputStyles} />}
                name='fssai_registration_number'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24}>
              <Typography className='formHeading'>
                Expiry Date
                <LuAsterisk className='asterisk' />
              </Typography>
              <Controller
                render={() => (
                  <DatePicker
                    style={{ width: '100%' }}
                    disabled={!isFssai}
                    defaultValue={changedDate ? moment(changedDate) : undefined}
                    onChange={handleDateChange}
                    disabledDate={disabledDate}
                  />
                )}
                name='expiry_date'
                control={control}
                // className='inputLabel'
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row gutter={[16, 16]} className='button'>
                <Col span={1}>
                  <Controller
                    name='is_gst'
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <Checkbox onChange={handleIsGstMethod} checked={isGst} />
                    )}
                  />
                </Col>
                <Col span={22}>
                  <Typography className='formHeading'>
                    Do you have GST Number&nbsp;?
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                GST Document
                <LuAsterisk className='asterisk' />
              </Typography>
            </Col>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row justify='space-between'>
                <Col>
                  <Upload
                    disabled={!isGst}
                    {...propsForUpload2}
                    listType='picture-card'
                    className='formHeading'
                  >
                    <Row justify='center'>
                      <Col span={24}>
                        {
                          <IoMdImage
                            size='3.5em'
                            color='#7B7B7B'
                            title='Add Image'
                          />
                        }
                      </Col>
                      <Col span={24}>
                        <Typography className='uploadText'>
                          Upload document
                        </Typography>
                      </Col>
                    </Row>
                  </Upload>
                </Col>
                {newFiles2
                  ? ''
                  : editId &&
                    restaurantdeatils?.gst_document_url &&
                    (mimeType2 === 'application/pdf' ? (
                      <Col>
                        <PdfViewer url={restaurantdeatils?.gst_document_url} />
                        <MdDeleteOutline
                          onClick={() =>
                            handleDeleteImage(
                              restaurantdeatils?.gst_document_key
                            )
                          }
                          style={{
                            width: '20px',
                            height: '20px',
                            position: 'absolute',
                            top: '11%',
                            cursor: 'pointer',
                          }}
                        />
                      </Col>
                    ) : (
                      <Col>
                        <Image
                          src={restaurantdeatils?.gst_document_url}
                          width='100px'
                          height='103px'
                        />
                        <MdDeleteOutline
                          onClick={() =>
                            handleDeleteImage(
                              restaurantdeatils?.gst_document_key
                            )
                          }
                          style={{
                            width: '20px',
                            height: '20px',
                            position: 'absolute',
                            top: '73%',
                            cursor: 'pointer',
                          }}
                        />
                      </Col>
                    ))}
              </Row>
              {fileAllowed2 && <p style={{ color: 'red' }}>{fileAllowed2}</p>}
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>
                GST Number <LuAsterisk className='asterisk' />
              </Typography>
              <Controller
                as={<Input style={inputStyles} disabled={!isGst}></Input>}
                name='gst_number'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[22, 22]} className='button' justify='center'>
            <Col span={24}></Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Button
                // className="filterButton"
                className='detailsButton'
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <Button
                className='editDesignationButton'
                htmlType='submit'
                // onClick={handleEditRestaurantMethod}
              >
                Update
              </Button>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
          </Row>
        </form>
      </Card>
    </Spin>
  );
};
export default FSSAIDetails;
