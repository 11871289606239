import React, { useEffect, useState } from 'react';

const SvgComponent = ({ url, width, height }) => {
  const [svgContent, setSvgContent] = useState(null);

  const modifySvg = (svgText) => {
    // Remove width and height attributes from the SVG
    svgText = svgText
      .replace(/width="[^"]*"/, '')
      .replace(/height="[^"]*"/, '');

    // Ensure there's a viewBox for scaling (the original SVG already has one)
    return svgText;
  };

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const response = await fetch(url);
        let svgText = await response.text();
        svgText = modifySvg(svgText); // Modify the SVG content before rendering
        setSvgContent(svgText);
      } catch (error) {
        console.error('Error fetching SVG:', error);
      }
    };

    fetchSvg();
  }, [url]);

  return (
    <div
      style={{ width, height }} // Set container size
      dangerouslySetInnerHTML={{ __html: svgContent }} // Inject modified SVG
    />
  );
};

export default SvgComponent;
