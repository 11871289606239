import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
  Upload,
  Checkbox,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useNavigate, useParams } from 'react-router-dom';
import { MdCurrencyRupee, MdOutlineVideoCall } from 'react-icons/md';
import { FaStarOfLife } from 'react-icons/fa6';
import { IoMdImage } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { categoryItemsSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import TextArea from 'antd/lib/input/TextArea';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';
import {
  addMultiItemsinCategory,
  getCategoryItem,
  getCategoryItemById,
} from '../../Actions/CategoryItemAction';
import { getItemTypes } from '../../Actions/itemTypesAction';
import {
  createItem,
  getItemById,
  getItems,
  updateItem,
} from '../../Actions/itemAction';
import { getCategoryById } from '../../Actions/CategoryAction';
import { falseyValueCases } from '../CommonLogics/CommonMethods';
import ReactPlayer from 'react-player';
import { Md3dRotation } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';
import { deleteImage } from '../../Actions/ImageAction';
import SvgComponent from '../../Utils/svgComponent';

const CategoryItemModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    isType,
    restaurant,
    Category,
    setIsType,
    setCurrentPage,
    currentPage,
    setCategoryId,
    categoryDetailId,
  } = props;
  const dispatch = useDispatch();
  const [preloadedCategoryItemValue, setPreloadedCategoryItemValue] =
    useState('');
  const [newFiles, setNewFiles] = useState();
  const [newFiles3dImage, setNewFiles3dImage] = useState();
  const [newFiles3dImageIos, setNewFiles3dImageIos] = useState();
  const [newFilesVideo, setNewFilesVideo] = useState();
  const [selectedItems, setSelectedItems] = useState();
  const [failedResponse, setFailedResponse] = useState();
  const [searchVal, setSearchVal] = useState(null);
  const [existingItemValidation, setExistingItemValidation] = useState();
  const navigate = useNavigate();
  const { allCategory, allItemType, allCategoryItem, isItemLoading, item } =
    useSelector((state) => {
      const { allCategory } = state.category;
      const { allItemType } = state.itemType;
      const { allCategoryItem } = state.categoryItem;
      const { isItemLoading, item } = state.item;
      return {
        allCategory,
        allItemType,
        allCategoryItem,
        isItemLoading,
        item,
      };
    });
  const [allItemData, setAllItemData] = useState();
  const the_category_item = _.filter(
    allCategoryItem?.category_items,
    function (o) {
      return o.item.id === editId;
    }
  );
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      price: the_category_item[0]?.item.display_price,
      name: the_category_item[0]?.item.name,
      description: the_category_item[0]?.item.description,
      quantity: the_category_item[0]?.item.quantity,
    },
    resolver: yupResolver(categoryItemsSchema),
  });

  const [selectedCategory, setSelectedCategory] = useState(
    editId === item.id
      ? item.categories.map((data) => data.id)
      : [setCategoryId]
  );
  useEffect(() => {
    setSelectedCategory(
      editId === item.id
        ? item.categories.map((data) => data.id)
        : [setCategoryId]
    );
  }, [editId === item.id]);

  const [checkIsGst, setCheckIsGst] = useState(
    categoryDetailId || (editId && the_category_item[0].item.is_gst)
      ? true
      : false
  );

  const [itemTypeSelection, setitemTypeSelection] = useState();
  useEffect(() => {
    setitemTypeSelection(
      editId &&
        the_category_item[0].item.items_item_types.map((data) => data.id)
        ? the_category_item[0].item.items_item_types.map((data) => data.id)
        : []
    );
  }, [the_category_item[0]]);

  const [itemUnit, setItemUnit] = useState(
    editId && the_category_item[0].item.quantity_unit
      ? the_category_item[0].item.quantity_unit
      : ''
  );

  const { orgId, categoryId } = useParams();

  useEffect(() => {
    if (editId) {
      dispatch(getItemTypes('api/item_types'));
      dispatch(getItemById(editId));
    }
  }, [dispatch]);
  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCategory = () => {
    navigate(`/restaurants/${orgId}/category`);
    dispatch(getCategoryItemById(''));
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ID',
      payload: '',
    });
  };
  const handleCloseCategoryDrawer = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}`);
  };
  const options = allCategory?.map((categories) => {
    return { label: categories?.name, value: categories?.id };
  });
  const handleChange = (e) => {
    setSelectedCategory(e);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
  };

  const handleCancel = () => {
    reset(preloadedCategoryItemValue);
    clearErrors();
  };

  const handleCreateNewItem = (data) => {
    data.quantity_unit = itemUnit;
    data.category_ids = selectedCategory;
    data.is_gst = checkIsGst;
    if (!falseyValueCases(newFiles)) {
      data.item_images = newFiles;
    }
    if (!falseyValueCases(newFiles3dImage)) {
      data.item_3d_image = newFiles3dImage;
    }
    if (!falseyValueCases(newFiles3dImageIos)) {
      data.item_3d_image_for_ios = newFiles3dImageIos;
    }
    if (!falseyValueCases(newFilesVideo)) {
      data.item_video = newFilesVideo;
    }
    data.item_type_ids = itemTypeSelection;
    data.published = false;
    dispatch(createItem(data, successCreateItem, failureCreateItem));
  };

  const successCreateItem = () => {
    setIsModalVisible(false);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
    setValue('published', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Item Created Successfully');
    }, 1000);
  };

  const failureCreateItem = (failureResponse) => {
    // setTimeout(() => {
    //   message.error(failureResponse);
    // }, 1000);
    setFailedResponse(failureResponse.name);
  };

  const handleEdit = (data) => {
    data.id = editId;
    data.quantity_unit = itemUnit;
    data.is_gst = checkIsGst;
    data.category_ids = selectedCategory;
    data.item_type_ids = itemTypeSelection;
    if (!falseyValueCases(newFiles)) {
      data.item_images = newFiles;
    }
    if (!falseyValueCases(newFiles3dImage)) {
      data.item_3d_image = newFiles3dImage;
    }
    if (!falseyValueCases(newFiles3dImageIos)) {
      data.item_3d_image_for_ios = newFiles3dImageIos;
    }
    if (!falseyValueCases(newFilesVideo)) {
      data.item_video = newFilesVideo;
    }
    dispatch(updateItem(data, UpdateCallbackItem));
  };
  const UpdateCallbackItem = () => {
    setIsModalVisible(false);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
    setTimeout(() => {
      message.success('Your Item Updated Successfully');
    }, 1000);
  };
  const handleDeleteImage = (key) => {
    dispatch(deleteImage(key, successCallbackImageDelete));
  };
  const successCallbackImageDelete = () => {
    setIsModalVisible(false);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
  };
  const handleItemTypes = (id) => {
    const isItemTypeSelected = _.some(
      itemTypeSelection,
      (itemTypeId) => itemTypeId === id
    );
    if (isItemTypeSelected) {
      const selectedItemType = _.remove(
        itemTypeSelection,
        (itemTypeId) => itemTypeId !== id
      );
      setitemTypeSelection(selectedItemType);
    } else {
      setitemTypeSelection([...itemTypeSelection, id]);
    }
  };
  const checkItemTypeSelection = (id) => {
    const isItemTypeSelected = _.some(
      itemTypeSelection,
      (itemTypeId) => itemTypeId === id
    );
    return isItemTypeSelected;
  };

  const propsForUpload = {
    action: handleCreateNewItem || handleEdit,
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const propsForUpload3dImage = {
    action: handleCreateNewItem || handleEdit,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles3dImage(file);
      return false;
    },
    multiple: false,
  };

  const propsForUpload3dImageIos = {
    action: handleCreateNewItem || handleEdit,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles3dImageIos(file);
      return false;
    },
    multiple: false,
  };

  const propsForUploadVideo = {
    action: handleCreateNewItem || handleEdit,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFilesVideo(file);
      return false;
    },
    multiple: false,
  };

  const handleExistingItem = () => {
    if (selectedItems) {
      const obj = {};
      obj.category_id = setCategoryId;
      obj.item_ids = selectedItems;
      dispatch(
        addMultiItemsinCategory(
          obj,
          successAddMultipleItems,
          failureAddMultipleItems
        )
      );
    } else {
      setExistingItemValidation('Please Select atleast one Item');
    }
  };

  const successAddMultipleItems = () => {
    setIsModalVisible(false);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
    setValue('published', '');
  };

  const failureAddMultipleItems = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const handleSearchItem = (e) => {
    setExistingItemValidation('');
    dispatch(getItems(`api/items?name=${e}&published=true`, successCallback));
  };

  const successCallback = (response) => {
    const filterItem = response?.items?.filter((items) =>
      allCategoryItem.category_items.every(
        (categoryItems) => categoryItems.item.id !== items.id
      )
    );
    const itemOptions = filterItem?.map((data) => {
      return { label: data?.name, value: data?.id };
    });
    setSearchVal(itemOptions);
  };

  const handleChangeItem = (selectedItems) => {
    const allSelectedItems = selectedItems.map((selectedItemId) =>
      allItemData.items.find((data) => selectedItemId === data.id)
    );
    const selectedId = allSelectedItems.map((items) => items.id);
    setSelectedItems(selectedId);
  };
  useEffect(() => {
    if (!editId) {
      dispatch(getItemTypes('api/item_types'));
      dispatch(
        getCategoryItem(`api/category_items?category_id=${setCategoryId}`)
      );
      dispatch(getItems('api/items', successItems));
    }
  }, [dispatch]);

  const successItems = (response) => {
    setAllItemData(response);
  };
  return (
    <div>
      {isType === 'new_item' ? (
        <Spin spinning={isItemLoading}>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={handleCloseRestaurant}
                  style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  style={{ cursor: 'pointer' }}
                  onClick={handleCloseRestaurantDrawer}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={handleCloseCategory}
                  style={{ cursor: 'pointer' }}
                >
                  Categories
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={handleCloseCategoryDrawer}
                  className='breadcrumRestaurant'
                >
                  {Category?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={handleCloseModal}
                  className='breadcrumRestaurant'
                >
                  Items
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  {editId ? 'Update' : 'Create New Item'}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <form
            onSubmit={handleSubmit(editId ? handleEdit : handleCreateNewItem)}
          >
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='formHeading'>Item Image</Typography>
              </Col>
              <Col style={{ float: 'left' }}>
                <Controller
                  name='item_images'
                  control={control}
                  render={() => (
                    <Upload
                      {...propsForUpload}
                      listType='picture-card'
                      accept='image/png,image/jpeg'
                      maxCount={1}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <IoMdImage
                              size='3em'
                              color='#7B7B7B'
                              title='Add Image'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadText'>
                            Add Image
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  )}
                />
              </Col>
              {newFiles
                ? ''
                : editId &&
                  the_category_item[0]?.item_image_url && (
                    <Col>
                      <img
                        src={the_category_item[0]?.item_image_url}
                        width='125px'
                        height='103px'
                      />
                      <MdDeleteOutline
                        onClick={() =>
                          handleDeleteImage(
                            the_category_item[0]?.item_image_keys[0].img_key
                          )
                        }
                        style={{
                          width: '20px',
                          height: '20px',
                          position: 'relative',
                          top: '44%',
                          cursor: 'pointer',
                        }}
                      />
                    </Col>
                  )}
              <Col span={24}>
                <Typography className='formHeading'>
                  Item 3d Image(Android)
                </Typography>
              </Col>
              <Col>
                <Controller
                  name='item_3d_image'
                  control={control}
                  render={() => (
                    <Upload
                      {...propsForUpload3dImage}
                      listType='picture-card'
                      accept='.glb'
                      maxCount={1}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <IoMdImage
                              size='3em'
                              color='#7B7B7B'
                              title='Add Image'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadText'>
                            Add Image
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  )}
                />
              </Col>
              {newFiles3dImage
                ? ''
                : editId &&
                  the_category_item[0]?.item_3d_image_url && (
                    <Col>
                      <Md3dRotation style={{ width: '90px', height: '90px' }} />
                      <MdDeleteOutline
                        onClick={() =>
                          handleDeleteImage(
                            the_category_item[0]?.item_3d_image_key
                          )
                        }
                        style={{
                          width: '20px',
                          height: '20px',
                          position: 'relative',
                          cursor: 'pointer',
                        }}
                      />
                    </Col>
                  )}
              <Col span={24}>
                <Typography className='formHeading'>
                  Item 3d Image(IOS)
                </Typography>
              </Col>
              <Col>
                <Controller
                  name='item_3d_image_for_ios'
                  control={control}
                  render={() => (
                    <Upload
                      {...propsForUpload3dImageIos}
                      listType='picture-card'
                      accept='.usdz'
                      maxCount={1}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <IoMdImage
                              size='3em'
                              color='#7B7B7B'
                              title='Add Image'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadText'>
                            Add Image
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  )}
                />
              </Col>
              {newFiles3dImageIos
                ? ''
                : editId &&
                  the_category_item[0]?.item_3d_image_for_ios_url && (
                    <Col>
                      <Md3dRotation style={{ width: '90px', height: '90px' }} />
                      <MdDeleteOutline
                        onClick={() =>
                          handleDeleteImage(
                            the_category_item[0]?.item_3d_image_for_ios_key
                          )
                        }
                        style={{
                          width: '20px',
                          height: '20px',
                          position: 'relative',
                          // top: '44%',
                          cursor: 'pointer',
                        }}
                      />
                    </Col>
                  )}
              <Col span={24}>
                <Typography className='formHeading'>Item Video</Typography>
              </Col>
              <Col>
                <Controller
                  name='item_video'
                  control={control}
                  render={() => (
                    <Upload
                      {...propsForUploadVideo}
                      listType='picture-card'
                      accept='.mp4'
                      maxCount={1}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <MdOutlineVideoCall
                              size='3em'
                              color='#7B7B7B'
                              title='Upload Video'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadText'>
                            Upload Video
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  )}
                />
              </Col>
              {newFilesVideo
                ? ''
                : editId &&
                  the_category_item[0]?.item_video_url && (
                    <Col>
                      <ReactPlayer
                        url={the_category_item[0]?.item_video_url}
                        controls={true}
                        width='125px'
                        height='103px'
                      />
                      <MdDeleteOutline
                        onClick={() =>
                          handleDeleteImage(
                            the_category_item[0]?.item_video_key
                          )
                        }
                        style={{
                          width: '20px',
                          height: '20px',
                          position: 'relative',
                          top: '-15%',
                          left: '101%',
                          cursor: 'pointer',
                        }}
                      />
                    </Col>
                  )}
              <Col span={24}>
                <Typography className='formHeading'>
                  Category Name
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '7%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  name='category_ids'
                  control={control}
                  render={() => (
                    <Select
                      size='large'
                      mode='multiple'
                      showArrow
                      suffixIcon={
                        <CaretDownOutlined
                          style={{ fontSize: '16px', color: '#7B7B7B' }}
                        />
                      }
                      style={{
                        width: '100%',
                        // border: '1px solid #262626',
                      }}
                      placeholder='Please select'
                      value={selectedCategory}
                      onChange={(e) => handleChange(e)}
                      options={options}
                    />
                  )}
                />
              </Col>
              <Col span={24}>
                <Typography className='formHeading'>
                  Item Name
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '7%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='name'
                  control={control}
                  className='inputLabel3'
                />
                {errors.name && (
                  <p style={{ color: '#eb5757' }}>{errors.name.message}</p>
                )}
                {<p style={{ color: '#eb5757' }}>{failedResponse}</p>}
              </Col>
              <Col span={24}>
                <Typography className='formHeading'>
                  Add Price
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '7%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  as={<Input size='large' prefix={<MdCurrencyRupee />} />}
                  name='price'
                  control={control}
                  className='inputLabel3'
                />
                {errors.price && (
                  <p style={{ color: '#eb5757' }}>{errors.price.message}</p>
                )}
              </Col>
              <Col span={24}>
                <Typography className='formHeading'>
                  Description
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '4%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  as={<TextArea rows={4} />}
                  name='description'
                  control={control}
                  className='inputLabel3'
                />
                {errors.description && (
                  <p style={{ color: '#eb5757' }}>
                    {errors.description.message}
                  </p>
                )}
              </Col>

              <Col span={18}>
                <Typography className='formHeading'>Quantity</Typography>
                <Controller
                  as={<Input size='large' />}
                  className='inputLabel3'
                  name='quantity'
                  control={control}
                />
              </Col>
              <Col span={6}>
                <Typography
                  className='formHeading'
                  style={{ visibility: 'hidden' }}
                >
                  Quantity
                </Typography>
                <Controller
                  name='quantity_unit'
                  control={control}
                  render={() => (
                    <Select
                      defaultValue={itemUnit}
                      size='large'
                      style={{ width: '100%', borderRadius: '3px' }}
                      // className='inputLabel'
                      options={[
                        { value: 'Kg', Label: 'Kg' },
                        { value: 'gm', Label: 'gm' },
                        { value: 'Pcs', Label: 'Pcs' },
                        { value: 'lt', Label: 'lt' },
                        { value: 'ml', Label: 'ml' },
                      ]}
                      onChange={(e) => setItemUnit(e)}
                    />
                  )}
                />
              </Col>
              <Col span={24}>
                <Typography className='formHeading'>Item type</Typography>
              </Col>
              <Col span={24}>
                <Row>
                  {allItemType?.item_types?.map((data, i) => (
                    <Col span={8}>
                      <Row>
                        <Col span={19} key={i}>
                          <Controller
                            name='item_type_ids'
                            control={control}
                            render={() => (
                              <Typography
                                style={{
                                  backgroundColor: checkItemTypeSelection(
                                    data?.id
                                  )
                                    ? '#454545'
                                    : '',
                                  color: checkItemTypeSelection(data?.id)
                                    ? '#f6f6f6'
                                    : '',
                                  cursor: 'pointer',
                                  padding: '4px',
                                  marginBottom: '5px',
                                  fontFamily: 'Circular-400',
                                }}
                                key={i}
                                onClick={() => handleItemTypes(data?.id)}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Row justify='center' align='middle'>
                                      <Col
                                        span={4}
                                        style={{
                                          width: '20px',
                                          height: '30px',
                                        }}
                                      >
                                        <SvgComponent
                                          url={data.admin_type_image_url}
                                          width='29px'
                                          height='29px'
                                        />
                                      </Col>
                                      <Col span={12}>{data.name}</Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Typography>
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col span={1}>
                <Controller
                  render={() => (
                    <Checkbox
                      checked={checkIsGst}
                      size='large'
                      onChange={(e) => setCheckIsGst(e.target.checked)}
                    />
                  )}
                  name='is_gst'
                  control={control}
                />
              </Col>
              <Col span={23}>
                <Typography className='formHeading'>
                  Is this item taxable?
                </Typography>
              </Col>
            </Row>

            <Row gutter={[22, 22]} className='button'>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                <Button
                  className='detailsButton'
                  onClick={handleCancel}
                  size='large'
                >
                  Cancel
                </Button>
              </Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        </Spin>
      ) : (
        <>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurantDrawer()}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={() => handleCloseCategory()}
                >
                  Categories
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseCategoryDrawer()}
                  className='breadcrumRestaurant'
                >
                  {Category?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={handleCloseModal}
                  className='breadcrumRestaurant'
                >
                  Items
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  Add Existing Item
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='formHeading'>Add Existing Item</Typography>

              <Select
                allowClear
                size='large'
                mode='multiple'
                className='inputLabel'
                showArrow
                suffixIcon={<SearchOutlined style={{ fontSize: '19px' }} />}
                style={{
                  width: '100%',
                  border: '1px solid #262626',
                }}
                placeholder='Item select'
                onSearch={handleSearchItem}
                onChange={handleChangeItem}
                options={searchVal}
                filterOption={false}
              />
            </Col>
          </Row>
          <Typography
            style={{
              color: '#eb5757',
              fontFamily: 'Circular-900',
              marginTop: '8px',
              wordSpacing: '-3px',
            }}
          >
            {existingItemValidation}
          </Typography>
          <Row gutter={[22, 22]} justify='center' className='button'>
            <Col xl={4} lg={4} md={8} sm={12} xs={12}>
              <Button
                size='large'
                className='detailsButton'
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Col>
            <Col xl={4} lg={4} md={8} sm={12} xs={12}>
              <Button
                size='large'
                className='detailsButton'
                onClick={handleExistingItem}
              >
                Add
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default CategoryItemModalForm;
