import constants from '../Utils/constants';
const initialState = {
  isCfdImagesLoading: false,
  isCreateCfdImagesLoading: false,
  allCfdImages: [],
  CfdImages: {},
};

export const CfdImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CFD_IMAGES_REQUEST:
      return {
        ...state,
        isCfdImagesLoading: true,
      };
    case constants.GET_CFD_IMAGES_SUCCESS:
      return {
        ...state,
        isCfdImagesLoading: false,
        allCfdImages: action.payload,
      };
    case constants.GET_CFD_IMAGES_FAILURE:
      return {
        ...state,
        isCfdImagesLoading: false,
      };
    case constants.CREATE_CFD_IMAGES_REQUEST:
      return {
        ...state,
        isCreateCfdImagesLoading: true,
      };
    case constants.CREATE_CFD_IMAGES_SUCCESS:
      return {
        ...state,
        isCreateCfdImagesLoading: false,
      };
    case constants.CREATE_CFD_IMAGES_FAILURE:
      return {
        ...state,
        isCreateCfdImagesLoading: false,
      };
    case constants.DELETE_CFD_IMAGES_REQUEST:
      return {
        ...state,
        isCfdImagesLoading: true,
      };
    case constants.DELETE_CFD_IMAGES_SUCCESS:
      return {
        ...state,
        isCfdImagesLoading: false,
      };
    case constants.DELETE_CFD_IMAGES_FAILURE:
      return {
        ...state,
        isCfdImagesLoading: false,
      };
    case constants.UPDATE_CFD_IMAGES_REQUEST:
      return {
        ...state,
        isCreateCfdImagesLoading: true,
      };
    case constants.UPDATE_CFD_IMAGES_SUCCESS:
      return {
        ...state,
        isCreateCfdImagesLoading: false,
      };
    case constants.UPDATE_CFD_IMAGES_FAILURE:
      return {
        ...state,
        isCreateCfdImagesLoading: false,
      };
    case constants.GET_CFD_IMAGES_BY_ID_REQUEST:
      return {
        ...state,
        isCfdImagesLoading: true,
      };
    case constants.GET_CFD_IMAGES_BY_ID_SUCCESS:
      return {
        ...state,
        isCfdImagesLoading: false,
      };
    case constants.GET_CFD_IMAGES_BY_ID_FAILURE:
      return {
        ...state,
        isCfdImagesLoading: false,
      };
    case constants.REORDER_CFD_IMAGES_REQUEST:
      return {
        ...state,
        isCfdImagesLoading: true,
      };
    case constants.REORDER_CFD_IMAGES_SUCCESS:
      return {
        ...state,
        isCfdImagesLoading: false,
      };
    case constants.REORDER_CFD_IMAGES_FAILURE:
      return {
        ...state,
        isCfdImagesLoading: false,
      };
    default:
      return state;
  }
};
