import constants from '../Utils/constants';
const initialState = {
  isStateloading: false,
  isCreateStateoading: false,
  isDeleteStateLoading: false,
  isUpdateStateLoading: false,
  allState: [],
  allStates: [],
  error: '',
  setStateId: '',
  State: {},
  isStateByIdLoading: false,
};

export const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_STATE_REQUEST:
      return {
        ...state,
        isStateloading: true,
      };
    case constants.GET_STATE_SUCCESS:
      return {
        ...state,
        isStateloading: false,
        allState: action.payload,
      };
    case constants.GET_STATE_FAILURE:
      return {
        ...state,
        isStateloading: false,
      };
    case constants.GET_STATES_REQUEST:
      return {
        ...state,
        isCountryloading: true,
      };
    case constants.GET_STATES_SUCCESS:
      return {
        ...state,
        isCountryloading: false,
        allStates: action.payload,
      };
    case constants.GET_STATES_FAILURE:
      return {
        ...state,
        isCountryloading: false,
      };
    case constants.CREATE_STATE_REQUEST:
      return {
        ...state,
        isCreateStateoading: true,
      };
    case constants.CREATE_STATE_SUCCESS:
      return {
        ...state,
        isCreateStateoading: false,
      };
    case constants.CREATE_STATE_FAILURE:
      return {
        ...state,
        isCreateStateoading: false,
        error: action.payload?.name,
      };
    case constants.DELETE_STATE_REQUEST:
      return {
        ...state,
        isDeleteStateLoading: true,
      };
    case constants.DELETE_STATE_SUCCESS:
      return {
        ...state,
        isDeleteStateLoading: false,
      };
    case constants.DELETE_STATE_FAILURE:
      return {
        ...state,
        isDeleteStateLoading: false,
      };
    case constants.UPDATE_STATE_REQUEST:
      return {
        ...state,
        isUpdateStateLoading: true,
      };
    case constants.UPDATE_STATE_SUCCESS:
      return {
        ...state,
        isUpdateStateLoading: false,
      };
    case constants.UPDATE_STATE_FAILURE:
      return {
        ...state,
        isUpdateStateLoading: false,
      };
    case constants.GET_STATE_BY_ID_REQUEST:
      return {
        ...state,
        isStateByIdLoading: true,
      };
    case constants.GET_STATE_BY_ID_SUCCESS:
      return {
        ...state,
        isStateByIdLoading: false,
        State: action.payload,
      };
    case constants.GET_STATE_BY_ID_FAILURE:
      return {
        ...state,
        isStateByIdLoading: false,
      };
    case constants.GET_SELECTED_STATE_ID:
      return {
        ...state,
        setStateId: action.payload,
      };
    default:
      return state;
  }
};
