import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  message,
  Spin,
  Breadcrumb,
  Switch,
} from 'antd';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import ActiveSwitch from '../../assests/ActiveSwitch.svg';
import UnActiveSwitch from '../../assests/UnActiveSwitch.svg';

import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getSurveyQuestion,
  deleteSurveyQuestion,
  getSurveyQuestionById,
  updateSurveyQuestion,
} from '../../Actions/surveyQuestionAction';
import { useNavigate } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import filtericon from '../../assests/filtericon.svg';
import dragvertical from '../../assests/dragvertical.svg';
import { SortableHandle } from 'react-sortable-hoc';
import SurveyQuestionModalForm from './SurveyQuestionModalForm';
// import SurveyQuestionDetails from "./SurveyQuestionDetails";
const SurveyQuestion = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [paymentModeDetailId, setpaymentModeDetailId] = useState('');
  let UnpublishData = {};
  let PublishData = {};
  const {
    isSurveyQuestionloading,
    restaurant,
    setRestaurantId,
    allSurveyQuestion,
    setSurveyId,
  } = useSelector((state) => {
    const { restaurant, setRestaurantId } = state.restaurant;
    const { allSurveyQuestion, isSurveyQuestionloading } = state.surveyQuestion;
    const { allSurvey, isSurveyloading, setSurveyId } = state.survey;
    return {
      restaurant,
      isSurveyQuestionloading,
      setRestaurantId,
      allSurveyQuestion,
      setSurveyId,
    };
  });
  const { length } = allSurveyQuestion || {};
  useEffect(() => {
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
  }, [dispatch, currentPage, setSurveyId]);
  const handleSortButtonClick = () => {
    const sortedResults = [...allSurveyQuestion].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (sortOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setSortedData(sortedResults);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const DragHandle = SortableHandle(() => (
    <img src={dragvertical} style={{ cursor: 'pointer' }} />
  ));
  const successDelete = () => {
    setTimeout(() => {
      message.success(`SurveyQuestion Delete Successfully`);
    }, 1000);
    dispatch(getSurveyQuestion(`api/surveys?page=${currentPage}`));
  };
  const handleOpen = (Id) => {
    setpaymentModeDetailId(Id);

    localStorage.setItem('organization_id', setRestaurantId);
    // dispatch(getSurveyQuestionById(Id));

    setDrawerVisible(true);
    dispatch({
      type: 'GET_SELECTED_PRINTER_ID',
      payload: Id,
    });
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    // dispatch(getSurveyQuestionById(""));

    dispatch({
      type: 'GET_SELECTED_PRINTER_ID',
      payload: '',
    });
  };
  const handlePublishMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.active = false;
    dispatch(
      updateSurveyQuestion(
        PublishData,
        successUpdatePublishMethod,
        faliureDelete
      )
    );
  };
  const handleUnpublishMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.active = true;
    dispatch(
      updateSurveyQuestion(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success('Your SurveyQuestion Published Successfully');
    }, 1000);
    dispatch(getSurveyQuestion(`api/surveys?page=${currentPage}`));
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success('Your SurveyQuestion Unpublished Successfully');
    }, 1000);
    dispatch(getSurveyQuestion(`api/surveys?page=${currentPage}`));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const handleCloseSurveyQuestion = () => {
    navigate('/survey');
  };
  const Columns = [
    {
      className: 'drag-visible4',
      width: '4%',
      render: () => <DragHandle />,
    },
    {
      title: 'position',
      dataIndex: 'position',
      className: 'drag-visible-position4',
      width: '6%',
      render: (Id, { id, position }) => {
        return (
          <Row>
            <Col>
              <Typography>{position}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'question_text',
      dataIndex: 'question_text',
      className: 'drag-visible-question-text',
      width: '14%',
      render: (Id, { id, question_text }) => {
        return (
          <Row>
            <Col>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpen(id)}
              >
                {question_text}
              </Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'id',
      className: 'drag-visible-action4',
      width: '4%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteSurveyQuestion]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={isSurveyQuestionloading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}></Col>
        {isModalVisible ? (
          <SurveyQuestionModalForm
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></SurveyQuestionModalForm>
        ) : (
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumRestaurant'>
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseSurveyQuestion()}
                  style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Survey
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  SurveyQuestion
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={16}>
              <Typography className='heading'>SurveyQuestion</Typography>
            </Col>

            {/* <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <Row justify="end" gutter={[8, 8]}>
                <Col>
                    <Button
                       className="detailsButton"
                      onClick={handleSortButtonClick}
                      icon={<img src={filtericon} alt="props" />}
                    >
                      View Survey
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className="detailsButton"
                      onClick={(id) => setIsModalVisible(id)}
                      icon={<img src={plus} alt="props" />}
                    >
                     Add Questions
                    </Button>
                  </Col>
                  
                </Row>
              </Col> */}
            <Col>
              {/* <SurveyQuestionDetails
                  setDrawerVisible={setDrawerVisible}
                  drawerVisible={drawerVisible}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  setEditId={setEditId}
                  editId={editId}
                  paymentModeDetailId={paymentModeDetailId}
                  currentPage={currentPage}
                ></SurveyQuestionDetails> */}
              <CustomTable
                setSurveyId={setSurveyId}
                columns={Columns}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                data={sortOrder === 'asc' ? allSurveyQuestion : sortedData}
              />
            </Col>

            {allSurveyQuestion && length > 0 ? (
              <Col span={24}>
                <Row justify='center'>
                  <Pagination
                    total={allSurveyQuestion?.length}
                    onChange={(e) => setCurrentPage(e)}
                    responsive={true}
                    size='large'
                    current={currentPage}
                    showSizeChanger={false}
                  />
                </Row>
              </Col>
            ) : (
              ''
            )}
          </Row>
        )}
        {/* </Col> */}
      </Row>
    </Spin>
  );
};

export default SurveyQuestion;
