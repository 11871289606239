import React, { useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useDispatch, useSelector } from 'react-redux';
import { FaStarOfLife } from 'react-icons/fa6';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import {
  createLayout,
  getLayout,
  updateLayout,
} from '../../Actions/LayoutAction';
import { layoutSchema } from '../../Utils/Schema';
const LayoutModalForm = (props) => {
  const [failureValue, setFailureValue] = useState('');
  const { editId, setEditId, setIsModalVisible, restaurant } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allLayout, isCreateLayoutLoading, isUpdateLayoutLoading } =
    useSelector((state) => {
      const { allLayout, isCreateLayoutLoading, isUpdateLayoutLoading } =
        state.layout;
      const { restaurant } = state.restaurant;
      return {
        allLayout,
        isCreateLayoutLoading,
        isUpdateLayoutLoading,
        restaurant,
      };
    });
  const the_layout = _.filter(allLayout, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      layout_name: the_layout[0]?.layout_name,
    },
    resolver: yupResolver(layoutSchema),
  });

  const handleCloseLayout = () => {
    setIsModalVisible(false);
    dispatch(getLayout(`api/organization_layouts`));
  };
  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCancel = () => {
    setEditId('');
    setValue('layout_name', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset();
  };
  const handleCreateLayout = (data) => {
    dispatch(createLayout(data, successCallbackLayout, failureCallbackLayout));
  };
  const successCallbackLayout = () => {
    setIsModalVisible(false);
    dispatch(getLayout(`api/organization_layouts`));
    setEditId('');
    setTimeout(() => {
      message.success('Your Layout Created Successfully');
    }, 1000);
  };
  const failureCallbackLayout = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
    setFailureValue(failureResponse.layout_name);
  };

  const handleEditLayout = (data) => {
    data.id = editId;
    dispatch(updateLayout(data, UpdateCallBackLayout, failureUpdateCallBack));
  };
  const UpdateCallBackLayout = () => {
    reset();
    setIsModalVisible(false);
    setEditId('');
    dispatch(getLayout(`api/organization_layouts`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Layout Updated Successfully');
    }, 1000);
  };

  const failureUpdateCallBack = (failureResponse) => {
    setFailureValue(failureResponse.layout_name);
  };

  return (
    <Spin spinning={editId ? isUpdateLayoutLoading : ''}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => navigate(-1)}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={handleCloseLayout}
            >
              Layout
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update layout' : 'Add new layout'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {editId ? 'Update layout' : 'Add new layout'}
          </Typography>
        </Col>
      </Row>
      <form
        onSubmit={handleSubmit(editId ? handleEditLayout : handleCreateLayout)}
      >
        <Spin spinning={isCreateLayoutLoading}>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography
                className='formHeading'
                style={{ marginBottom: '-15px' }}
              >
                Layout Name
                <FaStarOfLife
                  style={{
                    fontSize: '7px',
                    color: '#eb5757',
                    position: 'absolute',
                    top: '7%',
                    marginLeft: '2px',
                  }}
                />
              </Typography>
            </Col>
            <Col span={12}>
              <Controller
                name='layout_name'
                control={control}
                className='inputLabel'
                as={<Input size='large' />}
              />
            </Col>
          </Row>
          {errors.layout_name && (
            <p style={{ color: '#eb5757' }}>{errors.layout_name.message}</p>
          )}
          {failureValue && <p style={{ color: '#eb5757' }}>{failureValue}</p>}
          <Col span={12} style={{ marginTop: '40px' }}>
            <Row gutter={[16, 16]} className='button' justify='center'>
              <Col xl={6} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    className={isDirty ? 'detailsButton' : 'classButton'}
                    onClick={handleeditCancel}
                    size='large'
                    disabled={!isDirty}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className='detailsButton'
                    onClick={handleCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={6} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className={isDirty ? 'detailsButton' : 'classButton'}
                    htmlType='submit'
                    disabled={!isDirty}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Spin>
      </form>
    </Spin>
  );
};

export default LayoutModalForm;
