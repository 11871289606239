import React, { useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Button,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import Delete from '../../assests/Delete.svg';
import { getCategory, deleteCategory } from '../../Actions/CategoryAction';
import { useNavigate, useParams } from 'react-router-dom';
import menuButton from '../../assests/menuButton.svg';
import { FiEdit2 } from 'react-icons/fi';

import { useDispatch, useSelector } from 'react-redux';
import { falseyValueCases } from '../CommonLogics/CommonMethods';
const CategoryDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    setIsModalVisible,
    categoryDetailId,
    currentPage,
  } = props;
  const navigate = useNavigate();
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const { orgId, categoryId } = useParams();

  const handleCloseDrawer = () => {
    navigate(`/restaurants/${orgId}/category`);
    // setDrawerVisible(false);
  };
  const { Category } = useSelector((state) => {
    const { Category } = state.category;
    return {
      Category,
    };
  });

  const onHandleNavigate = (path) => {
    navigate(path);
  };
  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={!falseyValueCases(drawerVisible) ? drawerVisible : true}
      width={width > 400 ? '400px' : '100%'}
    >
      {Category && (
        <div>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Category Name </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {Category?.name}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Position </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {Category?.position}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Total Items </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {Category?.total_item}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={14}>
              <Button
                size='large'
                className='detailsButton'
                onClick={() =>
                  onHandleNavigate(
                    `/restaurants/${orgId}/category/${categoryId}/categoryItems`
                  )
                }
                icon={<img src={menuButton} alt='props' />}
              >
                Category Items
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default CategoryDetails;
