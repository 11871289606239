import API from '../Utils/API';

export const getQrCodes = (url) => (dispatch) => {
  dispatch({ type: 'GET_QR_CODES_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_QR_CODES_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_QR_CODES_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createQrCodes =
  (data, successCreateQrCodes, failureCreateQrCodes) => (dispatch) => {
    dispatch({ type: 'CREATE_QR_CODES_REQUEST' });
    API.post('api/organization_qr_codes', data)
      .then((response) => {
        dispatch({ type: 'CREATE_QR_CODES_SUCCESS', payload: response.data });
        successCreateQrCodes && successCreateQrCodes();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_QR_CODES_FAILURE',
          payload: error.response.data,
        });
        failureCreateQrCodes && failureCreateQrCodes(error.response.data);
      });
  };
export const deleteQrCodes =
  (QrCodesId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_QR_CODES_REQUEST' });
    API.delete(`api/organization_qr_codes/${QrCodesId}`)
      .then((response) => {
        dispatch({ type: 'DELETE_QR_CODES_SUCCESS', payload: response.data });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_QR_CODES_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateQrCodes = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_QR_CODES_REQUEST' });
  API.put(`api/organization_qr_codes/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_QR_CODES_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_QR_CODES_FAILURE',
        payload: error.response.data,
      });
    });
};
export const getQrCodesById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_QR_CODES_BY_ID_REQUEST' });
  API.get(`api/organization_qr_codes/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_QR_CODES_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_QR_CODES_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const reOrderQrCodes = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'REORDER_QR_CODES_REQUEST' });
  API.put(`api/organization_qr_codes/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'REORDER_QR_CODES_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'REORDER_QR_CODES_FAILURE',
        payload: error.response.data,
      });
    });
};
