import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { useNavigate } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import View from '../../assests/View.svg';
import dragvertical from '../../assests/dragvertical.svg';
import { SortableHandle } from 'react-sortable-hoc';
import CfdImgesModalForm from './CfdImgesModalForm';
import { deleteCfdImage, getCfdImages } from '../../Actions/CfdImagesAction';
import CfdImageDetails from './CfdImageDetails';

const CfdImages = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [cfdImageId, setCfdImageId] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const { restaurant, setRestaurantId, allCfdImages, isCfdImagesLoading } =
    useSelector((state) => {
      const { allCfdImages, isCfdImagesLoading } = state.CfdImages;
      const { restaurant, setRestaurantId } = state.restaurant;
      return {
        restaurant,
        allCfdImages,
        setRestaurantId,
        isCfdImagesLoading,
      };
    });
  useEffect(() => {
    dispatch(getCfdImages(`api/organization_carousel_screens`));
  }, [dispatch]);

  const handleOpen = (Id) => {
    setCfdImageId(Id);
    localStorage.setItem('organization_id', setRestaurantId);
    setDrawerVisible(true);
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleCreate = (id) => {
    setIsModalVisible(id);
    setEditId(false);
  };

  const successDeleteCfdImage = () => {
    dispatch(getCfdImages(`api/organization_carousel_screens`));
    setTimeout(() => {
      message.success(`CFD Image Deleted Successfully`);
    }, 1000);
  };

  const DragHandle = SortableHandle(() => (
    <img src={dragvertical} style={{ cursor: 'pointer' }} />
  ));

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const Columns = [
    {
      className: 'drag-visible',
      width: '4%',
      render: () => <DragHandle />,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      className: 'drag-visible-position',
      width: '6%',
      render: (position) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {position}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Image',
      dataIndex: 'file_name',
      className: 'drag-visible-name',
      width: '18%',
      render: (Id, { id, file_name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${file_name} Details`}>
                <Typography>{file_name}</Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      className: 'drag-visible-action',
      width: '5%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <Tooltip title='View'>
                <img
                  src={View}
                  style={{
                    cursor: 'pointer',
                    position: 'relative',
                    top: '15%',
                    padding: '0px 5px 0px 5px',
                  }}
                  onClick={() => handleOpen(id)}
                />
              </Tooltip>
            </Col>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteCfdImage]}
                customMethod={successDeleteCfdImage}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isCfdImagesLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <CfdImgesModalForm
              restaurant={restaurant}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
            ></CfdImgesModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => navigate(-1)}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    CFD images
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>
                  Customer facing display images
                </Typography>
              </Col>

              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleCreate}
                      icon={<img src={plus} alt='props' />}
                    >
                      Add new image
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <CfdImageDetails
                  setDrawerVisible={setDrawerVisible}
                  drawerVisible={drawerVisible}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  setEditId={setEditId}
                  editId={editId}
                  cfdImageId={cfdImageId}
                  setCfdImageId={setCfdImageId}
                ></CfdImageDetails>
                <CustomTable
                  type='cfdimages'
                  columns={Columns}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={allCfdImages}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default CfdImages;
