import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { QrcodeSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getQrCodes,
  createQrCodes,
  updateQrCodes,
} from '../../Actions/QrCodesAction';
import { getOrganizationTableById } from '../../Actions/OrganizationTableAction';
const QrCodesModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, setCurrentPage, tabKey } =
    props;
  const dispatch = useDispatch();
  const { Option } = Select;
  const [preloadedQrCodesValue, setPreloadedQrCodesValue] = useState('');
  const {
    isQrCodesloading,
    isCreateQrCodesoading,
    isUpdateQrCodesLoading,
    allQrCodes,
    allOrganizationTable,
  } = useSelector((state) => {
    const {
      allQrCodes,
      isQrCodesloading,
      isCreateQrCodesoading,
      isUpdateQrCodesLoading,
    } = state.qrCodes;
    const { allOrganizationTable } = state.organizationTable;
    return {
      isQrCodesloading,
      isCreateQrCodesoading,
      isUpdateQrCodesLoading,
      allQrCodes,
      allOrganizationTable,
    };
  });
  const [tableId, settableId] = useState(
    allOrganizationTable?.organization_tables?.table_number
  );

  const selectOrganizationTable = (e) => {
    const tableObj = _.filter(
      allOrganizationTable?.organization_tables,
      function (o) {
        return o.id === e;
      }
    );
    settableId(tableObj[0]?.organization_table_id);
    dispatch(getOrganizationTableById(tableObj[0]?.id));
  };
  const the_Qrcode = _.filter(allQrCodes, function (o) {
    return o.id === editId;
  });
  if (editId) {
    the_Qrcode[0].organization_table_id =
      allQrCodes?.organization_table?.table_number;
  }
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedQrCodesValue,
    },
    resolver: yupResolver(QrcodeSchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedQrCodesValue(the_Qrcode[0]);
      reset(the_Qrcode[0]);
    }
  }, [the_Qrcode[0], reset]);

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('description', '');
    setValue('qr_type', '');
    clearErrors();
  };
  const handleCancel = () => {
    setEditId('');
    setValue('name', '');
    setValue('description', '');
    setValue('qr_type', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedQrCodesValue);
    clearErrors();
  };
  const handleCreate = (data) => {
    data.qr_type = tabKey === '1' ? 'Take Away' : 'Dine In';
    dispatch(createQrCodes(data, successCreate, failureCreate));
  };
  const successCreate = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    if (tabKey === '1') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Take Away`));
    } else if (tabKey === '2') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Dine In`));
    }
    setValue('name', '');
    setValue('description', '');
    setValue('qr_type', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your QR Code Created Successfully');
    }, 1000);
  };

  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name : 'Something went wrong.'
      );
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    data.organization_table_id = tableId;
    dispatch(updateQrCodes(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('description', '');
    setValue('qr_type', '');
    if (tabKey === '1') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Take Away`));
    } else if (tabKey === '2') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Dine In`));
    }
    clearErrors();
    setTimeout(() => {
      message.success('Your QR Code Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
              className='breadcrumRestaurant'
            >
              QR Code
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update' : 'Create New'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Spin
        style={{ height: '100%', maxHeight: 'unset' }}
        spinning={editId ? isUpdateQrCodesLoading : isCreateQrCodesoading}
        size='large'
      >
        <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
          <Spin spinning={isQrCodesloading}>
            <Row gutter={[16, 16]}></Row>

            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>QR Name</Typography>
                <Controller
                  as={<Input size='large' />}
                  name='name'
                  control={control}
                  className='inputLabel'
                />
                {errors.name && (
                  <p style={{ color: 'red' }}>{errors.name.message}</p>
                )}
              </Col>
            </Row>

            {/* <Row gutter={[16, 16]} className="button">
              <Col span={24} className="formRows">
                <Typography className="formHeading">QR Type</Typography>
                <Controller
                  name="qr_type"
                  as={
                    <Select
                      className="selection"
                      placeholder="Select User type"
                      // options={options}
                      style={{ width: "100%", borderRadius: "4px" }}
                      size="large"
                    >
                      {tabKey === "1" &&<Option value="Take Away">Take Away</Option>}
                      {tabKey === "2" &&<Option value="Dine In">Dine in</Option>}
                    </Select>
                  }
                  control={control}
                ></Controller>

                {errors.qr_type && (
                  <p style={{ color: "red" }}>{errors.qr_type.message}</p>
                )}
              </Col>
            </Row> */}
            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>Description</Typography>
                <Controller
                  as={<Input size='large' />}
                  name='description'
                  control={control}
                  className='inputLabel'
                />
              </Col>
            </Row>
            {tabKey === '2' && (
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Select Table Number
                  </Typography>
                  <Controller
                    name='organization_table_id'
                    as={
                      <Select
                        placeholder='Select Table Number'
                        style={{ width: '100%', borderRadius: '4px' }}
                        showSearch={true}
                        className='selection'
                        options={allOrganizationTable?.organization_tables?.map(
                          (val, i) => {
                            return {
                              label: val.table_number,
                              value: val.id,
                            };
                          }
                        )}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={selectOrganizationTable}
                        size='large'
                      ></Select>
                    }
                    control={control}
                  />
                </Col>
              </Row>
            )}
            <Row gutter={[22, 22]} className='button'>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    className='detailsButton'
                    onClick={handleeditCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className='detailsButton'
                    onClick={handleCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Spin>
        </form>
      </Spin>
    </div>
  );
};

export default QrCodesModalForm;
