import React, { useState, useEffect } from 'react';
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  message,
  Checkbox,
  Space,
  Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  getStationsItems,
  createStationsItems,
  deleteStationsItems,
  updateStationsItems,
} from '../../Actions/StationItemAction';
import filter from 'lodash/filter';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { getCategoryItem } from '../../Actions/CategoryItemAction';
import lib, { DownOutlined, UpOutlined } from '@ant-design/icons';
import List_Add from '../../assests/List_Add.svg';
import List_remove from '../../assests/List_remove.svg';
import { getStations } from '../../Actions/StationsAction';
import {
  getCategory,
} from '../../Actions/CategoryAction';
const StationItemModalForm = (props) => {
  const { editItemId, setEditItemId, currentPage,stationsDetailId } = props;
  const dispatch = useDispatch();
  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');
  const { allCategoryItem, allStationsItems,isStationsItemsloading } = useSelector((state) => {
    const { allStationsItems,isStationsItemsloading } = state.stationsItem;
    const { allCategoryItem } = state.categoryItem;
    return {
      allCategoryItem,
      allStationsItems,
      isStationsItemsloading
    };
  });
  useEffect(() => {
    dispatch(getCategory(`api/categories`));
    dispatch(getCategoryItem(`api/category_items?published=true`));
    dispatch(
      getStationsItems(`api/station_items?station_id=${editItemId}`)
    );
  }, []);
  const AllItemsByCategory = _.sortBy(
    _.uniqBy(
      allCategoryItem?.category_items?.map((item) => {
        return allCategoryItem?.category_items?.find(
          (categoryItem) => categoryItem?.category?.id === item?.category?.id
        )
          ? {
              ...item?.category,
              items: _.compact(
                allCategoryItem?.category_items?.map((categoryItem) => {
                  return categoryItem?.category?.id === item?.category?.id
                    ? {
                        ...categoryItem,
                      }
                    : false;
                })
              ),
            }
          : null;
      }),
      'id'
    ),
    ['position']
  );
  const [visibleDataId, setVisibleDataId] = useState(null);

  const the_stationItem = _.filter(allStationsItems, function (o) {
    return o.id === editItemId;
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedRestaurantValue,
      published: false,
    },
  });
  useEffect(() => {
    if (editItemId) {
      setPreloadedRestaurantValue(the_stationItem[0]);
      reset(the_stationItem[0]);
    }
  }, [the_stationItem[0], reset]);
  const handleCreateStationItem = (e, data, type) => {
    const obj = {};
    obj.station_id = editItemId;
    if (type === 'add-category') {
      obj.category_id = [data];
    } else if (type === 'add-item') {
      obj.item_ids = [data];
    }
    if (type === 'add-category') {
      setVisibleDataId(data);
    }
    dispatch(createStationsItems(obj, successCreateStationItem, failureCreateStationITem));
  };

  const successCreateStationItem = (res) => {
    reset();
    dispatch(
      getStationsItems(`api/station_items?station_id=${editItemId}`)
    );
    dispatch(getStations(`api/stations?page=${currentPage}`));
    setValue('category_id', '');
    setValue('item_ids',"")
    clearErrors();
    setValue('station_id', '');
    setTimeout(() => {
      message.success('Successfully create StationItem.');
    }, 1000);
  };

  const failureCreateStationITem = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name : 'Something went wrong.'
      );
    }, 1000);
  };

  const handleEditStaionItem = (data) => {
    data.id = setEditItemId;
    dispatch(updateStationsItems(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    dispatch(
      getStationsItems(`api/station_items?station_id=${editItemId}`)
    );
    clearErrors();
    setTimeout(() => {
      message.success('Successfully update Station Item.');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const deleteStationAllItems = (value, type) => {
    const FoundStationCategoryItems = allStationsItems?.filter(
      (stationItem) => {
        return value.items?.find(
          (categoryItem) => categoryItem?.item?.id === stationItem?.item?.id
        );
      }
    );

    const stationItemIds = FoundStationCategoryItems?.map((stationItem) => {
      return stationItem?.id;
    });
    const obj = {};
    obj.station_id = editItemId;
    if (type === 'add-category') {
      obj.station_item_ids = [stationItemIds];
    } else if (type === 'delete-item') {
      obj.station_item_ids = [value?.station_item_id];
    }
    dispatch(deleteStationsItems(obj, successDelete));
  };

  const successDelete = () => {
    dispatch(
      getStationsItems(`api/station_items?station_id=${editItemId}`)
    );
    dispatch(getStations(`api/stations?page=${currentPage}`));
    setTimeout(() => {
      message.success('Successfully deleted a station Item.');
    }, 500);
  };
  useEffect(() => {
    if (!editItemId) {
      setEditItemId('');
    }
  }, [setValue, !editItemId]);

  const toggleVisibility = (id) => {
    setVisibleDataId(visibleDataId === id ? null : id);
  };
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px rgba(246, 246, 246, 1) inset',
  };

  return (
    <div>
      <form onSubmit={handleSubmit(editItemId ? handleEditStaionItem : handleCreateStationItem)}>
        <Row gutter={[16, 16]} className='button'>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className='formRows'>
            <Typography className='formHeading'>Categories</Typography>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className='formRows'>
              {/* <Space direction='vertical'> */}
              {/* {isStationsItemsloading && ( */}
                <div>
                {AllItemsByCategory?.map((category) => {
                  const FilterStationItem = (type) => {
                    return allStationsItems?.filter((stationItem) => {
                      const isItemFound = category?.items?.find(
                        (item) => item?.item?.id === stationItem?.item?.id
                      );
                      return type === 'belonging' ? isItemFound : !isItemFound;
                    });
                  };
                  const FoundAllStationItems = FilterStationItem('belonging');

                  return (
                  <div style={inputStyles}>
                    <Card style={inputStyles}>
                      <Row
                        gutter={[8, 8]}
                        style={{ backgroundColor: 'rgba(246, 246, 246, 1)' }}
                      >
                        <Col span={4} key={category.id}>
                          <Button
                            onClick={() => toggleVisibility(category.id)}
                            style={inputStyles}
                            className='buttonStation'
                            icon={
                              visibleDataId === category.id ? (
                                <DownOutlined />
                              ) : (
                                <UpOutlined />
                              )
                            }
                          ></Button>
                        </Col>

                        <Col span={12}>
                          <Typography className='text'>
                            {category.name}
                          </Typography>
                        </Col>
                        <Col span={4} className="buttonadd">
                          {' '}
                          <Tooltip title='Select all StationItem'>
                            <Button
                              className=
                              {
                                FoundAllStationItems?.length ===
                                category?.items?.length
                                  ? 'disabled'
                                  : ''
                              }
                              icon={
                                <img
                                  src={List_Add}
                                  key={category.id}
                                  value={category?.id}
                                  alt='Image 1'
                                  className={
                                    FoundAllStationItems?.length ===
                                    category?.items?.length
                                      ? 'disableEvents'
                                      : ''
                                  }
                                  onClick={(e) =>
                                    handleCreateStationItem(e, category.id, 'add-category')
                                  }
                                />
                              }
                            ></Button>
                          </Tooltip>
                        </Col>
                        <Col span={4} className="buttonadd">
                          <Tooltip title='Unselect all StationItems'>
                            <Button
                              className={
                                FoundAllStationItems?.length === 0
                                  ? 'disabled'
                                  : ''
                              }
                              icon={
                                <img
                                  src={List_remove}
                                  alt='Image 2'
                                  onClick={() =>
                                    deleteStationAllItems(
                                      category,
                                      'add-category'
                                    )
                                  }
                                  className={
                                    FoundAllStationItems?.length === 0
                                      ? 'disableEvents'
                                      : ''
                                  }
                                />
                              }
                            ></Button>
                          </Tooltip>
                        </Col>
                        <Col span={24}>
                          {visibleDataId === category.id && (
                            <Row>
                              <Col span={24}>
                                {' '}
                                {category?.items?.length > 0 ? (
                                  category?.items?.map((categoryitem) => {
                                    const checkedItem = filter(
                                      allStationsItems,
                                      (stationItem) => {
                                        return (
                                          stationItem?.item?.id ===
                                          categoryitem?.item?.id
                                        );
                                      }
                                    );
                                    const isItemChecked =
                                      checkedItem?.[0]?.item?.id ===
                                      categoryitem?.item?.id;
                                    return (
                                      <Row>
                                        <Col span={24}>
                                          <Checkbox
                                            checked={isItemChecked}
                                            className='checkBox'
                                            key={categoryitem.item.value}
                                            value={categoryitem.item.id}
                                            style={inputStyles}
                                            onClick={(e) =>
                                              isItemChecked
                                                ? deleteStationAllItems(
                                                    {
                                                      ...categoryitem,
                                                      station_item_id:
                                                        isItemChecked
                                                          ? checkedItem?.[0]?.id
                                                          : null,
                                                    },
                                                    'delete-item',
                                                    category
                                                  )
                                                : handleCreateStationItem(
                                                    e,
                                                    categoryitem.item.id,
                                                    'add-item'
                                                  )
                                            }
                                          >
                                            <Typography className='text'>
                                              {' '}
                                              {categoryitem.item.name}
                                            </Typography>
                                          </Checkbox>
                                        </Col>
                                      </Row>
                                    );
                                  })
                                ) : (
                                  <Typography
                                    message={`No items found  Category`}
                                  />
                                )}
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </div>
                 );}
                )}
                </div>
              {/* // )} */}
            
              {/* </Space> */}
            </Col>
          
        </Row>
      </form>
    </div>
  );
};

export default StationItemModalForm;

