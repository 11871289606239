import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Typography, Spin } from 'antd';
import { useBreakpoints } from '../../Screens/CommonLogics/ResponsiveBreakPoints';
import { IoArrowBack } from 'react-icons/io5';
import OtpInput from 'react-otp-input';
import { useForm } from 'react-hook-form';
import { FaStarOfLife } from 'react-icons/fa';
import '../../App.css';
import './OtpStyle.css';
import {
  verifyOtpAction,
  loginUserAction,
} from '../../Actions/authenticationAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const OtpAuthentication = () => {
  const { IsMD, IsLG, IsXL, IsXXL } = useBreakpoints();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isVerifyOtpLoading,
    loginOtp,
    currentUser,
    phoneNumberwithCountryCode,
  } = useSelector((state) => {
    const {
      isVerifyOtpLoading,
      loginOtp,
      currentUser,
      phoneNumberwithCountryCode,
    } = state.authentication;
    return {
      loginOtp,
      isVerifyOtpLoading,
      currentUser,
      phoneNumberwithCountryCode,
    };
  });
  const { handleSubmit, setError } = useForm({});
  const [seconds, setSeconds] = useState(60);
  const [failureMessage, setFailureMessage] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  useEffect(() => {
    setOtpValue(loginOtp);
  }, [loginOtp]);

  const handleOtpInput = (otpValue) => {
    setOtpValue(otpValue);
  };

  const handleVerifyOtpMethod = (data) => {
    data.phone = phoneNumberwithCountryCode.phone;
    data.country_code = phoneNumberwithCountryCode.country_code;
    data.code = otpValue;
    dispatch(verifyOtpAction(data, successVerifyOtp, failureVerifyOtp));
  };
  const successVerifyOtp = () => {
    navigate('/restaurants');
  };
  const failureVerifyOtp = () => {
    setFailureMessage(true);
    setError('code', { type: 'custom', message: 'Otp is invalid' });
  };

  const data = {
    phone: '',
  };

  const handleResendMethod = () => {
    dispatch(loginUserAction(phoneNumberwithCountryCode));
  };

  setTimeout(() => {
    if (seconds >= 0) {
      setSeconds(seconds - 1);
    } else {
      setSeconds(-1);
    }
  }, 1000);

  const height = window.innerHeight;
  return (
    <Row
      style={{
        overflow: 'hidden',
        height: '100vh',
        background: '#F6F6F6',
      }}
      align='middle'
    >
      <Col span={24} align='middle'>
        <Row justify='center'>
          <Col
            xs={20}
            sm={10}
            md={9}
            lg={9}
            xl={8}
            xxl={8}
            style={{
              backgroundColor: '#F6F6F6',
              boxShadow:
                '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10)',
            }}
          >
            <Row
              gutter={[
                0,
                { xs: 120, sm: 100, md: 110, lg: 100, xl: 100, xxl: 140 },
              ]}
            >
              <Col span={24}>
                <Typography className='verify'>
                  <IoArrowBack
                    onClick={() => navigate(-1)}
                    style={{
                      position: 'absolute',
                      left: '3%',
                      top: '15',
                      cursor: 'pointer',
                    }}
                  />
                  Verify Phone Number
                </Typography>
              </Col>

              <Col span={24} style={{ marginBottom: '20px' }}>
                <Row>
                  <Col span={24}>
                    <Row justify='center'>
                      <form onSubmit={handleSubmit(handleVerifyOtpMethod)}>
                        <Col
                          className='otp'
                          xs={17}
                          sm={19}
                          md={17}
                          lg={15}
                          xl={13}
                          xxl={12}
                        >
                          <Typography>
                            OTP
                            <FaStarOfLife
                              style={{
                                fontSize: '7px',
                                color: '#eb5757',
                                position: 'absolute',
                                marginLeft: '2px',
                              }}
                            />
                          </Typography>
                        </Col>
                        <Col xs={18} sm={20} md={18} lg={16} xl={13} xxl={12}>
                          <OtpInput
                            inputStyle={{
                              margin: '7px',
                              boxShadow:
                                '0 0px 0px 0 rgba(0, 0, 0, 0.1), 0px 0.5px 1px 0.5px rgba(0, 0, 0, 0.10)',
                              width: '100%',
                              height: '56px',
                              background: '#F5F6F8',
                              border: '1px solid #DEDEDE',
                              boxSizing: 'border-box',
                              borderRadius: '4px',
                            }}
                            separator={
                              <span style={{ marginLeft: '15px' }}></span>
                            }
                            numInputs={4}
                            value={otpValue}
                            onChange={handleOtpInput}
                            shouldAutoFocus={true}
                            renderInput={(props) => <input {...props} />}
                          ></OtpInput>
                        </Col>
                        <Col
                          className='failMsg'
                          xs={17}
                          sm={19}
                          md={17}
                          lg={15}
                          xl={13}
                          xxl={12}
                        >
                          {failureMessage && (
                            <p
                              style={{
                                color: '#eb5757',
                              }}
                            >
                              OTP is expired or invalid
                            </p>
                          )}
                        </Col>

                        <Col xs={9} sm={10} md={10} lg={8} xl={8} xxl={8}>
                          <Button
                            className='editVerifybtn'
                            htmlType='submit'
                            disabled={isVerifyOtpLoading}
                          >
                            {!isVerifyOtpLoading ? (
                              'Verify OTP'
                            ) : (
                              <AiOutlineLoading3Quarters className='loading' />
                            )}
                          </Button>
                        </Col>
                        <Col span={24}>
                          {seconds >= 0 ? (
                            <p
                              style={{
                                color: '#191d23',
                                margin: '10px 0px 2px 0px',
                                fontFamily: 'Circular-900',
                                wordSpacing: '-4px',
                              }}
                            >
                              OTP expires in {seconds} s
                            </p>
                          ) : (
                            <p
                              style={{
                                color: '#191d23',
                                margin: '10px 0px 2px 0px',
                                fontFamily: 'Circular-900',
                                wordSpacing: '-4px',
                              }}
                            >
                              OTP is expired
                            </p>
                          )}
                        </Col>
                        <Col span={24}>
                          <Button
                            type='link'
                            className='resendButton'
                            onClick={() => handleResendMethod()}
                            // disabled={seconds !== -1}
                          >
                            Request OTP
                            <hr style={{ position: 'relative', top: '-45%' }} />
                          </Button>
                        </Col>
                      </form>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={24} />
            </Row>
          </Col>

          <Col
            className='logoimage'
            xs={20}
            sm={10}
            md={9}
            lg={9}
            xl={8}
            xxl={8}
          >
            <img src='./logo.svg' height='226px' width='200px' />
          </Col>
        </Row>
      </Col>
    </Row>

    // <Spin spinning={isVerifyOtpLoading} size="large">
    //   <Row justify="center" align="middle" gutter={[0, height / 16]}>
    //     {(IsXL || IsLG || IsMD || IsXXL) && <Col span={24}></Col>}
    //     <Col span={24}></Col>
    //     {(IsXL || IsLG || IsMD || IsXXL) && <Col span={24}></Col>}

    //     <Col xs={{ span: 10, offset: 1 }} xl={10} md={10} sm={10}>
    //       <Row>
    //         <Col span={24}>
    //           <Typography className="headerTitle">
    //             OTP Verification
    //           </Typography>
    //         </Col>
    //       </Row>
    //       <Row gutter={[0, 16]} justify="center">
    //         <Col span={24}></Col>
    //         <Col xl={24} sm={12} lg={24} md={24}>
    //           <Typography className="pageContent">
    //             We've sent an OTP to your phone number
    //           </Typography>
    //         </Col>
    //       </Row>

    //       <form onSubmit={handleSubmit(handleVerifyOtpMethod)}>
    //         <Row gutter={[0, 23]}>
    //           <Col span={24} />
    //           <Col xl={20} lg={20} xs={22} md={22} sm={15}>
    //             <OtpInput
    //               inputStyle={{
    //                 margin: "10px",
    //                 width: "100%",
    //                 height: "56px",
    //                 background: "#F5F6F8",
    //                 border: "1px solid #DEDEDE",
    //                 boxSizing: "border-box",
    //                 borderRadius: "4px",
    //               }}
    //               separator={<span style={{ marginLeft: "15px" }}></span>}
    //               numInputs={4}
    //               value={otpValue}
    //               onChange={handleOtpInput}
    //               shouldAutoFocus={true}
    //               renderInput={(props) => <input {...props} />}
    //             ></OtpInput>
    //             {seconds >= 0 ? (
    //               <p style={{ color: "red", marginTop: "10px" }}>
    //                 OTP expires in {seconds} s
    //               </p>
    //             ) : (
    //               <p style={{ color: "red", marginTop: "10px" }}>
    //                 OTP is expired
    //               </p>
    //             )}
    //             {failureMessage && (
    //               <p style={{ color: "red", marginTop: "10px" }}>
    //                 OTP is expired or invalid
    //               </p>
    //             )}
    //           </Col>
    //         </Row>
    //         <Row justify="start">
    //           <Col span={24} />
    //           <Col>
    //             <Button
    //               type="link"
    //               className="resendButton"
    //               onClick={() => handleResendMethod()}
    //               disabled={seconds !== -1}
    //             >
    //               Resend
    //             </Button>
    //           </Col>
    //         </Row>
    //         <Row gutter={[0, 19]}>
    //           <Col span={24} />
    //           <Col>
    //             <Button  className="editDesignationButton" htmlType="submit">
    //               Verify
    //             </Button>
    //           </Col>
    //         </Row>
    //       </form>
    //     </Col>
    //   </Row>
    // </Spin>
  );
};
export default OtpAuthentication;
