import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  Tooltip,
  Breadcrumb,
  Button,
  message,
} from 'antd';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import { getStationsById } from '../../Actions/StationsAction';
import {
  getStationsItemsById,
  getStationsItems,
  deleteStationsItems,
} from '../../Actions/StationItemAction';
import { getRestaurant } from '../../Actions/RestaurantAction';
import { useNavigate } from 'react-router-dom';
import dragvertical from '../../assests/dragvertical.svg';
import StationItemModalForm from './StationItemModalForm';
import StationsDetails from './StationsDetails';
import { DeleteColumnAction } from '../CustomTable/CustomTableColumnAction';
const StationsItem = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [stationsDetailId, setstationsDetailId] = useState('');
  const [tabKey, setTabkey] = useState('1');
  const {
    restaurant,
    setRestaurantId,
    allStationsItems,
    Stations,
    setStationsId,
  } = useSelector((state) => {
    const { restaurant, setRestaurantId } = state.restaurant;
    const { allStationsItems } = state.stationsItem;
    const { Stations, setStationsId } = state.stations;
    return {
      restaurant,
      setRestaurantId,
      allStationsItems,
      Stations,
      setStationsId,
    };
  });
  const { length } = allStationsItems || {};
  // useEffect(() => {
  //   dispatch(getStationsItems(`api/station_items?station_id=${setStationsId}&page=${currentPage}`));
  // }, [dispatch, currentPage,setStationsId]);

  const handleOpen = (Id) => {
    setstationsDetailId(Id);
    localStorage.setItem('organization_id', setRestaurantId);
    dispatch(getStationsById(Id));
    setDrawerVisible(true);
    dispatch({
      type: 'GET_SELECTED_STATIONS_ID',
      payload: Id,
    });
  };
  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getStationsById(''));
    dispatch({
      type: 'GET_SELECTED_STATIONS_ID',
      payload: '',
    });
  };
  const handleCloseCategory = () => {
    navigate('/stations');
    dispatch(getStationsItemsById(''));
    dispatch({
      type: 'GET_SELECTED_STATIONS_ID',
      payload: '',
    });
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`Station Item Delete Successfully`);
    }, 1000);
    dispatch(getRestaurant(`api/organizations?page=${currentPage}`));
  };
  const Columns = [
    {
      width: '2%',
      render: () => {
        return <img src={dragvertical} alt='props' />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'item',
      width: '10%',
      render: (Id, { id, item }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${item?.name} Items`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {item?.name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Description',
      dataIndex: 'item',
      width: '10%',
      render: (item) => {
        return (
          <Row>
            <Col>
              <Typography>{item?.description}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '3%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            {/* <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col> */}
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteStationsItems]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    // <Spin spinning={isStationsloading}>
    <Row gutter={[24, 24]}>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        {/* {isModalVisible ? ( */}
        <StationItemModalForm
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          editId={editId}
          setEditId={setEditId}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setStationsId={setStationsId}
          // setTabkey={setTabkey}
          // tabKey={tabKey}
        ></StationItemModalForm>
        {/* ) : ( */}
        <Row gutter={[24, 24]}>
          <Col xl={23} lg={23} md={23} sm={23} xs={23}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item
                onClick={() => handleCloseRestaurant()}
                style={{ cursor: 'pointer' }}
                className='breadcrumRestaurant'
              >
                Restaurants
              </Breadcrumb.Item>
              <Breadcrumb.Item className='breadcrumRestaurant'>
                {restaurant?.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className='breadcrumRestaurant'
                onClick={() => handleCloseCategory()}
                style={{ cursor: 'pointer' }}
              >
                Stations
              </Breadcrumb.Item>
              <Breadcrumb.Item className='breadcrumUsers'>
                {Stations.name}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={18}>
            <Typography className='heading'>Station Items</Typography>
          </Col>

          <Col xl={6} lg={6} md={24} sm={24} xs={24}>
            <Row justify='end' gutter={[8, 8]}>
              <Col>
                <Button
                  className='detailsButton'
                  onClick={(id) => setIsModalVisible(id)}
                  // icon={<img src={plus} alt="props" />}
                >
                  Create New
                </Button>
              </Col>
              <Col>
                <Button
                  className='filterButton'
                  // onClick={handleSortButtonClick}
                  // icon={<img src={filtericon} alt="props" />}
                >
                  Filter
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <StationsDetails
              setDrawerVisible={setDrawerVisible}
              drawerVisible={drawerVisible}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              setEditId={setEditId}
              editId={editId}
              stationsDetailId={stationsDetailId}
              currentPage={currentPage}
              tabKey={tabKey}
            ></StationsDetails>
            <CustomTable
              columns={Columns}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              data={sortOrder === 'asc' ? allStationsItems : sortedData}
            />
          </Col>

          {allStationsItems && length > 10 ? (
            <Col span={24}>
              <Row justify='center'>
                <Pagination
                  total={allStationsItems?.length}
                  onChange={(e) => setCurrentPage(e)}
                  responsive={true}
                  size='large'
                  current={currentPage}
                  showSizeChanger={false}
                />
              </Row>
            </Col>
          ) : (
            ''
          )}
        </Row>
        {/* )} */}
      </Col>
    </Row>
    // </Spin>
  );
};

export default StationsItem;
