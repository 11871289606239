import API from '../Utils/API';

export const getState = (url) => (dispatch) => {
  dispatch({ type: 'GET_STATE_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_STATE_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_STATE_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const getAllState = (url) => (dispatch) => {
  dispatch({ type: 'GET_STATES_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_STATES_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_STATES_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createState =
  (data, successCreateState, failureCreateState) => (dispatch) => {
    dispatch({ type: 'CREATE_STATE_REQUEST' });
    API.post('api/states', data)
      .then((response) => {
        dispatch({ type: 'CREATE_STATE_SUCCESS', payload: response.data });
        successCreateState && successCreateState();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_STATE_FAILURE',
          payload: error.response.data,
        });
        failureCreateState && failureCreateState(error.response.data);
      });
  };
export const deleteState = (StateId, successDeleteCallback) => (dispatch) => {
  dispatch({ type: 'DELETE_STATE_REQUEST' });
  API.delete(`api/states/${StateId}`)
    .then((response) => {
      dispatch({ type: 'DELETE_STATE_SUCCESS', payload: response.data });
      successDeleteCallback();
    })
    .catch((error) => {
      dispatch({
        type: 'DELETE_STATE_FAILURE',
        payload: error.response.data,
      });
    });
};

export const updateState =
  (data, successUpdateCallBack, failureUpdateState) => (dispatch) => {
    dispatch({ type: 'UPDATE_STATE_REQUEST' });
    API.put(`api/states/${data.id}`, data)
      .then((response) => {
        dispatch({ type: 'UPDATE_STATE_SUCCESS', payload: response.data });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_STATE_FAILURE',
          payload: error.response.data,
        });
        failureUpdateState && failureUpdateState(error.response.data);
      });
  };
export const getStateById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_STATE_BY_ID_REQUEST' });
  API.get(`api/states/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_STATE_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_STATE_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
