import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Radio,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { orderProcessSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getOrderProcess,
  createOrderProcess,
  updateOrderProcess,
} from '../../Actions/OrderProcessAction';
const OrderProcessModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, setCurrentPage, currentPage } =
    props;
  const dispatch = useDispatch();
  const [preloadedOrderProcessValue, setPreloadedOrderProcessValue] =
    useState('');
  const {
    allOrderProcess,
    isOrderProcessloading,
    isCreateOrderProcessoading,
    isUpdateOrderProcessLoading,
  } = useSelector((state) => {
    const {
      allOrderProcess,
      isOrderProcessloading,
      isCreateOrderProcessoading,
      isUpdateOrderProcessLoading,
    } = state.orderProcess;
    return {
      allOrderProcess,
      isOrderProcessloading,
      isCreateOrderProcessoading,
      isUpdateOrderProcessLoading,
    };
  });
  const the_orderprocess = _.filter(allOrderProcess, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedOrderProcessValue,
      is_sequence: false,
    },
    resolver: yupResolver(orderProcessSchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedOrderProcessValue(the_orderprocess[0]);
      reset(the_orderprocess[0]);
    }
  }, [the_orderprocess[0], reset]);
  const [checkedValueInventory, setCheckedValueInventory] = useState(
    the_orderprocess[0]?.is_sequence ? true : false
  );
  const onChangeInventory = (e) => {
    setCheckedValueInventory(e.target.value);
  };
  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('published', '');
    clearErrors();
  };
  const handleCancel = () => {
    setEditId('');
    setValue('name', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedOrderProcessValue);
    clearErrors();
  };
  const handleCreate = (data) => {
    data.published = false;
    data.is_sequence = checkedValueInventory;
    dispatch(createOrderProcess(data, successCreate, failureCreate));
  };
  const successCreate = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(
      getOrderProcess(`api/organization_order_processes?is_sequence=true`)
    );
    setValue('name', '');
    setValue('published', '');
    setValue('is_sequence', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your OrderProcess Created Successfully');
    }, 1000);
  };

  const failureCreate = (failureResponse) => {
    setError('name', { message: failureResponse.name[0] });
  };
  const handleEdit = (data) => {
    data.id = editId;
    data.is_sequence = checkedValueInventory;
    dispatch(updateOrderProcess(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('published', '');
    setValue('is_sequence', '');
    dispatch(
      getOrderProcess(`api/organization_order_processes?is_sequence=true`)
    );
    clearErrors();
    setTimeout(() => {
      message.success('Your OrderProcess Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
              className='breadcrumRestaurant'
            >
              Order Process
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update Order Process' : 'New Order Process'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Spin
        style={{ height: '100%', maxHeight: 'unset' }}
        spinning={
          editId ? isUpdateOrderProcessLoading : isCreateOrderProcessoading
        }
        size='large'
      >
        <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
          <Spin spinning={isOrderProcessloading}>
            <Row gutter={[16, 16]}></Row>

            <Row gutter={[16, 16]} className='button'>
              <Col span={24} className='formRows'>
                <Typography className='formHeading'>
                  Order Process Name
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='name'
                  control={control}
                  className='inputLabel'
                />
                {errors.name && (
                  <p
                    style={{
                      color: '#eb5757',
                      marginTop: '6px',
                      fontFamily: 'Circular-900',
                      wordSpacing: '-4px',
                    }}
                  >
                    {errors.name.message}
                  </p>
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <Row justify='start'>
                  <Col span={24}>
                    <Typography className='formHeading'>
                      Is Sequence?
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Radio.Group
                      onChange={onChangeInventory}
                      value={checkedValueInventory}
                    >
                      <Radio className='textRadio' value={true}>
                        Yes
                      </Radio>
                      <Radio className='textRadio' value={false}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[22, 22]} className='button'>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    className='detailsButton'
                    onClick={handleeditCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className='detailsButton'
                    onClick={handleCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Spin>
        </form>
      </Spin>
    </div>
  );
};

export default OrderProcessModalForm;
