import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Typography,
  message,
  Spin,
  Breadcrumb,
  Input,
  Tooltip,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { getUserThemes, updateUserTheme } from '../../Actions/UserThemesAction';
import { HexColorPicker } from 'react-colorful';
import { UserThemePreviewComponent } from '../../Utils/UserThemePreviewComponent';
import { userThemeSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';

const UserThemes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    restaurant,
    allUserThemes,
    isUserThemesloading,
    isUpdateUserThemesloading,
  } = useSelector((state) => {
    const { allUserThemes, isUserThemesloading, isUpdateUserThemesloading } =
      state.userThemes;
    const { restaurant, setRestaurantId } = state.restaurant;
    return {
      restaurant,
      allUserThemes,
      setRestaurantId,
      isUserThemesloading,
      isUpdateUserThemesloading,
    };
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    watch,
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: allUserThemes,
    resolver: yupResolver(userThemeSchema),
  });
  const watching = watch();

  useEffect(() => {
    dispatch(getUserThemes(`api/user_view_themes`, allUserThemeSuccess));
  }, []);

  const allUserThemeSuccess = (res) => {
    setValue(
      'primary_color',
      res?.primary_color ? res?.primary_color : '#4B4283'
    );
    setValue(
      'secondary_color',
      res?.secondary_color ? res?.secondary_color : '#ED723Df'
    );
  };

  const [visible, setVisible] = useState(false);
  const [isFieldChanged, setIsFieldChanged] = useState(false);
  const primaryColor = watch('primary_color');
  const secondaryColor = watch('secondary_color');

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const handleUpdateUserTheme = (data) => {
    data.id = allUserThemes?.id;
    dispatch(
      updateUserTheme(data, successUpdateCallback, failureUpdateCallback)
    );
  };

  const successUpdateCallback = () => {
    dispatch(getUserThemes(`api/user_view_themes`));
    setTimeout(() => {
      message.success(`Theme Updated Successfully`);
    }, 1000);
  };

  const failureUpdateCallback = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.error || failureResponse?.message
          : 'Something went wrong.'
      );
    }, 1000);
  };

  const onResetToDefault = (type) => {
    setIsFieldChanged(true);
    if (type === 'primary') {
      setValue('primary_color', '#4B4283');
    } else {
      setValue('secondary_color', '#ED723D');
    }
  };

  const handleCancel = () => {
    reset(allUserThemes);
  };

  const shwoLoading = isUserThemesloading || isUpdateUserThemesloading;

  return (
    <Spin spinning={shwoLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => navigate(-1)}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  User Themes
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={18}>
              <Typography className='heading'>User Themes</Typography>
            </Col>
          </Row>
        </Col>
      </Row>
      <Col span={24}>
        <Row>
          <Col span={13}>
            <form onSubmit={handleSubmit(handleUpdateUserTheme)}>
              <Row gutter={[16, 16]} className='button'>
                <Col span={14}>
                  <Row>
                    <Col span={18}>
                      <Typography className='formHeading'>
                        Primary Color
                      </Typography>
                    </Col>
                    <Col span={6} className='formHeading'>
                      {watching?.primary_color !== '#4B4283' && (
                        <Button
                          style={{
                            color: '#4B4283',
                            background: 'transparent',
                            border: 'none',
                          }}
                          onClick={() => onResetToDefault('primary')}
                        >
                          Default
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Controller
                    name='primary_color'
                    control={control}
                    render={() => (
                      <Input
                        size='large'
                        value={watching?.primary_color}
                        onFocus={() => setVisible(true)}
                        onBlur={() => setVisible(false)}
                        onChange={(color) => {
                          setValue('primary_color', color.target.value);
                          setIsFieldChanged(true);
                        }}
                        suffix={
                          <Tooltip
                            trigger='hover'
                            title={
                              <div onClick={(e) => e.stopPropagation()}>
                                <HexColorPicker
                                  color={watching?.primary_color}
                                  onChange={(color) => {
                                    setValue('primary_color', color);
                                    setIsFieldChanged(true);
                                  }}
                                />
                              </div>
                            }
                          >
                            <div
                              style={{
                                width: 50,
                                height: 24,
                                backgroundColor: watching?.primary_color,
                                border: '1px solid #d9d9d9',
                                cursor: 'pointer',
                              }}
                              onClick={() => setVisible(!visible)}
                            />
                          </Tooltip>
                        }
                      />
                    )}
                  />
                  {errors.primary_color && (
                    <p style={{ color: '#eb5757' }}>
                      {errors.primary_color.message}
                    </p>
                  )}
                </Col>
                <Col span={14}>
                  <Row>
                    <Col span={18}>
                      <Typography className='formHeading'>
                        Secondary Color
                      </Typography>
                    </Col>
                    <Col className='formHeading'>
                      {watching?.secondary_color !== '#ED723D' && (
                        <Button
                          style={{
                            color: '#ED723D',
                            background: 'transparent',
                            border: 'none',
                          }}
                          onClick={() => onResetToDefault('secondary')}
                        >
                          Default
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Controller
                    name='secondary_color'
                    control={control}
                    render={() => (
                      <Input
                        size='large'
                        value={watching?.secondary_color}
                        onFocus={() => setVisible(true)}
                        onBlur={() => setVisible(false)}
                        onChange={(color) => {
                          setValue('secondary_color', color.target.value);
                          setIsFieldChanged(true);
                        }}
                        suffix={
                          <Tooltip
                            trigger='hover'
                            title={
                              <div onClick={(e) => e.stopPropagation()}>
                                <HexColorPicker
                                  color={watching?.secondary_color}
                                  onChange={(color) => {
                                    setValue('secondary_color', color);
                                    setIsFieldChanged(true);
                                  }}
                                />
                              </div>
                            }
                          >
                            <div
                              style={{
                                width: 50,
                                height: 24,
                                backgroundColor: watching?.secondary_color,
                                border: '1px solid #d9d9d9',
                                cursor: 'pointer',
                              }}
                              onClick={() => setVisible(!visible)}
                            />
                          </Tooltip>
                        }
                      />
                    )}
                  />
                  {errors.secondary_color && (
                    <p style={{ color: '#eb5757' }}>
                      {errors.secondary_color.message}
                    </p>
                  )}
                </Col>
              </Row>
              <Col span={14}>
                <Row
                  gutter={[16, 16]}
                  className='button'
                  align='middle'
                  justify='center'
                >
                  <Col span={24}></Col>
                  <Col xl={11} lg={4} md={8} sm={12} xs={12}>
                    <Button
                      className={
                        isFieldChanged ? 'detailsButton' : 'classButton'
                      }
                      disabled={!isFieldChanged}
                      onClick={handleCancel}
                      size='large'
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xl={11} lg={4} md={8} sm={12} xs={12}>
                    <Button
                      size='large'
                      className={
                        isFieldChanged ? 'detailsButton' : 'classButton'
                      }
                      disabled={!isFieldChanged}
                      htmlType='submit'
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Col>
            </form>
          </Col>
          <Col span={8}>
            <UserThemePreviewComponent
              primaryColor={
                watching?.primary_color
                  ? watching?.primary_color
                  : allUserThemes?.primary_color
              }
              secondaryColor={
                watching?.secondary_color
                  ? watching?.secondary_color
                  : allUserThemes?.secondary_color
              }
            />
          </Col>
        </Row>
      </Col>
    </Spin>
  );
};

export default UserThemes;
