import constants from '../Utils/constants';
const initialState = {
  isStationsloading: false,
  allStations: [],
  isCreateStationsoading: false,
  isDeleteStationsLoading: false,
  isUpdateStationsLoading: false,
  setStationsId: '',
  Stations: {},
  isStationsByIdLoading: false,
  Error: '',
  succes:''
};

export const stationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_STATIONS_REQUEST:
      return {
        ...state,
        isStationsloading: true,
      };
    case constants.GET_STATIONS_SUCCESS:
      return {
        ...state,
        isStationsloading: false,
        allStations: action.payload,
      };
    case constants.GET_STATIONS_FAILURE:
      return {
        ...state,
        isStationsloading: false,
      };
    case constants.CREATE_STATIONS_REQUEST:
      return {
        ...state,
        isCreateStationsoading: true,
      };
    case constants.CREATE_STATIONS_SUCCESS:
      return {
        ...state,
        isCreateStationsoading: false,
        succes:action.payload,

      };
    case constants.CREATE_STATIONS_FAILURE:
      return {
        ...state,
        isCreateStationsoading: false,
        Error: action.payload?.name,
      };
    case constants.DELETE_STATIONS_REQUEST:
      return {
        ...state,
        isDeleteStationsLoading: true,
      };
    case constants.DELETE_STATIONS_SUCCESS:
      return {
        ...state,
        isDeleteStationsLoading: false,
      };
    case constants.DELETE_STATIONS_FAILURE:
      return {
        ...state,
        isDeleteStationsLoading: false,
      };
    case constants.UPDATE_STATIONS_REQUEST:
      return {
        ...state,
        isUpdateStationsLoading: true,
      };
    case constants.UPDATE_STATIONS_SUCCESS:
      return {
        ...state,
        isUpdateStationsLoading: false,
      };
    case constants.UPDATE_STATIONS_FAILURE:
      return {
        ...state,
        isUpdateStationsLoading: false,
      };
    case constants.GET_STATIONS_BY_ID_REQUEST:
      return {
        ...state,
        isStationsByIdLoading: true,
      };
    case constants.GET_STATIONS_BY_ID_SUCCESS:
      return {
        ...state,
        isStationsByIdLoading: false,
        Stations: action.payload,
      };
    case constants.GET_STATIONS_BY_ID_FAILURE:
      return {
        ...state,
        isStationsByIdLoading: false,
      };
    case constants.GET_SELECTED_STATIONS_ID:
      return {
        ...state,
        setStationsId: action.payload,
      };
    default:
      return state;
  }
};
