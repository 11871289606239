import API from '../Utils/API';

export const getOrganizationTable = (url) => (dispatch) => {
  dispatch({ type: 'GET_ORGANIZATION_TABLE_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({
        type: 'GET_ORGANIZATION_TABLE_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_ORGANIZATION_TABLE_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createOrganizationTable =
  (data, successCreateOrganizationTable, failureCreateOrganizationTable) =>
  (dispatch) => {
    dispatch({ type: 'CREATE_ORGANIZATION_TABLE_REQUEST' });
    API.post('api/organization_tables', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_ORGANIZATION_TABLE_SUCCESS',
          payload: response.data,
        });
        successCreateOrganizationTable && successCreateOrganizationTable();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_ORGANIZATION_TABLE_FAILURE',
          payload: error.response.data,
        });
        failureCreateOrganizationTable && failureCreateOrganizationTable();
      });
  };
export const deleteOrganizationTable =
  (OrganizationTableId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_ORGANIZATION_TABLE_REQUEST' });
    API.delete(`api/organization_tables/${OrganizationTableId}`)
      .then((response) => {
        dispatch({
          type: 'DELETE_ORGANIZATION_TABLE_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_ORGANIZATION_TABLE_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateOrganizationTable =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_ORGANIZATION_TABLE_REQUEST' });
    API.put(`api/organization_tables/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_ORGANIZATION_TABLE_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_ORGANIZATION_TABLE_FAILURE',
          payload: error.response.data,
        });
      });
  };
export const getOrganizationTableById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_ORGANIZATION_TABLE_BY_ID_REQUEST' });
  API.get(`api/organization_tables/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_ORGANIZATION_TABLE_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_ORGANIZATION_TABLE_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
