import { Button, Col, Divider, Row, Typography } from 'antd';
import '../Utils/UserThemePreview.css';
import { useSelector, useDispatch } from 'react-redux';
import { LiaRupeeSignSolid } from 'react-icons/lia';
import { IoChevronForward } from 'react-icons/io5';
import clipsBackground from '../assests/clipsBackground.jpg';
import logoPrest2 from '../assests/logoPrest2.png';
import imageIcon from '../assests/imageIcon.png';
import closeIcon from '../assests/closeIcon.svg';
import loadingBar from '../assests/loadingBar.jpg';
import {
  MdOutlineSignalCellular4Bar,
  MdOutlineSignalWifi4Bar,
} from 'react-icons/md';
import { IoMdBatteryFull } from 'react-icons/io';

export const UserThemePreviewComponent = (props) => {
  const dispatch = useDispatch();
  const { primaryColor, secondaryColor, tabKey } = props;

  const { restaurant } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    return { restaurant };
  });

  const CategoryData = ['All', 'Pizza', 'Hotdog', 'Panini'];

  const CategoryTypeData = [
    { icon: require('../assests/veg-type.png'), name: 'Veg' },
    { icon: require('../assests/nonveg-type.png'), name: 'Non-Veg' },
    { icon: require('../assests/egg-type.png'), name: 'Eggs' },
  ];

  const CategoryItems = [
    {
      icon: require('../assests/Pizza.jpg'),
      name: '7 Cheese Margheritta',
      description: 'This is description for 7 Cheese Margherita.',
      price: '100.00',
    },
    {
      icon: require('../assests/Pizza.jpg'),
      name: 'Macroni Pizza',
      description: 'This is description for Macroni Pizza.',
      price: '150.00',
    },
  ];

  return (
    <>
      {tabKey === '1' ? (
        <Col span={22}>
          <Row justify='center' align='middle'>
            <Col className='formHeading'>
              <Typography>Online Store Logo view</Typography>
            </Col>
          </Row>
          <Row style={{ backgroundColor: 'white', marginTop: '10px' }}>
            <Col span={24} style={{ backgroundColor: primaryColor }}>
              <div style={{ padding: 20 }}>
                <Row align='middle'>
                  {restaurant?.online_org_logo_url ? (
                    <img
                      src={restaurant?.online_org_logo_url}
                      width={30}
                      height={30}
                      alt='organizationlogo'
                    />
                  ) : (
                    <Col
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography className='appLogoByName'>
                        {restaurant?.name ? restaurant?.name?.[0] : 'P'}
                      </Typography>
                    </Col>
                  )}
                  <Typography className='userViewThemePreviewHeaderTitle'>
                    {restaurant?.name}
                  </Typography>
                </Row>

                <div style={{ marginTop: 12, marginBottom: 12 }}>
                  <Row>
                    {CategoryData?.map((category, index) => (
                      <Col key={index}>
                        <Row style={{ marginRight: 5, marginBottom: 5 }}>
                          <Col
                            className='useViewThemePreviewCategory'
                            style={{
                              background:
                                index === 0 ? secondaryColor : '#FFFDD0',
                            }}
                          >
                            <Typography
                              className={
                                index === 0
                                  ? 'userViewThemePreviewSelectedCategoryName'
                                  : ''
                              }
                            >
                              {category}
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </div>
                <Row className='userViewThemeCategoryTypeContainer'>
                  {CategoryTypeData?.map((categoryType, index) => (
                    <Col key={index}>
                      <Row gutter={[0, 4]}>
                        <Col span={24}>
                          <Row justify='start'>
                            <Col className='userViewThemeCategoryTypeImage'>
                              <img
                                src={categoryType?.icon}
                                alt='category_type_image'
                                width={20}
                                height={20}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row justify='start'>
                            <Typography className='userViewThemeCategoryTypeName'>
                              {categoryType?.name}
                            </Typography>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
            <Col span={24} style={{ padding: 20 }}>
              <Typography
                style={{ marginTop: 40 }}
                className='userViewThemePreviewRecommended'
              >
                Recommended Items
              </Typography>
              <Row gutter={[8, 8]} style={{ marginTop: 24 }}>
                {CategoryItems?.map((categoryItem, index) => (
                  <Col span={24} key={index}>
                    <Row gutter={[8, 8]}>
                      <Col span={6}>
                        <img
                          src={categoryItem?.icon}
                          height={55}
                          alt='pizza-image'
                        />
                      </Col>
                      <Col span={18}>
                        <Row>
                          <Col span={24}>
                            <Typography className='userViewThemePreviewCategoryName'>
                              {categoryItem?.name}
                            </Typography>
                          </Col>
                          <Col span={15}>
                            <Typography className='userViewThemePreviewCategoryDescription'>
                              {categoryItem?.description}
                            </Typography>
                          </Col>
                          <Col span={24}>
                            <Divider style={{ marginTop: '3px' }} />
                          </Col>
                          <Col
                            span={24}
                            className='userViewThemePreviewRecommended'
                          >
                            <LiaRupeeSignSolid
                              style={{
                                position: 'absolute',
                                left: '-2%',
                                top: '-300%',
                                fontSize: '16px',
                              }}
                            />
                            <Typography
                              style={{
                                marginTop: '-20px',
                                fontSize: '16px',
                                marginLeft: '10px',
                              }}
                            >
                              {categoryItem?.price}
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={24} style={{ padding: 20 }}>
              <Row
                justify='space-between'
                className='userViewThemeFooter'
                style={{ backgroundColor: primaryColor }}
              >
                <Col span={24} className='userViewThemePreviewRecommended'>
                  <Row gutter={[0, 4]} align='middle'>
                    <Col
                      className='userViewThemeFooterCountContainer'
                      style={{ backgroundColor: secondaryColor }}
                    >
                      <Typography style={{ color: 'white' }}>1</Typography>
                    </Col>
                    <Col span={12}>
                      <Typography style={{ color: 'white' }}>
                        ₹ 100.00
                      </Typography>
                    </Col>
                    <Col>
                      <Typography style={{ color: 'white' }}>
                        View Cart
                        <IoChevronForward
                          style={{
                            marginLeft: '2px',
                            position: 'absolute',
                            bottom: '4',
                            fontSize: '15px',
                          }}
                        />
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : tabKey === '2' ? (
        <Col span={22}>
          <Row justify='center' align='middle'>
            <Col className='formHeading'>
              <Typography>App clip header image</Typography>
            </Col>
          </Row>
          <Row
            style={{
              position: 'relative',
              marginTop: '10px',
            }}
          >
            <Col span={24}>
              <img
                src={clipsBackground}
                style={{ position: 'absolute' }}
                width='100%'
                height='100%'
              />
              <Row
                style={{
                  margin: '50px 5px 0px 5px',
                }}
              >
                <Col span={24} style={{ margin: '150px 0px 20px 0px' }}>
                  <Row style={{ position: 'relative', height: '150px' }}>
                    <Col span={24}>
                      {restaurant?.app_clip_logo_url ? (
                        <img
                          src={restaurant?.app_clip_logo_url}
                          className='appClipFooterBanner'
                        />
                      ) : (
                        <Col className='appClipFooterDefaultImage'>
                          <img src={imageIcon} width='40px' height='40px' />
                        </Col>
                      )}
                    </Col>
                    <img
                      src={closeIcon}
                      style={{ position: 'absolute', top: '7%', left: '91%' }}
                    />
                  </Row>
                  <Row className='appClipFooterContainer'>
                    <Col span={15}>
                      <Typography className='appClipFooterHeading'>
                        KFC
                      </Typography>
                      <Typography className='appClipFooterText'>
                        Ahmedabad, India
                      </Typography>
                    </Col>
                    <Col span={6}>
                      <Button className='appClipButton'>Order now</Button>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        marginTop: '20px',
                        borderTop: '1px solid #7b7b7b',
                      }}
                    >
                      <Row style={{ marginTop: '10px' }}>
                        <Col>
                          <img src={logoPrest2} height='35px' width='35px' />
                        </Col>
                        <Col span={12} style={{ marginLeft: '5px' }}>
                          <Typography className='appClipFootertext2'>
                            Powered by
                          </Typography>
                          <Typography className='appClipFooterName'>
                            PREST
                          </Typography>
                        </Col>
                        <Col span={8} style={{ marginLeft: '3px' }}>
                          <Typography className='appClipFootertext2'>
                            Visit Website{' '}
                            <IoChevronForward
                              style={{
                                position: 'absolute',
                                top: '5%',
                                right: '-5%',
                              }}
                            />
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : tabKey === '3' ? (
        <Col span={22}>
          <Row justify='center' align='middle'>
            <Col className='formHeading'>
              <Typography>Instant app logo Preview</Typography>
            </Col>
          </Row>
          <Row
            style={{
              position: 'relative',
              marginTop: '10px',
            }}
          >
            <Col span={24} style={{ background: 'white' }}>
              <Row>
                <Col span={24} className='instantAppHeader'>
                  <Row>
                    <Col span={20}>
                      <Typography className='instantAppHeader'>
                        12:30
                      </Typography>
                    </Col>
                    <Col style={{ marginTop: '6px' }}>
                      <MdOutlineSignalWifi4Bar />
                      <MdOutlineSignalCellular4Bar />
                      <IoMdBatteryFull />
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{ marginTop: '160px' }}>
                  <Row
                    justify='center'
                    align='middle'
                    style={{
                      position: 'relative',
                      height: '150px',
                    }}
                  >
                    <Col>
                      {restaurant?.instant_app_clip_logo_url ? (
                        <img
                          src={restaurant?.instant_app_clip_logo_url}
                          width='100px'
                          height='100px'
                        />
                      ) : (
                        <>
                          <Col className='appClipFooterDefaultImage'>
                            <img src={imageIcon} width='40px' height='40px' />
                          </Col>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Col style={{ textAlign: 'center' }}>
                    <img src={loadingBar} width='170px' />
                  </Col>
                  <Col className='instantAppFootertext'>
                    <Typography>
                      Google Play{' '}
                      <span style={{ color: '#219653' }}>Instant</span>
                    </Typography>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : tabKey === '4' ? (
        ''
      ) : (
        <Row style={{ backgroundColor: 'white' }}>
          <Col span={24} style={{ backgroundColor: primaryColor }}>
            <div style={{ padding: 20 }}>
              <Row align='middle'>
                {restaurant?.logo_url ? (
                  <img
                    src={restaurant?.logo_url}
                    width={30}
                    height={30}
                    alt='organizationlogo'
                  />
                ) : (
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography className='appLogoByName'>
                      {restaurant?.name ? restaurant?.name?.[0] : 'P'}
                    </Typography>
                  </Col>
                )}
                <Typography className='userViewThemePreviewHeaderTitle'>
                  {restaurant?.name}
                </Typography>
              </Row>

              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <Row>
                  {CategoryData?.map((category, index) => (
                    <Col key={index}>
                      <Row style={{ marginRight: 5, marginBottom: 5 }}>
                        <Col
                          className='useViewThemePreviewCategory'
                          style={{
                            background:
                              index === 0 ? secondaryColor : '#FFFDD0',
                          }}
                        >
                          <Typography
                            className={
                              index === 0
                                ? 'userViewThemePreviewSelectedCategoryName'
                                : ''
                            }
                          >
                            {category}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </div>
              <Row className='userViewThemeCategoryTypeContainer'>
                {CategoryTypeData?.map((categoryType, index) => (
                  <Col key={index}>
                    <Row gutter={[0, 4]}>
                      <Col span={24}>
                        <Row justify='start'>
                          <Col className='userViewThemeCategoryTypeImage'>
                            <img
                              src={categoryType?.icon}
                              alt='category_type_image'
                              width={20}
                              height={20}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row justify='start'>
                          <Typography className='userViewThemeCategoryTypeName'>
                            {categoryType?.name}
                          </Typography>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col span={24} style={{ padding: 20 }}>
            <Typography
              style={{ marginTop: 40 }}
              className='userViewThemePreviewRecommended'
            >
              Recommended Items
            </Typography>
            <Row gutter={[8, 8]} style={{ marginTop: 24 }}>
              {CategoryItems?.map((categoryItem, index) => (
                <Col span={24} key={index}>
                  <Row gutter={[8, 8]}>
                    <Col span={6}>
                      <img
                        src={categoryItem?.icon}
                        height={55}
                        alt='pizza-image'
                      />
                    </Col>
                    <Col span={18}>
                      <Row>
                        <Col span={24}>
                          <Typography className='userViewThemePreviewCategoryName'>
                            {categoryItem?.name}
                          </Typography>
                        </Col>
                        <Col span={15}>
                          <Typography className='userViewThemePreviewCategoryDescription'>
                            {categoryItem?.description}
                          </Typography>
                        </Col>
                        <Col span={24}>
                          <Divider style={{ marginTop: '3px' }} />
                        </Col>
                        <Col
                          span={24}
                          className='userViewThemePreviewRecommended'
                        >
                          <LiaRupeeSignSolid
                            style={{
                              position: 'absolute',
                              left: '-2%',
                              top: '-300%',
                              fontSize: '16px',
                            }}
                          />
                          <Typography
                            style={{
                              marginTop: '-20px',
                              fontSize: '16px',
                              marginLeft: '10px',
                            }}
                          >
                            {categoryItem?.price}
                          </Typography>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={24} style={{ padding: 20 }}>
            <Row
              justify='space-between'
              className='userViewThemeFooter'
              style={{ backgroundColor: primaryColor }}
            >
              <Col span={24} className='userViewThemePreviewRecommended'>
                <Row gutter={[0, 4]} align='middle'>
                  <Col
                    className='userViewThemeFooterCountContainer'
                    style={{ backgroundColor: secondaryColor }}
                  >
                    <Typography style={{ color: 'white' }}>1</Typography>
                  </Col>
                  <Col span={14}>
                    <Typography style={{ color: 'white' }}>
                      <LiaRupeeSignSolid
                        style={{
                          position: 'relative',
                          bottom: '-3',
                          fontSize: '16px',
                        }}
                      />
                      100.00
                    </Typography>
                  </Col>
                  <Col>
                    <Typography style={{ color: 'white' }}>
                      View Cart
                      <IoChevronForward
                        style={{
                          marginLeft: '2px',
                          position: 'relative',
                          bottom: '-3',
                          fontSize: '15px',
                        }}
                      />
                    </Typography>
                    {/* <Typography></Typography> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};
