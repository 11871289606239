import React, { useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Avatar,
  Button,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import Delete from '../../assests/Delete.svg';
import { FiEdit2 } from 'react-icons/fi';
import { ImageEnv } from '../CommonLogics/CommonMethods';
import { getQrCodes, deleteQrCodes } from '../../Actions/QrCodesAction';
import { useDispatch, useSelector } from 'react-redux';
const QrCodesDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    setIsModalVisible,
    qrCodeDetailId,
    tabKey,
  } = props;
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { QrCodes } = useSelector((state) => {
    const { QrCodes } = state.qrCodes;
    return {
      QrCodes,
    };
  });
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setDrawerVisible(false);
  };
  const handleDelete = () => {
    dispatch(deleteQrCodes(qrCodeDetailId, successDelete, failureDelete));
  };
  const successDelete = () => {
    setDrawerVisible(false);
    if (tabKey === '1') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Take Away`));
    } else if (tabKey === '2') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Dine In`));
    }
    setTimeout(() => {
      message.success(`QR Code Delete Successfully`);
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
            <Col span={24} style={{ marginTop: '25px' }}>
              <Row>
                <Col span={8}>
                  <Button
                    className='editButtonDrawer'
                    onClick={() => handleEdit(qrCodeDetailId, QrCodes)}
                  >
                    <FiEdit2 className='editIcon' />
                    Edit
                  </Button>
                </Col>
                <Col span={8}>
                  <Popconfirm
                    title={
                      <Typography className='popconfirmTitle'>
                        Do you really want to delete this Category?
                      </Typography>
                    }
                    icon={<Typography className='warning'>Warning!</Typography>}
                    onConfirm={() => handleDelete()}
                    okButtonProps={{ className: 'popconfirmButton' }}
                    cancelButtonProps={{ className: 'popconfirmButton' }}
                    okText='Confirm'
                    cancelText='Cancel'
                    placement='topLeft'
                  >
                    <Button className='deleteButtonDrawer'>
                      <img
                        src={Delete}
                        className='deleteIcon'
                        width='20px'
                        height='16px'
                      />
                      Delete
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {QrCodes && (
        <div>
          <Avatar
            shape='square'
            size={200}
            // className='image'
            src={QrCodes.qr_image_url}
          >
            <Typography style={{ fontSize: '20px', color: 'white' }}>
              {QrCodes.name?.[0]}
            </Typography>
          </Avatar>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>QR Code Type </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {QrCodes?.qr_type}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>QR Name </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {QrCodes?.name}
              </Typography>
            </Col>
          </Row>
          {tabKey === '2' && (
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>table_number </Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {QrCodes?.organization_table?.table_number}
                </Typography>
              </Col>
            </Row>
          )}
          {tabKey === '2' && (
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>placement </Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {QrCodes?.organization_table?.placement}
                </Typography>
              </Col>
            </Row>
          )}
          {tabKey === '2' && (
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>
                  seating_capacity{' '}
                </Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {QrCodes?.organization_table?.seating_capacity}
                </Typography>
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Description</Typography>
              <Typography className='detailsubheading'>
                {' '}
                {QrCodes?.description}
              </Typography>
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default QrCodesDetails;
