import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Utils/Main';
import { history } from './Utils/history';
import OtpAuthentication from './Screens/OtpAuthentication/OtpAuthentication';
import Slider from './Screens/Slider';
import Restaurant from './Screens/Restaurant/Restaurant';
import Country from './Screens/Countries/Country';
import Category from './Screens/Category/Category';
import CategoryItems from './Screens/Category Items/CategoryItems';
import OrderProcess from './Screens/OrderProcess/OrderProcess';
import QrCodes from './Screens/QrCodes/QrCodes';
import Stations from './Screens/Stations/Stations';
import PaymentMode from './Screens/PaymentMode/PaymentMode';
import StationsItem from './Screens/Stations/StationsItem';
import Tables from './Screens/Tables/Tables';
import CountryDetails from './Screens/Countries/CountryDetails';
import Printer from './Screens/Printer/Printer';
import Survey from './Screens/Survey/Survey';
import SurveyQuestion from './Screens/Survey/SurveyQuestion';
import ItemType from './Screens/ItemType/ItemType';
import CfdImages from './Screens/CfdImages/CfdImages';
import Employees from './Screens/Employees/Employees';
import Layout from './Screens/Layout/Layout';
import RestaurantDetails from './Screens/Restaurant/RestaurantDetails';
import RestarantModalForm from './Screens/Restaurant/RestaurantModalForm';
import UserThemes from './Screens/User Themes/UserThemes';
import Customers from './Screens/Customers/Customers';
import CategoryDetails from './Screens/Category/CategoryDetails';
import CategoryItemDetails from './Screens/Category Items/CategoryItemDetails';
import Customizations from './Screens/Customizations/Customizations';
import CustomizationDetails from './Screens/Customizations/CustomizationDetails';
import CustomizationItems from './Screens/Customization Items/CustomizationItems';
import Branding from './Screens/Branding/Branding';

function App() {
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    if (history.location.pathname === '/' && token) {
      history.push('/');
    } else {
    }
  }, [token]);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Routes>
            <Route
              path='/otp-verification'
              element={<OtpAuthentication />}
            ></Route>
            <Route path='/' element={<Main />}></Route>
            <Route
              path='/restaurants'
              element={<Slider element={<Restaurant />} itemKeyValue='1' />}
            ></Route>
            <Route
              path='/restaurants/:orgId'
              element={
                <Slider element={<RestaurantDetails />} itemKeyValue='2' />
              }
            ></Route>
            <Route
              path='/restaurants/:orgId/edit'
              element={
                <Slider element={<RestarantModalForm />} itemKeyValue='3' />
              }
            ></Route>
            <Route
              path='/country'
              element={<Slider element={<Country />} itemKeyValue='4' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/category'
              element={<Slider element={<Category />} itemKeyValue='5' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/category/:categoryId'
              element={
                <Slider element={<CategoryDetails />} itemKeyValue='6' />
              }
            ></Route>
            <Route
              path='/restaurants/:orgId/category/:categoryId/categoryItems'
              element={<Slider element={<CategoryItems />} itemKeyValue='7' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/category/:categoryId/categoryItems/:categoryItemId'
              element={
                <Slider element={<CategoryItemDetails />} itemKeyValue='8' />
              }
            ></Route>
            <Route
              path='/restaurants/:orgId/category/:categoryId/categoryItems/:categoryItemId/customizations'
              element={<Slider element={<Customizations />} itemKeyValue='9' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/category/:categoryId/categoryItems/:categoryItemId/customizations/:customizationId'
              element={
                <Slider element={<CustomizationDetails />} itemKeyValue='10' />
              }
            ></Route>
            <Route
              path='/restaurants/:orgId/category/:categoryId/categoryItems/:categoryItemId/customizations/:customizationId/customizationItems'
              element={
                <Slider element={<CustomizationItems />} itemKeyValue='11' />
              }
            ></Route>
            <Route
              path='/restaurants/:orgId/orderProcess'
              element={<Slider element={<OrderProcess />} itemKeyValue='12' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/qrCodes'
              element={<Slider element={<QrCodes />} itemKeyValue='13' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/stations'
              element={<Slider element={<Stations />} itemKeyValue='14' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/paymentMode'
              element={<Slider element={<PaymentMode />} itemKeyValue='15' />}
            ></Route>
            <Route
              path='/stationItems'
              element={<Slider element={<StationsItem />} itemKeyValue='16' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/tables'
              element={<Slider element={<Tables />} itemKeyValue='17' />}
            ></Route>
            <Route
              path='/countryDetails'
              element={
                <Slider element={<CountryDetails />} itemKeyValue='18' />
              }
            ></Route>
            <Route
              path='/printer'
              element={<Slider element={<Printer />} itemKeyValue='19' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/survey'
              element={<Slider element={<Survey />} itemKeyValue='20' />}
            ></Route>
            <Route
              path='/surveyQuestion'
              element={
                <Slider element={<SurveyQuestion />} itemKeyValue='21' />
              }
            ></Route>
            <Route
              path='/itemType'
              element={<Slider element={<ItemType />} itemKeyValue='22' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/cfdimages'
              element={<Slider element={<CfdImages />} itemKeyValue='23' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/employees'
              element={<Slider element={<Employees />} itemKeyValue='24' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/layout'
              element={<Slider element={<Layout />} itemKeyValue='25' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/customers'
              element={<Slider element={<Customers />} itemKeyValue='26' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/userthemes'
              element={<Slider element={<UserThemes />} itemKeyValue='27' />}
            ></Route>
            <Route
              path='/restaurants/:orgId/branding'
              element={<Slider element={<Branding />} itemKeyValue='28' />}
            ></Route>
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
