import React from 'react';
import { useMediaQuery } from 'react-responsive';
export const useBreakpoints = () => {
  const IsXS = useMediaQuery({
    maxWidth: 576,
  });
  const IsSM = useMediaQuery({
    minWidth: 576,
    maxWidth: 768,
  });
  const IsMD = useMediaQuery({
    minWidth: 768,
    maxWidth: 992,
  });
  const IsLG = useMediaQuery({
    minWidth: 992,
    maxWidth: 1200,
  });
  const IsXL = useMediaQuery({
    minWidth: 1200,
    maxWidth: 1600,
  });
  const IsXXL = useMediaQuery({
    minWidth: 1600,
  });
  return { IsXS, IsSM, IsMD, IsLG, IsXL, IsXXL };
};
