import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tabs,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { getTaxation, deleteTaxation } from '../../Actions/TaxationAction';
import StateTaxationModalForm from './StateTaxationModalForm';
import plus from '../../assests/plus.svg';

const StateTaxation = (props) => {
  const { setIsModalVisible, isModalVisible } = props;
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [tabKey, setTabkey] = useState('1');
  const { TabPane } = Tabs;

  const dispatch = useDispatch();
  const { isTaxationloading, allTaxation, setStateId, State, country } =
    useSelector((state) => {
      const { isTaxationloading, allTaxation } = state.taxation;
      const { setStateId, State } = state.states;
      const { country } = state.country;
      return {
        country,
        State,
        isTaxationloading,
        allTaxation,
        setStateId,
      };
    });

  const { length } = allTaxation || {};
  useEffect(() => {
    dispatch(getTaxation(`api/taxations?resource_id=${setStateId}`));
  }, [dispatch, currentPage]);

  const successDelete = () => {
    setTimeout(() => {
      message.success(`State Delete Successfully`);
    }, 1000);
    dispatch(getTaxation(`api/taxations?resource_id=${setStateId}`));
  };

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const onTabClick = (key) => {
    setTabkey(key);
  };
  const Columns = [
    {
      title: 'Taxation Name',
      dataIndex: 'taxation_name',
      width: '6%',
      render: (taxation_name) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {_.startCase(taxation_name)}
              </Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Resource Type',
      dataIndex: 'resource_type',
      width: '10%',
      render: (resource_type) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {resource_type}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Taxation Type',
      dataIndex: 'taxation_type',
      width: '10%',
      render: (taxation_type) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {taxation_type}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: ' Tax Rate ',
      dataIndex: 'display_tax_rate',
      width: '10%',
      render: (Id, { display_tax_rate }) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {display_tax_rate}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '4%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteTaxation]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={isTaxationloading}>
      <Row gutter={[24, 24]}>
        {isModalVisible ? (
          // <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <StateTaxationModalForm
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></StateTaxationModalForm>
        ) : (
          // </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Row justify='end' gutter={[8, 8]}>
              <Col>
                <Button
                  className='detailsButton'
                  onClick={() => setIsModalVisible(true)}
                  icon={<img src={plus} alt='props' />}
                >
                  Add State Taxation
                </Button>
              </Col>
            </Row>
            <CustomTable columns={Columns} data={allTaxation} />

            {allTaxation && length > 10 ? (
              <Col span={24}>
                <Row justify='center'>
                  <Pagination
                    total={allTaxation?.length}
                    onChange={(e) => setCurrentPage(e)}
                    responsive={true}
                    size='large'
                    current={currentPage}
                    showSizeChanger={false}
                  />
                </Row>
              </Col>
            ) : (
              ''
            )}
          </Col>
        )}
      </Row>
    </Spin>
  );
};

export default StateTaxation;
