import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Typography, Spin, Input, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { stationSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getStations,
  createStations,
  updateStations,
  getStationsById,
} from '../../Actions/StationsAction';
const StationsModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    setCurrentPage,
    currentPage,
    setDrawerVisible,
    setEditItemId,
  } = props;
  const dispatch = useDispatch();
  const [preloadedStationValue, setPreloadedStationValue] = useState('');
  const {
    isStationsloading,
    isCreateStationsoading,
    isUpdateStationsLoading,
    allStations,
    setStationsId,
  } = useSelector((state) => {
    const {
      allStations,
      isStationsloading,
      isCreateStationsoading,
      isUpdateStationsLoading,
      setStationsId,
    } = state.stations;
    return {
      isStationsloading,
      isCreateStationsoading,
      isUpdateStationsLoading,
      allStations,
      setStationsId,
    };
  });
  const the_station = _.filter(allStations, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedStationValue,
    },
    resolver: yupResolver(stationSchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedStationValue(the_station[0]);
      reset(the_station[0]);
    }
  }, [the_station[0], reset]);

  
  const handleCancel = () => {
    setEditId('');
    setValue('name', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedStationValue);
    clearErrors();
  };
  const handleCreateStaion = (data) => {
    dispatch(createStations(data, successCreate, failureCreate));
  };

  const successCreate = (res) => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    setDrawerVisible(true);
    setValue('name', '');
    setValue('is_main', '');
    setEditItemId(res.data.id);
    clearErrors();
    dispatch(getStations(`api/stations?page=${currentPage}`));
    setTimeout(() => {
      message.success(' Successfully created a Station.');
    }, 1000);
  };
  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name : 'Something went wrong.'
      );
    }, 1000);
  };
  const handleEditStation = (data) => {
    data.id = editId;
    dispatch(updateStations(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = (res) => {
    reset();
    setCurrentPage(1);
    setEditId("");
    setEditItemId("");
    setIsModalVisible(false);
    setDrawerVisible(false);
    setValue('name', '');
    setValue('is_main', '');
    dispatch(getStations(`api/stations?page=${currentPage}`));
    dispatch(getStationsById(res?.data?.id));
    clearErrors();
    setTimeout(() => {
      message.success('Successfully updated a Station.');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);
  const [width] = useState(window.innerWidth);
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px rgba(246, 246, 246, 1) inset',
  };
  return (
    <div>
      <form onSubmit={handleSubmit(editId ? handleEditStation : handleCreateStaion)}>
        <Spin
          style={{ height: '100%', maxHeight: 'unset' }}
          spinning={editId ? isUpdateStationsLoading : isCreateStationsoading}
        >
          <Row gutter={[16, 16]}></Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Station Name</Typography>
              <Controller
                as={<Input style={inputStyles} />}
                name='name'
                control={control}
                className='inputLabel2'
              />
              {errors.name && (
                <p style={{ color: 'red' }}>{errors.name.message}</p>
              )}
            </Col>
          </Row>


          <Row gutter={[22, 22]} className='button' justify='center'>
            <Col span={24}></Col>
            <Col xl={10} lg={10} md={10} sm={12} xs={12}>
              {editId ? (
                <Button className='detailsButton' onClick={handleeditCancel}>
                  Cancel
                </Button>
              ) : (
                <Button className='detailsButton' onClick={handleCancel}>
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={10} lg={10} md={10} sm={12} xs={12}>
              {editId ? (
                <Button className='editDesignationButton' htmlType='submit'>
                  Update
                </Button>
              ) : (
                <Button className='editDesignationButton' htmlType='submit'>
                  Create
                </Button>
              )}
            </Col>
         
          </Row>
        </Spin>
      </form>


    </div>
  );
};

export default StationsModalForm;
