import constants from '../Utils/constants';
const initialState = {
  isTaxationloading: false,
  allTaxation: [],
  isCreateTaxationoading: false,
  isDeleteTaxationLoading: false,
  isUpdateTaxationLoading: false,
  setTaxationId: '',
  Taxation: {},
  isTaxationByIdLoading: false,
  error: '',
};

export const taxationReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_TAXATION_REQUEST:
      return {
        ...state,
        isTaxationloading: true,
      };
    case constants.GET_TAXATION_SUCCESS:
      return {
        ...state,
        isTaxationloading: false,
        allTaxation: action.payload,
      };
    case constants.GET_TAXATION_FAILURE:
      return {
        ...state,
        isTaxationloading: false,
      };
    case constants.CREATE_TAXATION_REQUEST:
      return {
        ...state,
        isCreateTaxationoading: true,
      };
    case constants.CREATE_TAXATION_SUCCESS:
      return {
        ...state,
        isCreateTaxationoading: false,
      };
    case constants.CREATE_TAXATION_FAILURE:
      return {
        ...state,
        isCreateTaxationoading: false,
        error: action.payload?.taxation_name,
      };
    case constants.DELETE_TAXATION_REQUEST:
      return {
        ...state,
        isDeleteTaxationLoading: true,
      };
    case constants.DELETE_TAXATION_SUCCESS:
      return {
        ...state,
        isDeleteTaxationLoading: false,
      };
    case constants.DELETE_TAXATION_FAILURE:
      return {
        ...state,
        isDeleteTaxationLoading: false,
      };
    case constants.UPDATE_TAXATION_REQUEST:
      return {
        ...state,
        isUpdateTaxationLoading: true,
      };
    case constants.UPDATE_TAXATION_SUCCESS:
      return {
        ...state,
        isUpdateTaxationLoading: false,
      };
    case constants.UPDATE_TAXATION_FAILURE:
      return {
        ...state,
        isUpdateTaxationLoading: false,
        error: action.payload?.taxation_name,
      };
    case constants.GET_TAXATION_BY_ID_REQUEST:
      return {
        ...state,
        isTaxationByIdLoading: true,
      };
    case constants.GET_TAXATION_BY_ID_SUCCESS:
      return {
        ...state,
        isTaxationByIdLoading: false,
        Taxation: action.payload,
      };
    case constants.GET_TAXATION_BY_ID_FAILURE:
      return {
        ...state,
        isTaxationByIdLoading: false,
      };
    case constants.GET_SELECTED_TAXATION_ID:
      return {
        ...state,
        setTaxationId: action.payload,
      };
    default:
      return state;
  }
};
