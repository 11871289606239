import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Drawer,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import Back from '../../assests/Back.svg';
import {
  getStations,
  deleteStations,
  getStationsById,
} from '../../Actions/StationsAction';
import { useNavigate } from 'react-router-dom';
import plus from '../../assests/plus.svg';
// import filtericon from '../../assests/filtericon.svg';
import dragvertical from '../../assests/dragvertical.svg';
import StationsModalForm from './StationsModalForm';
import StationItemModalForm from './StationItemModalForm';
const Stations = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [editItemId, setEditItemId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tabKey, setTabkey] = useState('1');

  const [stationsDetailId, setstationsDetailId] = useState('');
  const { isStationsloading, restaurant, allStations } = useSelector(
    (state) => {
      const { restaurant } = state.restaurant;
      const { allStations, isStationsloading } = state.stations;
      return {
        restaurant,
        isStationsloading,
        allStations,
      };
    }
  );

  const { length } = allStations || {};
  useEffect(() => {
    dispatch(getStations(`api/stations?page=${currentPage}`));
  }, [dispatch, currentPage]);
  const handleSortButtonClick = () => {
    const sortedResults = [...allStations].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (sortOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setSortedData(sortedResults);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`Successfully deleted a station.`);
    }, 1000);
    dispatch(getStations(`api/stations?page=${currentPage}`));
  };

  const handleEdit = (id) => {
    setstationsDetailId(id);
    setEditId(id);
    setEditItemId(id);
    setDrawerVisible(true);
    setIsModalVisible(true);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getStationsById(''));
    dispatch({
      type: 'GET_SELECTED_STATIONS_ID',
      payload: '',
    });
  };

  const Columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} details`}>
                <Typography style={{ cursor: 'pointer' }}>{name}</Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Total Item',
      dataIndex: 'item_count',
      width: '10%',
      render: (item_count) => {
        return (
          <Row>
            <Col>{item_count}</Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '4%',
      render: (id, { is_main }) => {
        return (
          <Row justify='space-around'>
            {is_main === false ? (
              <Row>
                <Col>
                  <EditColumnAction id={id} customMethod={handleEdit} />
                </Col>
                <Col>
                  <DeleteColumnAction
                    id={id}
                    actionArray={[deleteStations]}
                    customMethod={successDelete}
                  />
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Row>
        );
      },
    },
  ];
  const handleClose = () => {
    setIsModalVisible(false);
    setDrawerVisible(false);
    setEditId('');
    setEditItemId('');
  };
  const [width] = useState(window.innerWidth);
  return (
    <Spin spinning={isStationsloading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Drawer
            closeIcon={<img src={Back} alt='props' />}
            open={isModalVisible || drawerVisible}
            onClose={() =>
              handleClose(isModalVisible ? 'isModalVisible' : 'drawerVisible')
            }
            maskClosable={false}
            title={
              editId
                ? 'Update Station'
                : isModalVisible
                ? 'Add Station'
                : 'Add Stations Item'
            }
            width={width > 370 ? '370px' : '100%'}
            className='drawer'
          >
            {(isModalVisible || editId) && (
              <StationsModalForm
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                editId={editId}
                setEditId={setEditId}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setTabkey={setTabkey}
                editItemId={editItemId}
                setEditItemId={setEditItemId}
                tabKey={tabKey}
                setDrawerVisible={setDrawerVisible}
              />
            )}
            {(drawerVisible || editItemId) && (
              <StationItemModalForm
                setDrawerVisible={setDrawerVisible}
                drawerVisible={drawerVisible}
                setIsModalVisible={setIsModalVisible}
                editId={editId}
                setEditId={setEditId}
                editItemId={editItemId}
                setEditItemId={setEditItemId}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                stationsDetailId={stationsDetailId}
              />
            )}
          </Drawer>

          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  // style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={() => navigate(-1)}
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  Stations
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={18}>
              <Typography className='heading'>Stations</Typography>
            </Col>

            <Col xl={6} lg={6} md={24} sm={24} xs={24}>
              <Row justify='end' gutter={[8, 8]}>
                <Col>
                  <Button
                    className='detailsButton'
                    onClick={(id) => setIsModalVisible(id)}
                    icon={<img src={plus} alt='props' />}
                  >
                    Create New
                  </Button>
                </Col>
                {/* <Col>
                  <Button
                    className='filterButton'
                    onClick={handleSortButtonClick}
                    icon={<img src={filtericon} alt='props' />}
                  >
                    Filter
                  </Button>
                </Col> */}
              </Row>
            </Col>
            <Col>
              <CustomTable
                columns={Columns}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                data={sortOrder === 'asc' ? allStations : sortedData}
              />
            </Col>

            {allStations && length > 0 ? (
              <Col span={24}>
                <Row justify='center'>
                  <Pagination
                    total={allStations?.length}
                    onChange={(e) => setCurrentPage(e)}
                    responsive={true}
                    size='large'
                    current={currentPage}
                    showSizeChanger={false}
                  />
                </Row>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default Stations;
