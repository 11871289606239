import constants from '../Utils/constants';
const initialState = {
  isImageLoading: false,
  image: {},
};

export const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.DELETE_IMAGE_REQUEST:
      return {
        ...state,
        isImageLoading: true,
      };
    case constants.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        isImageLoading: false,
      };
    case constants.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        isImageLoading: false,
      };

    default:
      return state;
  }
};
