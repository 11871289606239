import constants from '../Utils/constants';
const initialState = {
  isTimezoneloading: false,
  allTimeZone: [],
  setTimezoneId: '',
  TimeZone: {},
  isTimezoneByIdLoading: false,
};

export const timeZoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_TIMEZONE_REQUEST:
      return {
        ...state,
        isTimezoneloading: true,
      };
    case constants.GET_TIMEZONE_SUCCESS:
      return {
        ...state,
        isTimezoneloading: false,
        allTimeZone: action.payload,
      };
    case constants.GET_TIMEZONE_FAILURE:
      return {
        ...state,
        isTimezoneloading: false,
      };
    case constants.GET_TIMEZONE_BY_ID_REQUEST:
      return {
        ...state,
        isTimezoneByIdLoading: true,
      };
    case constants.GET_TIMEZONE_BY_ID_SUCCESS:
      return {
        ...state,
        isTimezoneByIdLoading: false,
        TimeZone: action.payload,
      };
    case constants.GET_TIMEZONE_BY_ID_FAILURE:
      return {
        ...state,
        isTimezoneByIdLoading: false,
      };
    case constants.GET_SELECTED_TIMEZONE_ID:
      return {
        ...state,
        setTimezoneId: action.payload,
      };
    default:
      return state;
  }
};
