import React from 'react';
import { Row, Col, Spin } from 'antd';
import '../App.css';
import { useBreakpoints } from '../Screens/CommonLogics/ResponsiveBreakPoints';
import Login from '../Screens/Login';
import { useSelector } from 'react-redux';

function Main() {
  const { IsXL, IsLG, IsMD, IsXS, IsXXL } = useBreakpoints();
  const { isLoginUserLoading, isCreateUserLoading } = useSelector((state) => {
    const { isLoginUserLoading, isCreateUserLoading } = state.authentication;
    return {
      isLoginUserLoading,
      isCreateUserLoading,
    };
  });

  const height = window.innerHeight;
  return (
    <Row
      style={{
        overflow: 'hidden',
        height: '100vh',
        background: '#F6F6F6',
      }}
      align='middle'
    >
      <Col
        // className="MainLoadingIndicator"
        span={24}
      >
        {/* <Spin spinning={isLoginUserLoading || isCreateUserLoading} size="large"> */}
        <Row align='middle'>
          <Login />
        </Row>
        {/* </Spin> */}
      </Col>
    </Row>
  );
}
export default Main;
