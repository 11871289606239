import API from '../Utils/API';

export const deleteImage = (imageKey, successDeleteCallback) => (dispatch) => {
  dispatch({ type: 'DELETE_IMAGE_REQUEST' });
  API.delete(`/api/delete_image_with_key/${imageKey}`)
    .then((response) => {
      dispatch({ type: 'DELETE_IMAGE_SUCCESS', payload: response?.data });
      successDeleteCallback();
    })
    .catch((error) => {
      dispatch({
        type: 'DELETE_IMAGE_FAILURE',
        payload: error?.response?.data,
      });
    });
};
