import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { isEmpty, isObject, toNumber } from 'lodash';
import { useEffect, useState } from 'react';
export const phoneFormatterAccordingToCountryCode = (
  countryFormat,
  phoneNumberArray
) => {
  var pattern = /[.](?!\d*$.)/g;
  var replacement = ' ';
  return countryFormat.replace(pattern, function (match) {
    replacement = phoneNumberArray.shift();
    if (typeof replacement == 'number' || typeof replacement == 'string') {
      return replacement;
    }
    return '';
  });
};
const text = '(...) ... ....';
const blankData = ['7', '8', '1', '9', '9', '1', '2', '5', '1', '9'];
// output: And also an array like this

// export const customToken = () => {
//   if (typeof window !== "undefined") {
//     return localStorage?.getItem("access_token")
//       ? localStorage?.getItem("access_token")
//       : null;
//     // : getCookie("access_token");
//   }
// };

export const getepochTime = () => {
  const currentDate = new Date();
  const epochTime = Math.floor(currentDate.getTime() / 1000);
  return epochTime;
};

export const CheckTokenExpired = () => {
  const token = localStorage.getItem('access_token');
  const decodedToken = token ? jwtDecode(token) : null;
  const newDate = new Date(decodedToken.exp * 1000);
  const formattedNewDate = moment(newDate).format('YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');
  const differenceDate = moment(currentDate).diff(formattedNewDate, 'days');
  const isTokenExpired = differenceDate > 0;

  return isTokenExpired;
};

export const CountryViseFormat = (CountryNumber) => {
  const phoneNumber = parsePhoneNumber(CountryNumber);
  if (isValidPhoneNumber(CountryNumber)) {
    return phoneNumber.formatInternational();
  } else if (phoneNumber.isValid()) {
    return phoneNumber.formatInternational();
  } else {
    return CountryNumber;
  }
};
// export const falseyValueCases = (data) => {
//   if (isObject(data)) {
//     // Fall-through switch cases. -> case return true, depending on case value (boolean).
//     switch (true) {
//       case isEmpty(data):
//         return true;
//       default:
//         return false;
//     }
//   } else {
//     switch (data) {
//       case null:
//         return true;
//       case typeof data === "function":
//         return true;
//       case "null":
//         return true;
//       case "":
//         return true;
//       case undefined:
//         return true;
//       case "undefined":
//         return true;
//       case {}:
//         return true;
//       case []:
//         return true;
//       case NaN:
//         return true;
//       case 0:
//         return true;
//       case "0":
//         return true;
//       case "NaN":
//         return true;
//       case false:
//         return false;
//       case true:
//         return true;
//       default:
//         return false;
//     }
//   }
// };
export const falseyValueCases = (data) => {
  switch (data) {
    case null:
      return true;
    case 'null':
      return true;
    case '':
      return true;
    case undefined:
      return true;
    case 'undefined':
      return true;
    case NaN:
      return true;
    case 'NaN':
      return true;
    default:
      return false;
  }
};

export const ImageEnv = (image) => {
  const image_url =
    process.env.REACT_APP_ENV === 'production'
      ? `${process.env.REACT_APP_PROD_IMAGE_URL}/${image}`
      : `${process.env.REACT_APP_DEV_IMAGE_URL}/${image}`;
  return image_url;
};

export const getTablePositions = (data, type) => {
  return data?.map((table) => {
    return toNumber(type === 'y' ? table?.y_position : table?.x_position);
  });
};

export const useDimensions = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const DimensionHandler = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    window.addEventListener('resize', DimensionHandler);
    return () => window.removeEventListener('resize', DimensionHandler);
  }, [width, height]);
  return { width, height };
};
