import constants from '../Utils/constants';
const initialState = {
  isCustomizationItemloading: false,
  allCustomizationItem: [],
  isCreateCustomizationItemloading: false,
  isDeleteCustomizationItemLoading: false,
  isUpdateCustomizationItemLoading: false,
  setCustomizationItemId: '',
  CustomizationItem: {},
  isCustomizationItemByIdLoading: false,
  isAvailabilityCustomizationItemLoading: false,
  isReorderCustomizationItemLoading: false,
  isDuplicateCustomizationItemLoading: false,
};

export const customizationItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CUSTOMIZATION_ITEM_REQUEST:
      return {
        ...state,
        isCustomizationItemloading: true,
      };
    case constants.GET_CUSTOMIZATION_ITEM_SUCCESS:
      return {
        ...state,
        isCustomizationItemloading: false,
        allCustomizationItem: action.payload,
      };
    case constants.GET_CUSTOMIZATION_ITEM_FAILURE:
      return {
        ...state,
        isCustomizationItemloading: false,
      };
    case constants.CREATE_CUSTOMIZATION_ITEM_REQUEST:
      return {
        ...state,
        isCreateCustomizationItemloading: true,
      };
    case constants.CREATE_CUSTOMIZATION_ITEM_SUCCESS:
      return {
        ...state,
        isCreateCustomizationItemloading: false,
      };
    case constants.CREATE_CUSTOMIZATION_ITEM_FAILURE:
      return {
        ...state,
        isCreateCustomizationItemloading: false,
      };
    case constants.DELETE_CUSTOMIZATION_ITEM_REQUEST:
      return {
        ...state,
        isDeleteCustomizationItemLoading: true,
      };
    case constants.DELETE_CUSTOMIZATION_ITEM_SUCCESS:
      return {
        ...state,
        isDeleteCustomizationItemLoading: false,
      };
    case constants.DELETE_CUSTOMIZATION_ITEM_FAILURE:
      return {
        ...state,
        isDeleteCustomizationItemLoading: false,
      };
    case constants.UPDATE_CUSTOMIZATION_ITEM_REQUEST:
      return {
        ...state,
        isUpdateCustomizationItemLoading: true,
      };
    case constants.UPDATE_CUSTOMIZATION_ITEM_SUCCESS:
      return {
        ...state,
        isUpdateCustomizationItemLoading: false,
      };
    case constants.UPDATE_CUSTOMIZATION_ITEM_FAILURE:
      return {
        ...state,
        isUpdateCustomizationItemLoading: false,
      };
    case constants.GET_CUSTOMIZATION_ITEM_BY_ID_REQUEST:
      return {
        ...state,
        isCustomizationItemByIdLoading: true,
      };
    case constants.GET_CUSTOMIZATION_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        isCustomizationItemByIdLoading: false,
        CustomizationItem: action.payload,
      };
    case constants.GET_CUSTOMIZATION_ITEM_BY_ID_FAILURE:
      return {
        ...state,
        isCustomizationItemByIdLoading: false,
      };
    case constants.AVAILABILITY_CUSTOMIZATION_ITEM_REQUEST:
      return {
        ...state,
        isAvailabilityCustomizationItemLoading: true,
      };
    case constants.AVAILABILITY_CUSTOMIZATION_ITEM_SUCCESS:
      const actionPayloadData = action.payload;
      const existingData = state.allCustomizationItem;
      const newData = existingData?.map((customizationItem) => {
        if (customizationItem?.id === actionPayloadData?.id) {
          return actionPayloadData;
        } else {
          return customizationItem;
        }
      });
      return {
        ...state,
        isAvailabilityCustomizationItemLoading: false,
        allCustomizationItem: newData,
      };
    case constants.AVAILABILITY_CUSTOMIZATION_ITEM_FAILURE:
      return {
        ...state,
        isAvailabilityCustomizationItemLoading: false,
      };
    case constants.REORDER_CUSTOMIZATION_ITEM_REQUEST:
      return {
        ...state,
        isReorderCustomizationItemLoading: true,
      };
    case constants.REORDER_CUSTOMIZATION_ITEM_SUCCESS:
      return {
        ...state,
        isReorderCustomizationItemLoading: false,
      };
    case constants.REORDER_CUSTOMIZATION_ITEM_FAILURE:
      return {
        ...state,
        isReorderCustomizationItemLoading: false,
      };
    case constants.DUPLICATE_CUSTOMIZATION_ITEM_REQUEST:
      return {
        ...state,
        isDuplicateCustomizationItemLoading: true,
      };
    case constants.DUPLICATE_CUSTOMIZATION_ITEM_SUCCESS:
      return {
        ...state,
        isDuplicateCustomizationItemLoading: false,
      };
    case constants.DUPLICATE_CUSTOMIZATION_ITEM_FAILURE:
      return {
        ...state,
        isDuplicateCustomizationItemLoading: false,
      };
    default:
      return state;
  }
};
