import API from '../Utils/API';

export const getTimeZone = (url) => (dispatch) => {
  dispatch({ type: ' GET_TIMEZONE_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_TIMEZONE_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_TIMEZONE_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const getTimeZoneById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_TIMEZONE_BY_ID_REQUEST' });
  API.get(`api/time_zones/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_TIMEZONE_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_TIMEZONE_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
