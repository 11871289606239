import constants from '../Utils/constants';
const initialState = {
  isPaymentModeloading: false,
  allPaymentMode: [],
  isCreatePaymentModeoading: false,
  isDeletePaymentModeLoading: false,
  isUpdatePaymentModeLoading: false,
  setPaymentModeId: '',
  PaymentMode: {},
  isPaymentModeByIdLoading: false,
  Error: '',
};

export const paymentModeReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PAYMENT_MODE_REQUEST:
      return {
        ...state,
        isPaymentModeloading: true,
      };
    case constants.GET_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        isPaymentModeloading: false,
        allPaymentMode: action.payload,
      };
    case constants.GET_PAYMENT_MODE_FAILURE:
      return {
        ...state,
        isPaymentModeloading: false,
      };
    case constants.CREATE_PAYMENT_MODE_REQUEST:
      return {
        ...state,
        isCreatePaymentModeoading: true,
      };
    case constants.CREATE_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        isCreatePaymentModeoading: false,
      };
    case constants.CREATE_PAYMENT_MODE_FAILURE:
      return {
        ...state,
        isCreatePaymentModeoading: false,
        // Error: action.payload?.name,
      };
    case constants.DELETE_PAYMENT_MODE_REQUEST:
      return {
        ...state,
        isDeletePaymentModeLoading: true,
      };
    case constants.DELETE_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        isDeletePaymentModeLoading: false,
      };
    case constants.DELETE_PAYMENT_MODE_FAILURE:
      return {
        ...state,
        isDeletePaymentModeLoading: false,
      };
    case constants.UPDATE_PAYMENT_MODE_REQUEST:
      return {
        ...state,
        isUpdatePaymentModeLoading: true,
      };
    case constants.UPDATE_PAYMENT_MODE_SUCCESS:
      return {
        ...state,
        isUpdatePaymentModeLoading: false,
      };
    case constants.UPDATE_PAYMENT_MODE_FAILURE:
      return {
        ...state,
        isUpdatePaymentModeLoading: false,
      };
    case constants.GET_PAYMENT_MODE_BY_ID_REQUEST:
      return {
        ...state,
        isPaymentModeByIdLoading: true,
      };
    case constants.GET_PAYMENT_MODE_BY_ID_SUCCESS:
      return {
        ...state,
        isPaymentModeByIdLoading: false,
        PaymentMode: action.payload,
      };
    case constants.GET_PAYMENT_MODE_BY_ID_FAILURE:
      return {
        ...state,
        isPaymentModeByIdLoading: false,
      };
    case constants.GET_SELECTED_PAYMENT_MODE_ID:
      return {
        ...state,
        setPaymentModeId: action.payload,
      };
    default:
      return state;
  }
};
