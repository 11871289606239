import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import {
  createTaxation,
  getTaxation,
  updateTaxation,
} from '../../Actions/TaxationAction';
import { taxationSchema } from '../../Utils/Schema';

const CountryTaxationModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsCountryTaxationModalVisible,
    setCurrentPage,
  } = props;
  const dispatch = useDispatch();
  const [preloadedCountryValue, setPreloadedCountryValue] = useState('');
  const { isTaxationloading, country, setCountryId, allTaxation } = useSelector(
    (state) => {
      const { country, allCountry, setCountryId } = state.country;
      const { isTaxationloading, allTaxation } = state.taxation;
      return {
        allTaxation,
        country,
        allCountry,
        isTaxationloading,
        setCountryId,
      };
    }
  );
  const the_country = _.filter(allTaxation, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedCountryValue,
    },
    resolver: yupResolver(taxationSchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedCountryValue(the_country[0]);
      reset(the_country[0]);
    }
  }, [the_country[0], reset]);

  const handleClose = () => {
    reset(preloadedCountryValue);
    setEditId('');
    setValue('taxation_name', '');
    setValue('tax_rate', '');
    setValue('taxation_type', '');
    setValue('resource_type', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedCountryValue);
    clearErrors();
  };
  const cardArray = [{ label: 'Country', value: 'Country' }];
  const handleCreate = (data) => {
    data.resource_id = country?.id;
    data.tax_rate = _.toNumber(data.tax_rate);
    dispatch(createTaxation(data, successCreate, failureCreate));
  };
  const successCreate = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsCountryTaxationModalVisible(false);
    dispatch(getTaxation(`api/taxations?resource_id=${setCountryId}`));
    setValue('taxation_name', '');
    setValue('tax_rate', '');
    setValue('taxation_type', '');
    setValue('resource_type', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Country Taxation Created Successfully');
    }, 1000);
  };

  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.taxation_name
          : 'Something went wrong.'
      );
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    data.tax_rate = _.toNumber(data?.tax_rate);
    dispatch(updateTaxation(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsCountryTaxationModalVisible(false);
    setEditId('');
    setValue('taxation_name', '');
    setValue('tax_rate', '');
    setValue('taxation_type', '');
    setValue('resource_type', '');
    dispatch(getTaxation(`api/taxations?resource_id=${setCountryId}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Country Taxation Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);
  const [width] = useState(window.innerWidth);
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #e5e5e5 inset',
  };
  return (
    <div style={{ width: 1200 }}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update Country Taxation ' : 'Add New Country Taxation'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
        <Spin spinning={isTaxationloading}>
          <Row gutter={[16, 16]}></Row>
          {/* <Row gutter={[16, 16]} className="button">
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className="formRows">
              <Typography className="details" >
                Country
              </Typography>
              <Input
                 style={inputStyles}
                className="inputLabel"
                value={country?.name}
              />
            </Col>
          </Row> */}
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='details'>Taxation Name</Typography>
              <Controller
                as={<Input className='input' style={inputStyles} />}
                name='taxation_name'
                control={control}
                className='inputLabel'
              />
              {errors.taxation_name && (
                <p style={{ color: 'red' }}>{errors.taxation_name.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='details'>Tax Rate</Typography>
              <Controller
                as={<Input className='input' style={inputStyles} />}
                name='tax_rate'
                control={control}
                className='inputLabel'
              />
              {errors.tax_rate && (
                <p style={{ color: 'red' }}>{errors.tax_rate.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='details'>Taxation Type</Typography>
              <Controller
                as={<Input className='input' style={inputStyles} />}
                name='taxation_type'
                control={control}
                className='inputLabel'
              />
              {errors.taxation_type && (
                <p style={{ color: 'red' }}>{errors.taxation_type.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='details'>Resource Type</Typography>
              <Controller
                as={
                  <Select
                    className='select'
                    style={{ inputStyles, width: '100%' }}
                    options={cardArray}
                  />
                }
                name='resource_type'
                control={control}
                // className='inputLabel'
              />
              {errors.resource_type && (
                <p style={{ color: 'red' }}>{errors.resource_type.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[22, 22]} className='button' justify='center'>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {editId ? (
                <Button className='detailsButton' onClick={handleeditCancel}>
                  Cancel
                </Button>
              ) : (
                <Button className='detailsButton' onClick={handleClose}>
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {editId ? (
                <Button className='detailsButton' htmlType='submit'>
                  Update
                </Button>
              ) : (
                <Button className='detailsButton' htmlType='submit'>
                  Create
                </Button>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
          </Row>
        </Spin>
      </form>
    </div>
  );
};

export default CountryTaxationModalForm;
