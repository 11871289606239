import API from '../Utils/API';

export const getUserThemes = (url, successCallback) => (dispatch) => {
  dispatch({ type: 'GET_USER_THEMES_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_USER_THEMES_SUCCESS', payload: response.data });
      successCallback && successCallback(response.data);
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_USER_THEMES_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const updateUserTheme =
  (data, successUpdateCallBack, failureCallback) => (dispatch) => {
    dispatch({ type: 'UPDATE_USER_THEME_REQUEST' });
    API.put(`api/user_view_themes/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_USER_THEME_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_USER_THEME_FAILURE',
          payload: error.response.data,
        });
        failureCallback && failureCallback(error.response.data);
      });
  };
