import API from '../Utils/API';

export const getStationsItems = (url) => (dispatch) => {
  dispatch({ type: 'GET_STATIONS_ITEMS_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_STATIONS_ITEMS_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_STATIONS_ITEMS_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createStationsItems =
  (data, successCreateStationsItems, failureCreateStationsItems) =>
  (dispatch) => {
    dispatch({ type: 'CREATE_STATIONS_ITEMS_REQUEST' });
    API.post('api/station_items', data)
      .then((response) => {

        dispatch({ type: "CREATE_STATIONS_ITEMS_SUCCESS", payload: response.data });
        successCreateStationsItems && successCreateStationsItems(response);

      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_STATIONS_ITEMS_FAILURE',
          payload: error.response.data,
        });
        failureCreateStationsItems &&
          failureCreateStationsItems(error.response.data);
      });
  };
export const deleteStationsItems =

  (data,successDeleteCallback) => (dispatch) => {
    dispatch({ type: "DELETE_STATIONS_ITEMS_REQUEST" });
    API.delete("api/multi_station_items_destroy",{data})

      .then((response) => {
        dispatch({
          type: 'DELETE_STATIONS_ITEMS_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_STATIONS_ITEMS_FAILURE',
          payload: error.response.data,
        });
      });
  };


export const updateStationsItems = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: "UPDATE_STATIONS_ITEMS_REQUEST" });
  API.put(`api/station_items/${data.id}`, data)
    .then((response) => {
      dispatch({ type: "UPDATE_STATIONS_ITEMS_SUCCESS", payload: response.data });
      successUpdateCallBack && successUpdateCallBack(response);
    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_STATIONS_ITEMS_FAILURE",
        payload: error.response.data,
      });
    });
  };
export const getStationsItemsById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_STATIONS_ITEMS_BY_ID_REQUEST' });
  API.get(`api/stations/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_STATIONS_ITEMS_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_STATIONS_ITEMS_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const reOrderStationsItems =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'REORDER_STATIONS_ITEMS_REQUEST' });
    API.put(`api/re_order_category/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'REORDER_STATIONS_ITEMS_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'REORDER_STATIONS_ITEMS_FAILURE',
          payload: error.response.data,
        });
      });
  };
