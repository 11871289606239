import React, { useEffect, useState } from 'react';
import { Row, Col, Pagination, Typography, message, Spin } from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { getTaxation, deleteTaxation } from '../../Actions/TaxationAction';
import CountryTaxationModalForm from './CountryTaxationModalForm';

const CountryTaxation = (props) => {
  const { CountryTaxationIsModalVisible, setIsCountryTaxationModalVisible } =
    props;
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { isTaxationloading, allTaxation, setCountryId } = useSelector(
    (state) => {
      const { isTaxationloading, allTaxation } = state.taxation;
      const { setCountryId, country } = state.country;
      return {
        isTaxationloading,
        country,
        setCountryId,
        allTaxation,
      };
    }
  );
  const { length } = allTaxation || {};
  useEffect(() => {
    dispatch(getTaxation(`api/taxations?resource_id=${setCountryId}`));
  }, [dispatch, currentPage]);

  const successDelete = () => {
    setTimeout(() => {
      message.success(`Country Taxation Delete Successfully`);
    }, 1000);
    dispatch(getTaxation(`api/taxations?resource_id=${setCountryId}`));
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsCountryTaxationModalVisible(true);
  };

  const Columns = [
    {
      title: 'Taxation Name',
      dataIndex: 'taxation_name',
      width: '10%',
      render: (Id, { id, taxation_name }) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {_.startCase(taxation_name)}
              </Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Resource Type',
      dataIndex: 'resource_type',
      width: '10%',
      render: (resource_type) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {resource_type}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Taxation Type',
      dataIndex: 'taxation_type',
      width: '10%',
      render: (taxation_type) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {taxation_type}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Tax Rate ',
      dataIndex: 'display_tax_rate',
      width: '10%',
      render: (display_tax_rate) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {display_tax_rate}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '4%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteTaxation]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={isTaxationloading}>
      <Row gutter={[24, 24]}>
        {CountryTaxationIsModalVisible ? (
          // <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <CountryTaxationModalForm
            CountryTaxationIsModalVisible={CountryTaxationIsModalVisible}
            setIsCountryTaxationModalVisible={setIsCountryTaxationModalVisible}
            editId={editId}
            setEditId={setEditId}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></CountryTaxationModalForm>
        ) : (
          // </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <CustomTable columns={Columns} data={allTaxation} />

            {allTaxation && length > 10 ? (
              <Col span={24}>
                <Row justify='center'>
                  <Pagination
                    total={allTaxation?.length}
                    onChange={(e) => setCurrentPage(e)}
                    responsive={true}
                    size='large'
                    current={currentPage}
                    showSizeChanger={false}
                  />
                </Row>
              </Col>
            ) : (
              ''
            )}
          </Col>
        )}
      </Row>
    </Spin>
  );
};

export default CountryTaxation;
