import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import {
  getSurvey,
  createSurvey,
  updateSurvey,
} from '../../Actions/surveyAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { surveySchema } from '../../Utils/Schema';
import { LuAsterisk } from 'react-icons/lu';

const SurveyModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, currentPage, setCurrentPage } =
    props;
  const dispatch = useDispatch();
  const [preloadedSurveyValue, setPreloadedSurveyValue] = useState('');

  const {
    isSurveyloading,
    allSurvey,
    isCreateSurveyoading,
    isUpdateSurveyLoading,
  } = useSelector((state) => {
    const {
      allSurvey,
      isSurveyloading,
      isCreateSurveyoading,
      isUpdateSurveyLoading,
    } = state.survey;
    return {
      allSurvey,
      isSurveyloading,
      isCreateSurveyoading,
      isUpdateSurveyLoading,
    };
  });
  const the_survey = _.filter(allSurvey, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedSurveyValue,
    },
    resolver: yupResolver(surveySchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedSurveyValue(the_survey[0]);
      reset(the_survey[0]);
    }
  }, [the_survey[0], reset]);

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('introduction', '');
    setValue('after_survey_content', '');
    clearErrors();
  };
  const handleCancel = () => {
    reset(preloadedSurveyValue);
    setEditId('');
    setValue('name', '');
    setValue('introduction', '');
    setValue('after_survey_content', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedSurveyValue);
    clearErrors();
  };
  const handleCreateSurvey = (data) => {
    dispatch(createSurvey(data, successCreateSurvey, failureCreateSurvey));
  };
  const successCreateSurvey = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getSurvey(`api/surveys?page=${currentPage}`));
    setValue('name', '');
    setValue('introduction', '');
    setValue('after_survey_content', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Survey Created Successfully');
    }, 1000);
  };

  const failureCreateSurvey = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleEditSurvey = (data) => {
    data.id = editId;
    dispatch(updateSurvey(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('introduction', '');
    setValue('after_survey_content', '');
    dispatch(getSurvey(`api/surveys?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Survey Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);
  const [width] = useState(window.innerWidth);
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #f6f6f6 inset',
  };
  return (
    <div style={{ width: 1200 }}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
              className='breadcrumRestaurant'
            >
              Surveys
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumRestaurant'>
              {editId ? 'Update Survey' : 'Add New Survey'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Spin
        style={{ height: '100%', maxHeight: 'unset' }}
        spinning={editId ? isUpdateSurveyLoading : isCreateSurveyoading}
        size='large'
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditSurvey : handleCreateSurvey
          )}
        >
          <Spin spinning={isSurveyloading}>
            <Row gutter={[16, 16]} className='button'>
              {editId ? (
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='heading'>Edit Survey</Typography>
                </Col>
              ) : (
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='heading'>Add New Survey</Typography>
                </Col>
              )}
            </Row>

            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>
                  Survey Name
                  <LuAsterisk className='asterisk' />
                </Typography>
                <Controller
                  as={<Input style={inputStyles} />}
                  name='name'
                  control={control}
                  className='inputLabel'
                />
                {errors.name && (
                  <p style={{ color: 'red' }}>{errors.name.message}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>
                  Introduction
                  <LuAsterisk className='asterisk' />
                </Typography>
                <Controller
                  as={<Input style={inputStyles} />}
                  name='introduction'
                  control={control}
                  className='inputLabel'
                />
                {errors.introduction && (
                  <p style={{ color: 'red' }}>{errors.introduction.message}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>
                  After Survey Content
                  <LuAsterisk className='asterisk' />
                </Typography>
                <Controller
                  as={<Input style={inputStyles} />}
                  name='after_survey_content'
                  control={control}
                  className='inputLabel'
                />
                {errors.after_survey_content && (
                  <p style={{ color: 'red' }}>
                    {errors.after_survey_content.message}
                  </p>
                )}
              </Col>
            </Row>

            <Row gutter={[22, 22]} className='button' justify='center'>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                {editId ? (
                  <Button
                    // className="filterButton"
                    className='detailsButton'
                    onClick={handleeditCancel}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    // className="filterButton"
                    className='detailsButton'
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                {editId ? (
                  <Button className='detailsButton' htmlType='submit'>
                    Update
                  </Button>
                ) : (
                  <Button className='detailsButton' htmlType='submit'>
                    Create
                  </Button>
                )}
              </Col>
              <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
            </Row>
          </Spin>
        </form>
      </Spin>
    </div>
  );
};

export default SurveyModalForm;
