import React from 'react';
import { isObject } from 'lodash';
import { Button } from 'antd';
const PdfViewer = (props) => {
  const { url, type, pdfName, width, height } = props;
  const customUrl = isObject(url)
    ? (window.URL || window.webkitURL).createObjectURL(url)
    : url;
  const viewPdfDocument = () => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = customUrl;
    a.click();
  };
  return (
    <Button type='primary' onClick={() => viewPdfDocument()}>
      View
    </Button>
  );
};
export default PdfViewer;
