import API from '../Utils/API';

export const getTables = (url, successCallback) => (dispatch) => {
  dispatch({ type: 'GET_TABLES_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_TABLES_SUCCESS', payload: response.data });
      successCallback && successCallback(response.data);
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_TABLES_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createTables =
  (data, successCreateTables, failureCreateTables) => (dispatch) => {
    dispatch({ type: 'CREATE_TABLES_REQUEST' });
    API.post('api/organization_tables', data)
      .then((response) => {
        dispatch({ type: 'CREATE_TABLES_SUCCESS', payload: response.data });
        successCreateTables && successCreateTables();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_TABLES_FAILURE',
          payload: error.response.data,
        });
        failureCreateTables && failureCreateTables(error.response.data);
      });
  };
export const deleteTables = (TablesId, successDeleteCallback) => (dispatch) => {
  dispatch({ type: 'DELETE_TABLES_REQUEST' });
  API.delete(`api/organization_tables/${TablesId}`)
    .then((response) => {
      dispatch({ type: 'DELETE_TABLES_SUCCESS', payload: response.data });
      successDeleteCallback();
    })
    .catch((error) => {
      dispatch({
        type: 'DELETE_TABLES_FAILURE',
        payload: error.response.data,
      });
    });
};

export const updateTables = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_TABLES_REQUEST' });
  API.put(`api/organization_tables/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_TABLES_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_TABLES_FAILURE',
        payload: error.response.data,
      });
    });
};
export const getTablesById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_TABLES_BY_ID_REQUEST' });
  API.get(`api/organization_tables/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_TABLES_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_TABLES_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
