import constants from '../Utils/constants';
const initialState = {
  isItemLoading: false,
  allItem: [],
  item: {},
};

export const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.CREATE_ITEM_REQUEST:
      return {
        ...state,
        isItemLoading: true,
      };
    case constants.CREATE_ITEM_SUCCESS:
      return {
        ...state,
        isItemLoading: false,
      };
    case constants.CREATE_ITEM_FAILURE:
      return {
        ...state,
        isItemLoading: false,
      };
    case constants.GET_ITEM_REQUEST:
      return {
        ...state,
        isItemLoading: true,
      };
    case constants.GET_ITEM_SUCCESS:
      return {
        ...state,
        isItemLoading: false,
        allItem: action.payload,
      };
    case constants.GET_ITEM_FAILURE:
      return {
        ...state,
        isItemLoading: false,
      };
    case constants.UPDATE_ITEM_REQUEST:
      return {
        ...state,
        isItemLoading: true,
      };
    case constants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        isItemLoading: false,
      };
    case constants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        isItemLoading: false,
      };
    case constants.GET_ITEM_BY_ID_REQUEST:
      return {
        ...state,
        isItemLoading: true,
      };
    case constants.GET_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        isItemLoading: false,
        item: action.payload,
      };
    case constants.GET_ITEM_BY_ID_FAILURE:
      return {
        ...state,
        isItemLoading: false,
      };
    default:
      return state;
  }
};
