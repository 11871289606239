import constants from '../Utils/constants';
const initialState = {
  isQrCodesloading: false,
  allQrCodes: [],
  isCreateQrCodesoading: false,
  isDeleteQrCodesLoading: false,
  isUpdateQrCodesLoading: false,
  setQrCodesId: '',
  QrCodes: {},
  isQrCodesByIdLoading: false,
  Error: '',
};

export const qrCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_QR_CODES_REQUEST:
      return {
        ...state,
        isQrCodesloading: true,
      };
    case constants.GET_QR_CODES_SUCCESS:
      return {
        ...state,
        isQrCodesloading: false,
        allQrCodes: action.payload,
      };
    case constants.GET_QR_CODES_FAILURE:
      return {
        ...state,
        isQrCodesloading: false,
      };
    case constants.CREATE_QR_CODES_REQUEST:
      return {
        ...state,
        isCreateQrCodesoading: true,
      };
    case constants.CREATE_QR_CODES_SUCCESS:
      return {
        ...state,
        isCreateQrCodesoading: false,
      };
    case constants.CREATE_QR_CODES_FAILURE:
      return {
        ...state,
        isCreateQrCodesoading: false,
        Error: action.payload?.name,
      };
    case constants.DELETE_QR_CODES_REQUEST:
      return {
        ...state,
        isDeleteQrCodesLoading: true,
      };
    case constants.DELETE_QR_CODES_SUCCESS:
      return {
        ...state,
        isDeleteQrCodesLoading: false,
      };
    case constants.DELETE_QR_CODES_FAILURE:
      return {
        ...state,
        isDeleteQrCodesLoading: false,
      };
    case constants.UPDATE_QR_CODES_REQUEST:
      return {
        ...state,
        isUpdateQrCodesLoading: true,
      };
    case constants.UPDATE_QR_CODES_SUCCESS:
      return {
        ...state,
        isUpdateQrCodesLoading: false,
      };
    case constants.UPDATE_QR_CODES_FAILURE:
      return {
        ...state,
        isUpdateQrCodesLoading: false,
      };
    // case constants.REORDER_QR_CODES_REQUEST:
    //   return {
    //     ...state,
    //     isUpdateQrCodesLoading: true,
    //   };
    // case constants.REORDER_QR_CODES_SUCCESS:
    //   return {
    //     ...state,
    //     isUpdateQrCodesLoading: false,
    //   };
    // case constants.REORDER_QR_CODES_FAILURE:
    //   return {
    //     ...state,
    //     isUpdateQrCodesLoading: false,
    //   };
    case constants.GET_QR_CODES_BY_ID_REQUEST:
      return {
        ...state,
        isQrCodesByIdLoading: true,
      };
    case constants.GET_QR_CODES_BY_ID_SUCCESS:
      return {
        ...state,
        isQrCodesByIdLoading: false,
        QrCodes: action.payload,
      };
    case constants.GET_QR_CODES_BY_ID_FAILURE:
      return {
        ...state,
        isQrCodesByIdLoading: false,
      };
    case constants.GET_SELECTED_QR_CODES_ID:
      return {
        ...state,
        setQrCodesId: action.payload,
      };
    default:
      return state;
  }
};
