import API from '../Utils/API';
import jwtDecode from 'jwt-decode';
import setAuthToken from '../Utils/setAuthToken';
import constants from '../Utils/constants';
export const getUsers = () => (dispatch) => {
  dispatch({ type: 'GET_USERS_REQUEST' });
  API.get('api/users')
    .then((response) => {
      dispatch({ type: 'GET_USERS_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: 'GET_USERS_FAILURE', payload: error.response.data });
    });
};
export const loginUserAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'LOGIN_USER_REQUEST' });
    API.post('api/login', data)
      .then((response) => {
        dispatch({ type: 'LOGIN_USER_SUCCESS', payload: response.data });
        successCallback &&
          successCallback(
            response.data,
            data.phone,
            data.country_code + data.phone,
            data.country_code
          );
        dispatch({ type: 'LOGIN_OTP', payload: response.data.otp });
        dispatch({
          type: 'SET_PHONE_NUMBER',
          payload: { phone: data.phone, country_code: data.country_code },
        });
      })
      .catch((error) => {
        dispatch({
          type: 'LOGIN_USER_FAILURE',
          payload: error?.response?.data,
        });
        failureCallback && failureCallback(error?.response?.data);
      });
  };
export const setCurrentUser = (decoded) => {
  localStorage.setItem('user_id', decoded.user_id);
  return {
    type: constants.SET_CURRENT_USER,
    payload: decoded,
  };
};
export const logoutCurrentUser = () => (dispatch) => {
  localStorage.removeItem('jwtToken');
  localStorage.clear();
  window.history.replaceState(null, null, '/');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  dispatch({ type: 'REMOVE_CURRENT_USER' });
  dispatch({ type: 'RESET_APP_DATA' });
};
export const verifyOtpAction =
  (data, successCallback, failureCallback) => (dispatch) => {
    dispatch({ type: 'VERIFY_OTP_REQUEST', payload: data });
    API.post('api/verify', data)
      .then((response) => {
        const tokenData = response.data.token;
        localStorage.setItem('access_token', tokenData);
        setAuthToken(tokenData);
        const decoded = jwtDecode(tokenData);
        dispatch(setCurrentUser(decoded));
        dispatch({ type: 'VERIFY_OTP_SUCCESS', payload: response.data });
        successCallback(response.data);
        dispatch(getUsers());
      })
      .catch((error) => {
        dispatch({
          type: 'VERIFY_OTP_FAILURE',
          payload: error?.response?.data,
        });
        localStorage.clear();
        failureCallback && failureCallback();
      });
  };
