import React, { useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Button,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import Delete from '../../assests/Delete.svg';
import { FiEdit2 } from 'react-icons/fi';
import {
  getPaymentMode,
  deletePaymentMode,
} from '../../Actions/paymentModeAction';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
const PaymentModeDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    setIsModalVisible,
    paymentModeDetailId,
    currentPage,
  } = props;
  const navigate = useNavigate();
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { PaymentMode } = useSelector((state) => {
    const { PaymentMode } = state.paymentMode;
    return {
      PaymentMode,
    };
  });
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setDrawerVisible(false);
  };
  const handleDelete = () => {
    dispatch(
      deletePaymentMode(paymentModeDetailId, successDelete, failureDelete)
    );
  };
  const successDelete = () => {
    setDrawerVisible(false);
    dispatch(getPaymentMode(`api/payment_modes?page=${currentPage}`));
    setTimeout(() => {
      message.success(`Payment Mode Delete Successfully`);
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const onHandleNavigate = (path) => {
    navigate(path);
  };
  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
            <Col span={24} style={{ marginTop: '25px' }}>
              <Row>
                <Col span={8}>
                  <Button
                    className='editButtonDrawer'
                    onClick={() => handleEdit(paymentModeDetailId, PaymentMode)}
                  >
                    <FiEdit2 className='editIcon' />
                    Edit
                  </Button>
                </Col>
                <Col span={8}>
                  <Popconfirm
                    title={
                      <Typography className='popconfirmTitle'>
                        Do you really want to delete this Category?
                      </Typography>
                    }
                    icon={<Typography className='warning'>Warning!</Typography>}
                    onConfirm={() => handleDelete()}
                    okButtonProps={{ className: 'popconfirmButton' }}
                    cancelButtonProps={{ className: 'popconfirmButton' }}
                    okText='Confirm'
                    cancelText='Cancel'
                    placement='topLeft'
                  >
                    <Button className='deleteButtonDrawer'>
                      <img
                        src={Delete}
                        className='deleteIcon'
                        width='20px'
                        height='16px'
                      />
                      Delete
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {PaymentMode && (
        <div>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Payment Mode </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {PaymentMode?.mode}
              </Typography>
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default PaymentModeDetails;
