import React, { useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Button,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import Delete from '../../assests/Delete.svg';
import { FiEdit2 } from 'react-icons/fi';
import { getCategory, deleteCategory } from '../../Actions/CategoryAction';
import { useNavigate } from 'react-router-dom';
import menuButton from '../../assests/menuButton.svg';

import { useDispatch, useSelector } from 'react-redux';
import { deleteStations, getStations } from '../../Actions/StationsAction';
const StationsDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    setIsModalVisible,
    stationsDetailId,
    currentPage,
  } = props;
  const navigate = useNavigate();
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { Stations } = useSelector((state) => {
    const { Stations } = state.stations;
    return {
      Stations,
    };
  });
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setDrawerVisible(false);
  };
  const handleDelete = () => {
    dispatch(deleteStations(stationsDetailId, successDelete, failureDelete));
  };
  const successDelete = () => {
    setDrawerVisible(false);
    dispatch(getStations(`api/stations?page=${currentPage}`));
    setTimeout(() => {
      message.success(`Stations Delete Successfully`);
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const onHandleNavigate = (path) => {
    navigate(path);
  };
  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
            <Col span={24} style={{ marginTop: '25px' }}>
              <Row>
                <Col span={8}>
                  <Button
                    className='editButtonDrawer'
                    onClick={() => handleEdit(stationsDetailId, Stations)}
                  >
                    <FiEdit2 className='editIcon' />
                    Edit
                  </Button>
                </Col>
                <Col span={8}>
                  <Popconfirm
                    title={
                      <Typography className='popconfirmTitle'>
                        Do you really want to delete this Category?
                      </Typography>
                    }
                    icon={<Typography className='warning'>Warning!</Typography>}
                    onConfirm={() => handleDelete()}
                    okButtonProps={{ className: 'popconfirmButton' }}
                    cancelButtonProps={{ className: 'popconfirmButton' }}
                    okText='Confirm'
                    cancelText='Cancel'
                    placement='topLeft'
                  >
                    <Button className='deleteButtonDrawer'>
                      <img
                        src={Delete}
                        className='deleteIcon'
                        width='20px'
                        height='16px'
                      />
                      Delete
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {Stations && (
        <div>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Stations Name </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {Stations?.name}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={12}>
              <Button
                size='large'
                className='detailsButton'
                onClick={() => onHandleNavigate('/stationItems')}
                icon={<img src={menuButton} alt='props' />}
              >
                Station Itmes
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default StationsDetails;
