import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
} from 'antd';
import {
  getSurveyQuestion,
  createSurveyQuestion,
  updateSurveyQuestion,
} from '../../Actions/surveyQuestionAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { surveySchema } from '../../Utils/Schema';

const SurveyQuestionModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, currentPage, setCurrentPage } =
    props;
  const dispatch = useDispatch();
  const [preloadedSurveyValue, setPreloadedSurveyValue] = useState('');

  const {
    isSurveyloading,
    allSurvey,
    isCreateSurveyoading,
    isUpdateSurveyLoading,
    allSurveyQuestion,
    setSurveyId,
  } = useSelector((state) => {
    const {
      allSurvey,
      isSurveyloading,
      isCreateSurveyoading,
      isUpdateSurveyLoading,
      setSurveyId,
    } = state.survey;
    const { allSurveyQuestion, isSurveyQuestionloading } = state.surveyQuestion;
    return {
      allSurvey,
      isSurveyloading,
      isCreateSurveyoading,
      isUpdateSurveyLoading,
      allSurveyQuestion,
      setSurveyId,
    };
  });
  const the_survey = _.filter(allSurvey, function (o) {
    return o.id === editId;
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedSurveyValue,
    },
    resolver: yupResolver(surveySchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedSurveyValue(the_survey[0]);
      reset(the_survey[0]);
    }
  }, [the_survey[0], reset]);

  const selectedCommunityCard = _.filter(allSurveyQuestion, (Cards) => {
    return Cards.id === editId;
  });
  const [OptionSelected, setOptionSelected] = useState(
    selectedCommunityCard[0]?.type
  );
  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('introduction', '');
    setValue('after_survey_content', '');
    clearErrors();
  };
  const handleCancel = () => {
    reset(preloadedSurveyValue);
    setEditId('');
    setValue('name', '');
    setValue('introduction', '');
    setValue('after_survey_content', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedSurveyValue);
    clearErrors();
  };
  const handleCreateSurvey = (data) => {
    data.survey_id = setSurveyId;
    dispatch(
      createSurveyQuestion(data, successCreateSurvey, failureCreateSurvey)
    );
  };
  const successCreateSurvey = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
    // setValue("name", "");
    // setValue("introduction", "");
    // setValue("after_survey_content", "");
    clearErrors();
    setTimeout(() => {
      message.success('Your Survey Created Successfully');
    }, 1000);
  };

  const failureCreateSurvey = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleEditSurvey = (data) => {
    data.id = editId;
    dispatch(updateSurveyQuestion(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('introduction', '');
    setValue('after_survey_content', '');
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Survey Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);
  const [width] = useState(window.innerWidth);
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #e5e5e5 inset',
  };
  const cardArray = [
    { label: 'Checkbox', value: 'Rapidfire::Questions::Checkbox' },
    { label: 'Date', value: 'Rapidfire::Questions::Date' },
    { label: 'Long', value: 'Rapidfire::Questions::Long' },
    { label: 'Numeric', value: 'Rapidfire::Questions::Numeric' },
    { label: 'Radio', value: 'Rapidfire::Questions::Radio' },
    { label: 'Select', value: 'Rapidfire::Questions::Select' },
    { label: 'Short', value: 'Rapidfire::Questions::Short' },
    { label: 'Information', value: 'Rapidfire::Questions::Information' },
    { label: 'File', value: 'Rapidfire::Questions::File' },
    { label: 'MultiFile', value: 'Rapidfire::Questions::MultiFile' },
  ];
  return (
    <div style={{ width: 1200 }}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
              className='breadcrumRestaurant'
            >
              Surveys Question
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumRestaurant'>
              {editId ? 'Update Survey' : 'Add New Survey'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Spin
        style={{ height: '100%', maxHeight: 'unset' }}
        spinning={editId ? isUpdateSurveyLoading : isCreateSurveyoading}
        size='large'
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditSurvey : handleCreateSurvey
          )}
        >
          <Spin spinning={isSurveyloading}>
            <Row gutter={[16, 16]} className='button'>
              {editId ? (
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='heading'>Edit Survey</Typography>
                </Col>
              ) : (
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='heading'>Add New Survey</Typography>
                </Col>
              )}
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='details'>Question Type</Typography>
                <Controller
                  as={
                    <Select
                      className='select'
                      style={{ inputStyles, width: '100%' }}
                      options={cardArray}
                      onSelect={(e) => setOptionSelected(e)}
                    />
                  }
                  name='type'
                  control={control}
                  className='inputLabel'
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>Question Text</Typography>
                <Controller
                  as={<Input style={inputStyles} />}
                  name='question_text'
                  control={control}
                  className='inputLabel'
                />
                {/* {errors.question_text && (
                  <p style={{ color: "red" }}>{errors.question_text.message}</p>
                )} */}
              </Col>
            </Row>
            {OptionSelected === 'Rapidfire::Questions::Checkbox' ? (
              <>
                <Row gutter={[16, 16]} className='button'>
                  <Col
                    xl={16}
                    lg={16}
                    md={16}
                    sm={24}
                    xs={24}
                    className='formRows'
                  >
                    <Typography className='formHeading'>
                      Answer Options
                    </Typography>
                    <Controller
                      as={<Input style={inputStyles} />}
                      name='placeholder'
                      control={control}
                      className='inputLabel'
                    />
                    {/* {errors.placeholder && (
                  <p style={{ color: "red" }}>{errors.placeholder.message}</p>
                )} */}
                  </Col>
                </Row>
              </>
            ) : OptionSelected === 'Rapidfire::Questions::Date' ? (
              <Row>
                <h2>Date</h2>
              </Row>
            ) : OptionSelected === 'Rapidfire::Questions::Long' ? (
              <Row>
                <h2>Long</h2>
              </Row>
            ) : OptionSelected === 'Rapidfire::Questions::Numeric' ? (
              <Row>
                <h2>Numeric</h2>
              </Row>
            ) : OptionSelected === 'Rapidfire::Questions::Radio' ? (
              <Row>
                <h2>Radio</h2>
              </Row>
            ) : OptionSelected === 'Rapidfire::Questions::Select' ? (
              <Row>
                <h2>Select</h2>
              </Row>
            ) : OptionSelected === 'Rapidfire::Questions::Short' ? (
              <Row>
                <h2>Short</h2>
              </Row>
            ) : OptionSelected === 'Rapidfire::Questions::Information' ? (
              <Row>
                <h2>Information</h2>
              </Row>
            ) : OptionSelected === 'Rapidfire::Questions::File' ? (
              <Row>
                <h2>File</h2>
              </Row>
            ) : OptionSelected === 'Rapidfire::Questions::MultiFile' ? (
              <Row>
                <h2>MultiFile</h2>
              </Row>
            ) : null}

            <Row gutter={[22, 22]} className='button' justify='center'>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                {editId ? (
                  <Button
                    // className="filterButton"
                    className='detailsButton'
                    onClick={handleeditCancel}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    // className="filterButton"
                    className='detailsButton'
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                {editId ? (
                  <Button className='detailsButton' htmlType='submit'>
                    Update
                  </Button>
                ) : (
                  <Button className='detailsButton' htmlType='submit'>
                    Create
                  </Button>
                )}
              </Col>
              <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
            </Row>
          </Spin>
        </form>
      </Spin>
    </div>
  );
};

export default SurveyQuestionModalForm;
