import constants from '../Utils/constants';
const initialState = {
  isOrganizationUserLoading: false,
  allOrganizationUser: [],
  organizationUser: {},
};

export const organizationUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ORGANIZATION_USER_REQUEST:
      return {
        ...state,
        isOrganizationUserLoading: true,
      };
    case constants.GET_ORGANIZATION_USER_SUCCESS:
      return {
        ...state,
        isOrganizationUserLoading: false,
        allOrganizationUser: action.payload,
      };
    case constants.GET_ORGANIZATION_USER_FAILURE:
      return {
        ...state,
        isOrganizationUserLoading: false,
      };
    default:
      return state;
  }
};
