import API from '../Utils/API';

export const getItemTypes = (url) => (dispatch) => {
  dispatch({ type: 'GET_ITEM_TYPE_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_ITEM_TYPE_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_ITEM_TYPE_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const createItemType =
  (data, successCreateItemType, failureCreateItemType) => (dispatch) => {
    dispatch({ type: 'CREATE_ITEM_TYPE_REQUEST' });
    let formData = new FormData();
    formData.append('item_type[name]', data.name);
    formData.append('item_type[is_veg]', data.is_veg);
    data.admin_type_image &&
      formData.append('item_type[admin_type_image]', data.admin_type_image);
    data.user_type_image &&
      formData.append('item_type[user_type_image]', data.user_type_image);
    API.post('api/item_types', formData)
      .then((response) => {
        dispatch({
          type: 'CREATE_ITEM_TYPE_SUCCESS',
          payload: response.data,
        });
        successCreateItemType && successCreateItemType();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_ITEM_TYPE_FAILURE',
          payload: error.response.data,
        });
        failureCreateItemType && failureCreateItemType(error.response.data);
      });
  };

export const deleteItemType = (itemId, successDeleteCallback) => (dispatch) => {
  dispatch({ type: 'DELETE_ITEM_TYPE_REQUEST' });
  API.delete(`/api/item_types/${itemId}`)
    .then((response) => {
      dispatch({ type: 'DELETE_ITEM_TYPE_SUCCESS', payload: response.data });
      successDeleteCallback();
    })
    .catch((error) => {
      dispatch({
        type: 'DELETE_ITEM_TYPE_FAILURE',
        payload: error.response.data,
      });
    });
};

export const updateItemType = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_ITEM_TYPE_REQUEST' });
  let formData = new FormData();
  formData.append('item_type[name]', data.name);
  formData.append('item_type[is_veg]', data.is_veg);
  data.admin_type_image &&
    formData.append('item_type[admin_type_image]', data.admin_type_image);
  data.user_type_image &&
    formData.append('item_type[user_type_image]', data.user_type_image);
  API.put(`api/item_types/${data.id}`, formData)
    .then((response) => {
      dispatch({ type: 'UPDATE_ITEM_TYPE_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_ITEM_TYPE_FAILURE',
        payload: error.response.data,
      });
    });
};

export const getItemTypeById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_ITEM_TYPE_BY_ID_REQUEST' });
  API.get(`api/item_types/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_ITEM_TYPE_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_ITEM_TYPE_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
