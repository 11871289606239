import API from '../Utils/API';

export const getCategoryItem = (url) => (dispatch) => {
  dispatch({ type: 'GET_CATEGORY_ITEM_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_CATEGORY_ITEM_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_CATEGORY_ITEM_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createCategoryItem =
  (data, successCreateCategory, failureCreateCategory) => (dispatch) => {
    dispatch({ type: 'CREATE_CATEGORY_ITEM_REQUEST' });
    API.post('api/category_items', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_CATEGORY_ITEM_SUCCESS',
          payload: response.data,
        });
        successCreateCategory && successCreateCategory();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_CATEGORY_ITEM_FAILURE',
          payload: error.response.data,
        });
        failureCreateCategory && failureCreateCategory();
      });
  };
export const deleteCategoryItem =
  (CategoryId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_CATEGORY_ITEM_REQUEST' });
    API.delete(`api/category_items/${CategoryId}`)
      .then((response) => {
        dispatch({
          type: 'DELETE_CATEGORY_ITEM_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_CATEGORY_ITEM_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const duplicateCategoryItem =
  (data, successDuplicateCallback) => (dispatch) => {
    dispatch({ type: 'DUPLICATE_CATEGORY_ITEM_REQUEST' });
    API.post(`api/add_duplicate_menu_item`, data)
      .then((response) => {
        dispatch({
          type: 'DUPLICATE_CATEGORY_ITEM_SUCCESS',
          payload: response.data,
        });
        successDuplicateCallback && successDuplicateCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DUPLICATE_CATEGORY_ITEM_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateCategoryItem =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_CATEGORY_ITEM_REQUEST' });
    API.put(`api/category_items/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_CATEGORY_ITEM_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_CATEGORY_ITEM_FAILURE',
          payload: error.response.data,
        });
      });
  };
export const getCategoryItemById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_CATEGORY_ITEM_BY_ID_REQUEST' });
  API.get(`api/category_items/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_CATEGORY_ITEM_BY_ID_SUCCESS',
        payload: response?.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CATEGORY_ITEM_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const addMultiItemsinCategory =
  (data, successAddMultipleItems, failureAddMultipleItems) => (dispatch) => {
    dispatch({ type: 'ADD_MULTI_ITEMS_IN_CATEGORY_REQUEST' });
    API.post(`api/add_multi_items_in_category`, data)
      .then((response) => {
        dispatch({
          type: 'ADD_MULTI_ITEMS_IN_CATEGORY_SUCCESS',
          payload: response.data,
        });
        successAddMultipleItems && successAddMultipleItems(data);
      })
      .catch((error) => {
        dispatch({
          type: 'ADD_MULTI_ITEMS_IN_CATEGORY_FAILURE',
          payload: error?.response.data,
        });
        failureAddMultipleItems && failureAddMultipleItems(error.response.data);
      });
  };
export const reOrderCategoryItem =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'REORDER_CATEGORY_ITEM_REQUEST' });
    API.put(`api/re_order_category_item/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'REORDER_CATEGORY_ITEM_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'REORDER_CATEGORY_ITEM_FAILURE',
          payload: error.response.data,
        });
      });
  };
export const availabilityCategoryItem =
  (data, successUpdateCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: 'AVAILABILITY_CATEGORY_ITEM_REQUEST' });
    API.put(`api/available_item/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'AVAILABILITY_CATEGORY_ITEM_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'AVAILABILITY_CATEGORY_ITEM_FAILURE',
          payload: error.response.data,
        });
        failureCallBack && failureCallBack(error.response.data);
      });
  };
