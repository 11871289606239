import API from '../Utils/API';

export const getCustomizationItem = (url) => (dispatch) => {
  dispatch({ type: 'GET_CUSTOMIZATION_ITEM_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({
        type: 'GET_CUSTOMIZATION_ITEM_SUCCESS',
        payload: response?.data,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_CUSTOMIZATION_ITEM_FAILURE',
          payload: error?.response?.data,
        });
      } catch {}
    });
};
export const createCustomizationItem =
  (data, successCreateCustomizationItem, failureCreateCustomizationItem) =>
  (dispatch) => {
    dispatch({ type: 'CREATE_CUSTOMIZATION_ITEM_REQUEST' });
    API.post('api/customization_items', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_CUSTOMIZATION_ITEM_SUCCESS',
          payload: response.data,
        });
        successCreateCustomizationItem && successCreateCustomizationItem();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_CUSTOMIZATION_ITEM_FAILURE',
          payload: error.response.data,
        });
        failureCreateCustomizationItem &&
          failureCreateCustomizationItem(error.response.data);
      });
  };
export const deleteCustomizationItem =
  (CustomizationId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_CUSTOMIZATION_ITEM_REQUEST' });
    API.delete(`api/customization_items/${CustomizationId}`)
      .then((response) => {
        dispatch({
          type: 'DELETE_CUSTOMIZATION_ITEM_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_CUSTOMIZATION_ITEM_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateCustomizationItem =
  (data, successUpdateCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_CUSTOMIZATION_ITEM_REQUEST' });
    API.put(`api/customization_items/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_CUSTOMIZATION_ITEM_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_CUSTOMIZATION_ITEM_FAILURE',
          payload: error.response.data,
        });
        failureCallBack && failureCallBack(error.response.data);
      });
  };
export const getCustomizationItemById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_CUSTOMIZATION_ITEM_BY_ID_REQUEST' });
  API.get(`api/customization_items/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_CUSTOMIZATION_ITEM_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_CUSTOMIZATION_ITEM_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const availabilityCustomizationItem =
  (data, successUpdateCallBack, failureCallBack) => (dispatch) => {
    dispatch({ type: 'AVAILABILITY_CUSTOMIZATION_ITEM_REQUEST' });
    API.put(`api/available_customization_item/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'AVAILABILITY_CUSTOMIZATION_ITEM_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'AVAILABILITY_CUSTOMIZATION_ITEM_FAILURE',
          payload: error.response.data,
        });
        failureCallBack && failureCallBack(error.response.data);
      });
  };
export const reOrderCustomizationItem =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'REORDER_CUSTOMIZATION_ITEM_REQUEST' });
    API.put(`api/re_order_customization_item/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'REORDER_CUSTOMIZATION_ITEM_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'REORDER_CUSTOMIZATION_ITEM_FAILURE',
          payload: error.response.data,
        });
      });
  };
export const duplicateCustomizationItem =
  (data, successDuplicateCallback) => (dispatch) => {
    dispatch({ type: 'DUPLICATE_CUSTOMIZATION_ITEM_REQUEST' });
    API.post(`api/duplicate_customization_item`, data)
      .then((response) => {
        dispatch({
          type: 'DUPLICATE_CUSTOMIZATION_ITEM_SUCCESS',
          payload: response.data,
        });
        successDuplicateCallback && successDuplicateCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DUPLICATE_CUSTOMIZATION_ITEM_FAILURE',
          payload: error.response.data,
        });
      });
  };
