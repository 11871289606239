import constants from '../Utils/constants';
const initialState = {
  isStationsItemsloading: false,
  allStationsItems: [],
  isCreateStationsItemsoading: false,
  isDeleteStationsItemsLoading: false,
  isUpdateStationsItemsLoading: false,
  setStationsItemsId: '',
  StationsItems: {},
  isStationsItemsByIdLoading: false,
  Error: '',
};

export const stationsItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_STATIONS_ITEMS_REQUEST:
      return {
        ...state,
        isStationsItemsloading: true,
      };
    case constants.GET_STATIONS_ITEMS_SUCCESS:
      return {
        ...state,
        isStationsItemsloading: false,
        allStationsItems: action.payload,
      };
    case constants.GET_STATIONS_ITEMS_FAILURE:
      return {
        ...state,
        isStationsItemsloading: false,
      };
    case constants.CREATE_STATIONS_ITEMS_REQUEST:
      return {
        ...state,
        isCreateStationsItemsoading: true,
      };
    case constants.CREATE_STATIONS_ITEMS_SUCCESS:
      return {
        ...state,
        isCreateStationsItemsoading: false,
      };
    case constants.CREATE_STATIONS_ITEMS_FAILURE:
      return {
        ...state,
        isCreateStationsItemsoading: false,
        Error: action.payload?.name,
      };
    case constants.DELETE_STATIONS_ITEMS_REQUEST:
      return {
        ...state,
        isDeleteStationsItemsLoading: true,
      };
    case constants.DELETE_STATIONS_ITEMS_SUCCESS:
      return {
        ...state,
        isDeleteStationsItemsLoading: false,
      };
    case constants.DELETE_STATIONS_ITEMS_FAILURE:
      return {
        ...state,
        isDeleteStationsItemsLoading: false,
      };
    case constants.UPDATE_STATIONS_ITEMS_REQUEST:
      return {
        ...state,
        isUpdateStationsItemsLoading: true,
      };
    case constants.UPDATE_STATIONS_ITEMS_SUCCESS:
      return {
        ...state,
        isUpdateStationsItemsLoading: false,
      };
    case constants.UPDATE_STATIONS_ITEMS_FAILURE:
      return {
        ...state,
        isUpdateStationsItemsLoading: false,
      };
    case constants.GET_STATIONS_ITEMS_BY_ID_REQUEST:
      return {
        ...state,
        isStationsItemsByIdLoading: true,
      };
    case constants.GET_STATIONS_ITEMS_BY_ID_SUCCESS:
      return {
        ...state,
        isStationsItemsByIdLoading: false,
        StationsItems: action.payload,
      };
    case constants.GET_STATIONS_ITEMS_BY_ID_FAILURE:
      return {
        ...state,
        isStationsItemsByIdLoading: false,
      };
    case constants.GET_SELECTED_STATIONS_ITEMS_ID:
      return {
        ...state,
        setStationsItemsId: action.payload,
      };
    default:
      return state;
  }
};
