import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Avatar,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Card,
  Tabs,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  getRestaurant,
  deleteRestaurant,
  updatePublishRestaurantAction,
  getRestaurantDetails,
  getRestaurantById,
} from '../../Actions/RestaurantAction';
import { ImageEnv } from '../CommonLogics/CommonMethods';
import RestaurantModalForm from './RestaurantModalForm';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import RestaurantDetails from './RestaurantDetails';
import publish from '../../assests/publish.svg';
import unpublish from '../../assests/unpublish.svg';
import plus from '../../assests/plus.svg';
import filter from '../../assests/filter.svg';
import Location from './Location';
import OprationTiming from './OprationTiming';
import FSSAIDetails from './FSSAIDetails';
import { useNavigate } from 'react-router';
const Restaurant = () => {
  const { TabPane } = Tabs;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState();
  const [restaurantDetailId, setrestaurantDetailId] = useState('');
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [tabKey, setTabkey] = useState('1');
  const [isType, setIsType] = useState('');
  const navigate = useNavigate();
  // const [selectedRow, setSelectedRow] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const dispatch = useDispatch();
  let UnpublishData = {};
  let PublishData = {};
  const { allRestaurant, isRestaurantloading, isDeleteRestaurantLoading } =
    useSelector((state) => {
      const { allRestaurant, isRestaurantloading, isDeleteRestaurantLoading } =
        state.restaurant;
      return {
        allRestaurant,
        isRestaurantloading,
        isDeleteRestaurantLoading,
      };
    });

  const { organizations } = allRestaurant;
  const { length } = organizations || {};

  useEffect(() => {
    dispatch(getRestaurant(`api/organizations?page=${currentPage}`));
  }, [dispatch, currentPage]);

  const handleSortButtonClick = () => {
    const sortedResults = [...organizations].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (sortOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setSortedData(sortedResults);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`Restaurant Delete Successfully`);
    }, 1000);
    dispatch(getRestaurant(`api/organizations?page=${currentPage}`));
  };
  const handlePublishMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updatePublishRestaurantAction(
        PublishData,
        successUpdatePublishMethod,
        faliureDelete
      )
    );
  };
  const handleUnpublishMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updatePublishRestaurantAction(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success('Your Restaurant Published Successfully');
    }, 1000);
    dispatch(getRestaurant(`api/organizations?page=${currentPage}`));
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success('Your Restaurant Unpublished Successfully');
    }, 1000);
    dispatch(getRestaurant(`api/organizations?page=${currentPage}`));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const onTabClick = (key) => {
    localStorage.setItem('organization_id', editId);
    setTabkey(key);
    setEditId(editId);
    // dispatch(getRestaurantById(editId));
    // setDrawerVisible(true);
    // dispatch(getRestaurantDetails(process.env.REACT_APP_RESTAURANT_ID));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: editId,
    });
  };

  const handleOpenRestaurant = (orgId) => {
    setDrawerVisible(true);
    navigate(`/restaurants/${orgId}`);
    setrestaurantDetailId(orgId);
    localStorage.setItem('organization_id', orgId);
    // dispatch(getRestaurantById(orgId));
    // dispatch(getRestaurantDetails(process.env.REACT_APP_RESTAURANT_ID));
    // dispatch({
    //   type: 'GET_SELECTED_RESTAURANT_ID',
    //   payload: orgId,
    // });
  };
  const openRestaurant = () => {
    setIsModalVisible(true);
    setEditId('');
  };
  const handleEdit = (id) => {
    setIsType('updateForm');
    localStorage.setItem('organization_id', id);
    setEditId(id);
    setIsModalVisible(true);
    setTabkey('1');
    // dispatch(getRestaurantById(id));
    // setDrawerVisible(true);
    // dispatch(getRestaurantDetails(process.env.REACT_APP_RESTAURANT_ID));
    // dispatch({
    //   type: 'GET_SELECTED_RESTAURANT_ID',
    //   payload: id,
    // });
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setTabkey('1');
    setShowUploadImage(false);
    setIsDefaultImageVisible(true);
    dispatch(getRestaurant(`api/organizations?page=${currentPage}`));
  };
  const Columns = [
    {
      // title: "Org Logo",
      dataIndex: 'org_logo',
      width: '10%',
      render: (id, { org_logo, name }) => {
        return (
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
              <Avatar
                shape='square'
                size={35}
                className='avtar'
                src={org_logo && ImageEnv(org_logo)}
              >
                <Typography style={{ fontSize: '20px', color: 'white' }}>
                  {name?.[0]}
                </Typography>
              </Avatar>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Restaurant Name',
      dataIndex: 'name',
      width: '40%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpenRestaurant(id)}
                >
                  {name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Legal Entity Name',
      dataIndex: 'legal_entity_name',
      width: '20%',
      render: (Id, { legal_entity_name }) => {
        return (
          <div>
            <Typography>{legal_entity_name}</Typography>
          </div>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'id',
      width: '10%',
      render: (id, { published }) => {
        return (
          <Row justify='space-between' align='middle'>
            <Col style={{ padding: '6px' }}>
              {published ? (
                <Tooltip title='Click for Unpublish'>
                  <img
                    src={publish}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handlePublishMethod(id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title='Click for Publish'>
                  <img
                    src={unpublish}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleUnpublishMethod(id)}
                  />
                </Tooltip>
              )}
            </Col>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteRestaurant]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isRestaurantloading || isDeleteRestaurantLoading}>
      <Row gutter={[16, 16]}>
        <Col
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
          style={{ marginTop: '-5px' }}
        >
          <Row gutter={[16, 16]}>
            {isModalVisible ? (
              <div style={{ width: '100%' }}>
                <Row gutter={[24, 24]}>
                  <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                    <Breadcrumb separator='>'>
                      <Breadcrumb.Item
                        onClick={() => handleClose()}
                        style={{ cursor: 'pointer' }}
                        className='breadcrumRestaurant'
                      >
                        Restaurants
                      </Breadcrumb.Item>
                      <Breadcrumb.Item className='breadcrumRestaurant'>
                        {editId ? 'Update Restaurant' : 'Add New Restaurant'}
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className='button'>
                  <Col span={24}></Col>
                  {editId ? (
                    <Col
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className='formRows'
                    >
                      <Typography className='heading'>
                        Update Restaurant
                      </Typography>
                    </Col>
                  ) : (
                    <Col
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className='formRows'
                    >
                      <Typography className='heading'>
                        Add New Restaurant
                      </Typography>
                    </Col>
                  )}
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}></Col>
                  <Col
                    xl={18}
                    lg={18}
                    md={18}
                    sm={12}
                    xs={12}
                    style={{ width: '100%' }}
                  >
                    <Tabs
                      activeKey={tabKey}
                      onChange={onTabClick}
                      animated={false}
                      renderTabBar={(props, DefaultTabBar) => (
                        <DefaultTabBar {...props}>
                          {(node) => <div key={node.key}>{node}</div>}
                        </DefaultTabBar>
                      )}
                    >
                      <TabPane
                        tab={
                          <Typography
                            className={
                              tabKey === '1'
                                ? 'selectedButton'
                                : 'unselectedButton'
                            }
                          >
                            General Details
                          </Typography>
                        }
                        key='1'
                      ></TabPane>
                      <TabPane
                        // disabled={editId?false:true}
                        tab={
                          <Typography
                            className={
                              tabKey === '2'
                                ? 'selectedButton'
                                : 'unselectedButton'
                            }
                          >
                            Location
                          </Typography>
                        }
                        key='2'
                      ></TabPane>
                      <TabPane
                        // disabled={editId?false:true}
                        tab={
                          <Typography
                            className={
                              tabKey === '3'
                                ? 'selectedButton'
                                : 'unselectedButton'
                            }
                          >
                            Operational Timing
                          </Typography>
                        }
                        key='3'
                      ></TabPane>
                      <TabPane
                        // disabled={editId?false:true}
                        tab={
                          <Typography
                            className={
                              tabKey === '4'
                                ? 'selectedButton'
                                : 'unselectedButton'
                            }
                          >
                            Country Requirenments
                          </Typography>
                        }
                        key='4'
                      ></TabPane>
                    </Tabs>
                  </Col>
                </Row>
                {tabKey === '1' && (
                  <RestaurantModalForm
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    editId={editId}
                    setEditId={setEditId}
                    setIsDefaultImageVisible={setIsDefaultImageVisible}
                    isDefaultImageVisible={isDefaultImageVisible}
                    setShowUploadImage={setShowUploadImage}
                    showUploadImage={showUploadImage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    restaurantDetailId={restaurantDetailId}
                    setrestaurantDetailId={setrestaurantDetailId}
                    setTabkey={setTabkey}
                    isType={isType}
                    setIsType={setIsType}
                  ></RestaurantModalForm>
                )}
                {tabKey === '2' && (
                  <Location
                    restaurantDetailId={restaurantDetailId}
                    editId={editId}
                    setEditId={setEditId}
                    setrestaurantDetailId={setrestaurantDetailId}
                    setTabkey={setTabkey}
                    isType={isType}
                  />
                )}
                {tabKey === '3' && (
                  <OprationTiming
                    restaurantDetailId={restaurantDetailId}
                    editId={editId}
                    setEditId={setEditId}
                    setrestaurantDetailId={setrestaurantDetailId}
                    setTabkey={setTabkey}
                  />
                )}
                {tabKey === '4' && (
                  <FSSAIDetails
                    restaurantDetailId={restaurantDetailId}
                    editId={editId}
                    setEditId={setEditId}
                    setrestaurantDetailId={setrestaurantDetailId}
                    setTabkey={setTabkey}
                    setIsModalVisible={setIsModalVisible}
                  />
                )}
              </div>
            ) : (
              <>
                <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                  <Typography className='heading'>Restaurants</Typography>
                </Col>
                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                  <Row justify='end' gutter={[8, 8]}>
                    <Col>
                      <Button
                        className='detailsButton'
                        onClick={handleSortButtonClick}
                        icon={
                          <img
                            src={filter}
                            alt='props'
                            style={{ width: '20%' }}
                          />
                        }
                      >
                        Filter
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className='detailsButton'
                        onClick={openRestaurant}
                        icon={<img src={plus} alt='props' />}
                      >
                        Create New
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}></Col>
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ height: '100%' }}
                >
                  <Row>
                    <Col span={24}>
                      {(organizations || sortedData) && (
                        <CustomTable
                          className='ant-table-body'
                          columns={Columns}
                          data={
                            sortOrder === 'asc' ? organizations : sortedData
                          }
                          drawerVisible={drawerVisible}
                          setDrawerVisible={setDrawerVisible}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>

                {allRestaurant && length > 0 ? (
                  <Col span={24}>
                    <Row justify='center' style={{ margin: 10 }}>
                      {(organizations || sortedData) && (
                        <Pagination
                          total={allRestaurant?.length}
                          onChange={(e) => setCurrentPage(e)}
                          responsive={true}
                          size='large'
                          current={currentPage}
                          showSizeChanger={false}
                        />
                      )}
                    </Row>
                  </Col>
                ) : (
                  ''
                )}
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default Restaurant;
