import React, { useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Button,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import Delete from '../../assests/Delete.svg';
import { FiEdit2 } from 'react-icons/fi';
import {
  getOrderProcess,
  deleteOrderProcess,
} from '../../Actions/OrderProcessAction';
import { useDispatch, useSelector } from 'react-redux';

const OrderProcessDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    setEditId,
    setIsModalVisible,
    orderProcessDetailId,
    tabKey,
  } = props;
  const [width] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { OrderProcess } = useSelector((state) => {
    const { OrderProcess } = state.orderProcess;
    return {
      OrderProcess,
    };
  });
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    setDrawerVisible(false);
  };
  const handleDelete = () => {
    dispatch(deleteOrderProcess(orderProcessDetailId, successDelete));
  };

  const successDelete = () => {
    setDrawerVisible(false);
    dispatch(
      getOrderProcess(`api/organization_order_processes?is_sequence=true`)
    );
    setTimeout(() => {
      message.success(`Order Process Delete Successfully`);
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
            {tabKey === '1' ? (
              <Col span={24} style={{ marginTop: '25px' }}>
                <Row>
                  <Col span={8}>
                    <Button
                      className='editButtonDrawer'
                      onClick={() =>
                        handleEdit(orderProcessDetailId, OrderProcess)
                      }
                    >
                      <FiEdit2 className='editIcon' />
                      Edit
                    </Button>
                  </Col>
                  <Col span={8}>
                    <Popconfirm
                      title={
                        <Typography className='popconfirmTitle'>
                          Do you really want to delete this Order Process?
                        </Typography>
                      }
                      icon={
                        <Typography className='warning'>Warning!</Typography>
                      }
                      onConfirm={() => handleDelete()}
                      okButtonProps={{ className: 'popconfirmButton' }}
                      cancelButtonProps={{ className: 'popconfirmButton' }}
                      okText='Confirm'
                      cancelText='Cancel'
                      placement='topLeft'
                    >
                      <Button className='deleteButtonDrawer'>
                        <img
                          src={Delete}
                          className='deleteIcon'
                          width='20px'
                          height='16px'
                        />
                        Delete
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {OrderProcess && (
        <div>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>
                Order Process Name{' '}
              </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {OrderProcess?.name}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Position </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {OrderProcess?.position}
              </Typography>
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default OrderProcessDetails;
