import constants from '../Utils/constants';
const initialState = {
  isOrderProcessloading: false,
  allOrderProcess: [],
  isCreateOrderProcessoading: false,
  isDeleteOrderProcessLoading: false,
  isUpdateOrderProcessLoading: false,
  setOrderProcessId: '',
  OrderProcess: {},
  isOrderProcessByIdLoading: false,
};

export const orderProcessReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ORDER_PROCESS_REQUEST:
      return {
        ...state,
        isOrderProcessloading: true,
      };
    case constants.GET_ORDER_PROCESS_SUCCESS:
      return {
        ...state,
        isOrderProcessloading: false,
        allOrderProcess: action.payload,
      };
    case constants.GET_ORDER_PROCESS_FAILURE:
      return {
        ...state,
        isOrderProcessloading: false,
      };
    case constants.CREATE_ORDER_PROCESS_REQUEST:
      return {
        ...state,
        isCreateOrderProcessoading: true,
      };
    case constants.CREATE_ORDER_PROCESS_SUCCESS:
      return {
        ...state,
        isCreateOrderProcessoading: false,
      };
    case constants.CREATE_ORDER_PROCESS_FAILURE:
      return {
        ...state,
        isCreateOrderProcessoading: false,
      };
    case constants.DELETE_ORDER_PROCESS_REQUEST:
      return {
        ...state,
        isDeleteOrderProcessLoading: true,
      };
    case constants.DELETE_ORDER_PROCESS_SUCCESS:
      return {
        ...state,
        isDeleteOrderProcessLoading: false,
      };
    case constants.DELETE_ORDER_PROCESS_FAILURE:
      return {
        ...state,
        isDeleteOrderProcessLoading: false,
      };
    case constants.UPDATE_ORDER_PROCESS_REQUEST:
      return {
        ...state,
        isUpdateOrderProcessLoading: true,
      };
    case constants.UPDATE_ORDER_PROCESS_SUCCESS:
      return {
        ...state,
        isUpdateOrderProcessLoading: false,
      };
    case constants.UPDATE_ORDER_PROCESS_FAILURE:
      return {
        ...state,
        isUpdateOrderProcessLoading: false,
      };
    case constants.REORDER_ORDER_PROCESS_REQUEST:
      return {
        ...state,
        isUpdateOrderProcessLoading: true,
      };
    case constants.REORDER_ORDER_PROCESS_SUCCESS:
      return {
        ...state,
        isUpdateOrderProcessLoading: false,
      };
    case constants.REORDER_ORDER_PROCESS_FAILURE:
      return {
        ...state,
        isUpdateOrderProcessLoading: false,
      };
    case constants.GET_ORDER_PROCESS_BY_ID_REQUEST:
      return {
        ...state,
        isOrderProcessByIdLoading: true,
      };
    case constants.GET_ORDER_PROCESS_BY_ID_SUCCESS:
      return {
        ...state,
        isOrderProcessByIdLoading: false,
        OrderProcess: action.payload,
      };
    case constants.GET_ORDER_PROCESS_BY_ID_FAILURE:
      return {
        ...state,
        isOrderProcessByIdLoading: false,
      };
    case constants.GET_SELECTED_ORDER_PROCESS_ID:
      return {
        ...state,
        setOrderProcessId: action.payload,
      };
    default:
      return state;
  }
};
