import constants from '../Utils/constants';
const initialState = {
  isPrinterloading: false,
  allPrinter: [],
  isCreatePrinteroading: false,
  isDeletePrinterLoading: false,
  isUpdatePrinterLoading: false,
  setPrinterId: '',
  Printer: {},
  isPrinterByIdLoading: false,
  Error: '',
};

export const printerReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PRINTER_REQUEST:
      return {
        ...state,
        isPrinterloading: true,
      };
    case constants.GET_PRINTER_SUCCESS:
      return {
        ...state,
        isPrinterloading: false,
        allPrinter: action.payload,
      };
    case constants.GET_PRINTER_FAILURE:
      return {
        ...state,
        isPrinterloading: false,
      };
    case constants.CREATE_PRINTER_REQUEST:
      return {
        ...state,
        isCreatePrinteroading: true,
      };
    case constants.CREATE_PRINTER_SUCCESS:
      return {
        ...state,
        isCreatePrinteroading: false,
      };
    case constants.CREATE_PRINTER_FAILURE:
      return {
        ...state,
        isCreatePrinteroading: false,
        Error: action.payload?.name,
      };
    case constants.DELETE_PRINTER_REQUEST:
      return {
        ...state,
        isDeletePrinterLoading: true,
      };
    case constants.DELETE_PRINTER_SUCCESS:
      return {
        ...state,
        isDeletePrinterLoading: false,
      };
    case constants.DELETE_PRINTER_FAILURE:
      return {
        ...state,
        isDeletePrinterLoading: false,
      };
    case constants.UPDATE_PRINTER_REQUEST:
      return {
        ...state,
        isUpdatePrinterLoading: true,
      };
    case constants.UPDATE_PRINTER_SUCCESS:
      return {
        ...state,
        isUpdatePrinterLoading: false,
      };
    case constants.UPDATE_PRINTER_FAILURE:
      return {
        ...state,
        isUpdatePrinterLoading: false,
      };
    // case constants.REORDER_PRINTER_REQUEST:
    //   return {
    //     ...state,
    //     isUpdatePrinterLoading: true,
    //   };
    // case constants.REORDER_PRINTER_SUCCESS:
    //   return {
    //     ...state,
    //     isUpdatePrinterLoading: false,
    //   };
    // case constants.REORDER_PRINTER_FAILURE:
    //   return {
    //     ...state,
    //     isUpdatePrinterLoading: false,
    //   };
    case constants.GET_PRINTER_BY_ID_REQUEST:
      return {
        ...state,
        isPrinterByIdLoading: true,
      };
    case constants.GET_QR_CODES_BY_ID_SUCCESS:
      return {
        ...state,
        isPrinterByIdLoading: false,
        Printer: action.payload,
      };
    case constants.GET_QR_CODES_BY_ID_FAILURE:
      return {
        ...state,
        isPrinterByIdLoading: false,
      };
    case constants.GET_SELECTED_QR_CODES_ID:
      return {
        ...state,
        setPrinterId: action.payload,
      };
    default:
      return state;
  }
};
