import constants from '../Utils/constants';
const initialState = {
  isLayoutLoading: false,
  isCreateLayoutLoading: false,
  isUpdateLayoutLoading: false,
  allLayout: [],
  layout: {},
};

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_LAYOUT_REQUEST:
      return {
        ...state,
        isLayoutLoading: true,
      };
    case constants.GET_LAYOUT_SUCCESS:
      return {
        ...state,
        isLayoutLoading: false,
        allLayout: action.payload,
      };
    case constants.GET_LAYOUT_FAILURE:
      return {
        ...state,
        isLayoutLoading: false,
      };
    case constants.CREATE_LAYOUT_REQUEST:
      return {
        ...state,
        isCreateLayoutLoading: true,
      };
    case constants.CREATE_LAYOUT_SUCCESS:
      return {
        ...state,
        isCreateLayoutLoading: false,
      };
    case constants.CREATE_LAYOUT_FAILURE:
      return {
        ...state,
        isCreateLayoutLoading: false,
      };
    case constants.DELETE_LAYOUT_REQUEST:
      return {
        ...state,
        isLayoutLoading: true,
      };
    case constants.DELETE_LAYOUT_SUCCESS:
      return {
        ...state,
        isLayoutLoading: false,
      };
    case constants.DELETE_LAYOUT_FAILURE:
      return {
        ...state,
        isLayoutLoading: false,
      };
    case constants.UPDATE_LAYOUT_REQUEST:
      return {
        ...state,
        isUpdateLayoutLoading: true,
      };
    case constants.UPDATE_LAYOUT_SUCCESS:
      return {
        ...state,
        isUpdateLayoutLoading: false,
      };
    case constants.UPDATE_LAYOUT_FAILURE:
      return {
        ...state,
        isUpdateLayoutLoading: false,
      };
    default:
      return state;
  }
};
