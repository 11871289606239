import API from '../Utils/API';

export const createItem =
  (data, successCreateItem, failureCreateItem) => (dispatch) => {
    dispatch({ type: 'CREATE_ITEM_REQUEST' });
    let formData = new FormData();
    formData.append('category_ids', JSON.stringify(data.category_ids));
    formData.append('item[name]', data.name);
    formData.append('item[price]', data.price);
    formData.append('item[description]', data.description);
    formData.append('item[quantity]', data.quantity);
    formData.append('item[quantity_unit]', data.quantity_unit);
    formData.append('item[item_type_ids]', JSON.stringify(data.item_type_ids));
    data.item_images && formData.append('item[item_images]', data.item_images);
    data.item_3d_image &&
      formData.append('item[item_3d_image]', data.item_3d_image);
    data.item_3d_image_for_ios &&
      formData.append(
        'item[item_3d_image_for_ios]',
        data.item_3d_image_for_ios
      );
    data.item_video && formData.append('item[item_video]', data.item_video);
    formData.append('item[is_gst]', data.is_gst);
    API.post('api/items', formData)
      .then((response) => {
        dispatch({
          type: 'CREATE_ITEM_SUCCESS',
          payload: response.data,
        });
        successCreateItem && successCreateItem();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_ITEM_FAILURE',
          payload: error.response.data,
        });
        failureCreateItem && failureCreateItem(error.response.data);
      });
  };

export const getItems = (url, successCallback) => (dispatch) => {
  dispatch({ type: 'GET_ITEM_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_ITEM_SUCCESS', payload: response.data });
      successCallback && successCallback(response.data);
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_ITEM_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};

export const updateItem = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_ITEM_REQUEST' });
  let formData = new FormData();
  formData.append('category_ids', JSON.stringify(data.category_ids));
  formData.append('item[name]', data.name);
  formData.append('item[price]', data.price);
  formData.append('item[description]', data.description);
  formData.append('item[quantity]', data.quantity);
  formData.append('item[quantity_unit]', data.quantity_unit);
  formData.append('item[item_type_ids]', JSON.stringify(data.item_type_ids));
  data.item_images && formData.append('item[item_images]', data.item_images);
  data.item_3d_image &&
    formData.append('item[item_3d_image]', data.item_3d_image);
  data.item_3d_image_for_ios &&
    formData.append('item[item_3d_image_for_ios]', data.item_3d_image_for_ios);
  data.item_video && formData.append('item[item_video]', data.item_video);
  formData.append('item[is_gst]', data.is_gst);
  API.put(`api/items/${data.id}`, formData)
    .then((response) => {
      dispatch({ type: 'UPDATE_ITEM_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_ITEM_FAILURE',
        payload: error.response.data,
      });
    });
};
export const getItemById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_ITEM_BY_ID_REQUEST' });
  API.get(`api/items/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_ITEM_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_ITEM_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
