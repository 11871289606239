import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { createState, getState, updateState } from '../../Actions/StateAction';
import { stateSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';

const StateModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsStateModalVisible,
    currentPage,
    setCurrentPage,
  } = props;
  const dispatch = useDispatch();
  const [preloadedCountryValue, setPreloadedCountryValue] = useState('');

  const { isStateloading, allState, country, setCountryId } = useSelector(
    (state) => {
      const { country, allCountry, setCountryId } = state.country;
      const { isStateloading, allState } = state.states;
      return {
        isStateloading,
        allState,
        country,
        allCountry,
        setCountryId,
      };
    }
  );
  const { states } = allState;
  const the_state = _.filter(states, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedCountryValue,
    },
    resolver: yupResolver(stateSchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedCountryValue(the_state[0]);
      reset(the_state[0]);
    }
  }, [the_state[0], reset]);

  const handleClose = () => {
    reset(preloadedCountryValue);
    setEditId('');
    setValue('name', '');
    setValue('code', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedCountryValue);
    clearErrors();
  };
  const handleCreate = (data) => {
    data.country_id = country?.id;
    dispatch(createState(data, successCreate, failureCreate));
  };
  const successCreate = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsStateModalVisible(false);
    dispatch(
      getState(`api/states?page=${currentPage}&country_id=${setCountryId}`)
    );
    setValue('name', '');
    setValue('code', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your State Created Successfully');
    }, 1000);
  };

  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name : 'Something went wrong.'
      );
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    dispatch(updateState(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsStateModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('code', '');
    dispatch(
      getState(`api/states?page=${currentPage}&country_id=${setCountryId}`)
    );
    clearErrors();
    setTimeout(() => {
      message.success('Your State Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);
  const [width] = useState(window.innerWidth);
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #f6f6f6 inset',
  };
  return (
    <div style={{ width: 1200 }}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update State ' : 'Add New State'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
        <Spin spinning={isStateloading}>
          <Row gutter={[16, 16]}></Row>

          {/* <Row gutter={[16, 16]} className="button">
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className="formRows">
              <Typography className="formHeading">Country</Typography>
              <Input className="inputLabel" style={inputStyles} value={country?.name} />
            </Col>
          </Row> */}
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='details'>State Name</Typography>
              <Controller
                as={<Input className='input' style={inputStyles} />}
                name='name'
                control={control}
                className='inputLabel'
              />
              {errors.name && (
                <p style={{ color: 'red' }}>{errors.name.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='details'>Code</Typography>
              <Controller
                as={<Input style={inputStyles} />}
                name='code'
                control={control}
                className='inputLabel'
              />
              {errors.code && (
                <p style={{ color: 'red' }}>{errors.code.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[22, 22]} className='button' justify='center'>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {editId ? (
                <Button className='detailsButton' onClick={handleeditCancel}>
                  Cancel
                </Button>
              ) : (
                <Button className='detailsButton' onClick={handleClose}>
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              {editId ? (
                <Button className='detailsButton' htmlType='submit'>
                  Update
                </Button>
              ) : (
                <Button className='detailsButton' htmlType='submit'>
                  Create
                </Button>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
          </Row>
        </Spin>
      </form>
    </div>
  );
};

export default StateModalForm;
