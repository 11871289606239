import React, { useState } from 'react';
import { Row, Col, Breadcrumb, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import StateTaxation from './StateTaxation';

const StateDetails = (props) => {
  const {
    setstateDetailId,
    stateDetailId,
    setcountryTabId,
    setIsModalVisible,
    isModalVisible,
  } = props;
  const [tabKey, setTabkey] = useState('1');
  const { TabPane } = Tabs;

  const dispatch = useDispatch();
  const { State, country } = useSelector((state) => {
    const { State } = state.states;
    const { country } = state.country;
    return {
      country,
      State,
    };
  });
  const handleClose = () => {
    setstateDetailId('');
    setcountryTabId(true);
    dispatch({
      type: 'GET_SELECTED_STATE_ID',
      payload: '',
    });
  };
  const handleCloseState = () => {
    setIsModalVisible(false);
    setstateDetailId('');
  };

  const onTabClick = (key) => {
    setTabkey(key);
  };

  const handleEdit = (id) => {
    setstateDetailId(id);
    setTabkey('1');
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xl={23} lg={23} md={23} sm={23} xs={23}>
        <Breadcrumb>
          <Breadcrumb.Item
            onClick={() => handleClose()}
            style={{ cursor: 'pointer' }}
            className='breadcrumRestaurant'
          >
            {country?.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() => handleCloseState()}
            style={{ cursor: 'pointer' }}
            className='breadcrumRestaurant'
          >
            {State?.name} ({State?.code})
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col>
        {/* <Tabs
            value={tabKey}
            className="tabs"
            defaultActiveKey="1"
            onChange={onTabClick}
            animated={false}
            renderTabBar={(props, DefaultTabBar) => (
              <DefaultTabBar {...props} className="tabs">
                {(node) => (
                  <div key={node.key} index={node.key}>
                    {node}
                  </div>
                )}
              </DefaultTabBar>
            )}
          >
            <TabPane
              tab={
                <Button
                  className={
                    tabKey === "1" ? "selectedButton" : "unselectedButton"
                  }
                >
                  STATE TAXATION
                </Button>
              }
              key="1"
            ></TabPane>
          </Tabs> */}
      </Col>
      <Col>
        {/* {tabKey === "1" ? ( */}
        <StateTaxation
          setTabkey={setTabkey}
          customMethod={handleEdit}
          stateDetailId={stateDetailId}
          setstateDetailId={setstateDetailId}
          setcountryTabId={setcountryTabId}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        {/* ) : (
          ""
        )}  */}
      </Col>
    </Row>
  );
};
export default StateDetails;
