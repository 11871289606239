import API from '../Utils/API';

export const getPrinter = (url) => (dispatch) => {
  dispatch({ type: 'GET_PRINTER_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_PRINTER_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_PRINTER_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createPrinter =
  (data, successCreatePrinter, failureCreatePrinter) => (dispatch) => {
    dispatch({ type: 'CREATE_PRINTER_REQUEST' });
    API.post('api/organization_printers', data)
      .then((response) => {
        dispatch({ type: 'CREATE_PRINTER_SUCCESS', payload: response.data });
        successCreatePrinter && successCreatePrinter();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_PRINTER_FAILURE',
          payload: error.response.data,
        });
        failureCreatePrinter && failureCreatePrinter();
      });
  };
export const deletePrinter =
  (PrinterId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_PRINTER_REQUEST' });
    API.delete(`api/organization_printers/${PrinterId}`)
      .then((response) => {
        dispatch({ type: 'DELETE_PRINTER_SUCCESS', payload: response.data });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_PRINTER_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updatePrinter = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_PRINTER_REQUEST' });
  API.put(`api/organization_printers/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_PRINTER_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_PRINTER_FAILURE',
        payload: error.response.data,
      });
    });
};
export const getPrinterById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_PRINTER_BY_ID_REQUEST' });
  API.get(`api/organization_printers/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_PRINTER_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_PRINTER_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
