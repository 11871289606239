import API from '../Utils/API';

export const getSurveyQuestion = (url) => (dispatch) => {
  dispatch({ type: 'GET_SURVEY_QUESTION_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_SURVEY_QUESTION_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_SURVEY_QUESTION_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createSurveyQuestion =
  (data, successCreateSurveyQuestion, failureCreateSurveyQuestion) =>
  (dispatch) => {
    dispatch({ type: 'CREATE_SURVEY_QUESTION_REQUEST' });
    API.post('api/questions', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_SURVEY_QUESTION_SUCCESS',
          payload: response.data,
        });
        successCreateSurveyQuestion && successCreateSurveyQuestion();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_SURVEY_QUESTION_FAILURE',
          payload: error.response.data,
        });
        failureCreateSurveyQuestion && failureCreateSurveyQuestion();
      });
  };
export const deleteSurveyQuestion =
  (SurveyQuestionId, successDeleteCallback) => (dispatch) => {
    dispatch({ type: 'DELETE_SURVEY_QUESTION_REQUEST' });
    API.delete(`api/questions/${SurveyQuestionId}`)
      .then((response) => {
        dispatch({
          type: 'DELETE_SURVEY_QUESTION_SUCCESS',
          payload: response.data,
        });
        successDeleteCallback();
      })
      .catch((error) => {
        dispatch({
          type: 'DELETE_SURVEY_QUESTION_FAILURE',
          payload: error.response.data,
        });
      });
  };

export const updateSurveyQuestion =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'UPDATE_SURVEY_QUESTION_REQUEST' });
    API.put(`api/questions/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'UPDATE_SURVEY_QUESTION_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack();
      })
      .catch((error) => {
        dispatch({
          type: 'UPDATE_SURVEY_QUESTION_FAILURE',
          payload: error.response.data,
        });
      });
  };
export const getSurveyQuestionById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_SURVEY_QUESTION_BY_ID_REQUEST' });
  API.get(`api/questions/${Id}`)
    .then((response) => {
      dispatch({
        type: 'GET_SURVEY_QUESTION_BY_ID_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_SURVEY_QUESTION_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const reOrderSurveyQuestion =
  (data, successUpdateCallBack) => (dispatch) => {
    dispatch({ type: 'REORDER_SUREVY_QUESTION_REQUEST' });
    API.put(`api/re_order_question/${data.id}`, data)
      .then((response) => {
        dispatch({
          type: 'REORDER_SUREVY_QUESTION_SUCCESS',
          payload: response.data,
        });
        successUpdateCallBack && successUpdateCallBack(response.data);
      })
      .catch((error) => {
        dispatch({
          type: 'REORDER_SUREVY_QUESTION_FAILURE',
          payload: error.response.data,
        });
      });
  };
