import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Avatar,
  Button,
  Popover,
  Divider,
  Space,
  Modal,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getCategoryItem,
  getCategoryItemById,
  updateCategoryItem,
  deleteCategoryItem,
  duplicateCategoryItem,
} from '../../Actions/CategoryItemAction';
import { getCategoryById } from '../../Actions/CategoryAction';
import { useNavigate, useParams } from 'react-router-dom';
import dragvertical from '../../assests/dragvertical.svg';
import { SortableHandle } from 'react-sortable-hoc';
import publish from '../../assests/publish.svg';
import unpublish from '../../assests/unpublish.svg';
import nonVeg from '../../assests/nonVeg.svg';
import veg from '../../assests/veg.svg';
import CategoryItemModalForm from './CategoryItemModalForm';
import Duplicate from '../../assests/Duplicate.svg';
import cart from '../../assests/cart.svg';
import noCart from '../../assests/noCart.svg';
import notAvailable from '../../assests/notAvailable.svg';
import CategoryItemChangeUnavailability from './CategoryItemChangeUnavailability';

const CategoryItems = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [categoryDetailId, setcategoryDetailId] = useState('');
  const [deleteCategoryItemId, setDeleteCategoryItemId] = useState('');
  const [isChangeUnavailabilityOpen, setIsChangeUnavailabityOpen] =
    useState(false);
  const [isType, setIsType] = useState();
  const [isModal, setIsModal] = useState({
    isOpen: false,
    id: null,
    name: null,
  });
  const navigate = useNavigate();
  const { control } = useForm({});
  let UnpublishData = {};
  let PublishData = {};
  const {
    setCategoryId,
    allCategoryItem,
    restaurant,
    Category,
    isCategoryItemloading,
    isDuplicateCategoryItemLoading,
    isDeleteCategoryItemLoading,
  } = useSelector((state) => {
    const { setCategoryId, Category } = state.category;
    const {
      allCategoryItem,
      isCategoryItemloading,
      isDuplicateCategoryItemLoading,
      isDeleteCategoryItemLoading,
    } = state.categoryItem;
    const { restaurant } = state.restaurant;
    return {
      setCategoryId,
      allCategoryItem,
      restaurant,
      Category,
      isCategoryItemloading,
      isDuplicateCategoryItemLoading,
      isDeleteCategoryItemLoading,
    };
  });
  const { length } = allCategoryItem?.category_items || {};
  const [isPublish, setIsPublish] = useState(
    allCategoryItem?.published ? allCategoryItem?.published : true
  );
  const { orgId, categoryId } = useParams();
  useEffect(() => {
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
  }, [dispatch, currentPage]);
  const DragHandle = SortableHandle(() => (
    <img src={dragvertical} style={{ cursor: 'pointer' }} />
  ));

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCategory = () => {
    navigate(`/restaurants/${orgId}/category`);
    dispatch(getCategoryItemById(''));
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ID',
      payload: '',
    });
  };

  const handleCloseCategoryDrawer = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}`);
  };
  const handleCreateItem = (type) => {
    setIsType(type);
    setIsModalVisible(true);
    setEditId(false);
  };
  const handleEdit = (id) => {
    setIsType('new_item');
    setEditId(id);
    setIsModalVisible(true);
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`Category Item Delete Successfully`);
    }, 1000);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
  };
  const handlePublishMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = true;
    dispatch(
      updateCategoryItem(PublishData, successUpdatePublishMethod, faliureDelete)
    );
  };
  const handleUnpublishMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = false;
    dispatch(
      updateCategoryItem(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success('Your Category Unpublished Successfully');
    }, 1000);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
  };
  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success('Your Category Published Successfully');
    }, 1000);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const handleOpen = (Id, itemId) => {
    setcategoryDetailId(itemId);
    setDeleteCategoryItemId(Id);
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${Id}`
    );
    dispatch(getCategoryItemById(Id));
    setDrawerVisible(true);
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ITEM_ID',
      payload: Id,
    });
  };

  const handleDuplicateMethod = (categoryItemId) => {
    const data = {};
    data.category_id = categoryId;
    data.item_id = categoryItemId;

    dispatch(duplicateCategoryItem(data, handleSuccessDuplicateMethod));
  };
  const handleSuccessDuplicateMethod = () => {
    setIsModal({
      isOpen: false,
    });
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
    setTimeout(() => {
      message.success(`Category Item Duplicate Successfully`);
    }, 1000);
  };

  const handleModal = (id, name) => {
    setIsModal({
      isOpen: true,
      id: id,
      name: name,
    });
  };

  const handleChangeUnavailability = (id) => {
    setIsChangeUnavailabityOpen(true);
    setEditId(id);
  };

  const content = (
    <Row justify='center'>
      <Col style={{ margin: '0px', padding: '0px' }}>
        <Button
          className='categoryItemButton'
          onClick={() => handleCreateItem('existing_item')}
        >
          Existing Item
        </Button>
      </Col>
      <Divider style={{ margin: '0px' }} />
      <Col style={{ margin: '0px', padding: '0px' }}>
        <Button
          className='categoryItemButton'
          onClick={() => handleCreateItem('new_item')}
        >
          New Item
        </Button>
      </Col>
    </Row>
  );

  const Columns = [
    {
      className: 'drag-visible3',
      width: '2%',
      render: () => <DragHandle />,
    },
    {
      title: 'Name',
      dataIndex: ['item', 'items_item_types'],
      className: 'drag-visible-name3',
      width: '15%',
      render: (Id, { id, item, item_image_url }) => {
        const isVeg = _.some(item?.items_item_types, ['is_veg', false]);
        return (
          <Row>
            <Tooltip title={`View ${item?.name} Details`}>
              <Col span={24}>
                <Row align='middle' style={{ cursor: 'pointer' }}>
                  <Col span={2}>
                    <Avatar
                      shape='circle'
                      size={40}
                      style={{
                        backgroundImage: `url(${item_image_url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      {item_image_url ? null : item?.name?.charAt(0)}
                    </Avatar>
                    <p
                      style={{
                        position: 'absolute',
                        top: '27px',
                        left: '27px',
                      }}
                    >
                      {item?.items_item_types?.length > 0 ? (
                        isVeg ? (
                          <img src={nonVeg} />
                        ) : (
                          <img src={veg} />
                        )
                      ) : null}
                    </p>
                  </Col>

                  <Col>
                    <Typography
                      style={{ marginLeft: '10px' }}
                      onClick={() => handleOpen(id, item.id)}
                    >
                      {item?.name}
                      <img
                        src={item?.is_available ? '' : notAvailable}
                        style={{ marginLeft: '5px' }}
                      />
                    </Typography>
                  </Col>
                </Row>
              </Col>
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'item',
      className: 'drag-visible-price',
      width: '5%',
      render: (Id, { item }) => {
        return (
          <Row>
            <Col>
              <Typography>₹{item?.display_price}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      className: 'drag-visible-action3',
      width: '5%',
      render: (Id, { id, published, item, category }) => {
        return (
          <Row justify='space-around'>
            <Col>
              {published ? (
                <Tooltip title='Click for UnPublish'>
                  <img
                    className='tablesIcon'
                    src={publish}
                    onClick={() => handleUnpublishMethod(Id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title='Click for Publish'>
                  <img
                    className='tablesIcon'
                    src={unpublish}
                    onClick={() => handlePublishMethod(Id)}
                  />
                </Tooltip>
              )}
            </Col>
            <Col>
              <Tooltip title='Duplicate'>
                <img
                  src={Duplicate}
                  className='tablesIcon'
                  onClick={() => handleModal(item.id, item.name)}
                />
              </Tooltip>
            </Col>
            <Col>
              <img
                src={item?.is_available ? noCart : cart}
                className='tablesIcon'
                onClick={() => handleChangeUnavailability(item.id)}
              />
            </Col>
            <Col>
              <EditColumnAction id={item.id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={Id}
                actionArray={[deleteCategoryItem]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin
      spinning={
        isCategoryItemloading ||
        isDuplicateCategoryItemLoading ||
        isDeleteCategoryItemLoading
      }
    >
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <CategoryItemModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setCategoryId={setCategoryId}
              restaurant={restaurant}
              Category={Category}
              isType={isType}
            ></CategoryItemModalForm>
          ) : isChangeUnavailabilityOpen ? (
            <CategoryItemChangeUnavailability
              isChangeUnavailabilityOpen={isChangeUnavailabilityOpen}
              setIsChangeUnavailabityOpen={setIsChangeUnavailabityOpen}
              setEditId={setEditId}
              editId={editId}
              allCategoryItem={allCategoryItem}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setCategoryId={setCategoryId}
            />
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseCategory()}
                  >
                    Categories
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseCategoryDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {Category?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Items
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Items</Typography>
              </Col>
              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Popover
                      content={content}
                      className='detailsButton'
                      trigger='click'
                    >
                      <Button>Add Item</Button>
                    </Popover>
                  </Col>
                </Row>
              </Col>
              <CustomTable
                type='categoryitems'
                setCategoryId={setCategoryId}
                columns={Columns}
                currentPage={currentPage}
                data={allCategoryItem?.category_items}
              />
              <Modal
                title={
                  <>
                    <Typography className='modalTitle'>{`Duplicate ${isModal.name}`}</Typography>
                    <Typography className='modalDescription'>
                      This particular customization item will be duplicated.
                    </Typography>
                  </>
                }
                centered
                width={380}
                closable={false}
                open={isModal.isOpen}
                footer={
                  <Row gutter={[20, 20]} justify='center'>
                    <Col>
                      <Button
                        size='large'
                        key='cancel'
                        onClick={() => setIsModal(false)}
                        className='orderButton'
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        size='large'
                        key='submit'
                        onClick={() => handleDuplicateMethod(isModal.id)}
                        className='orderButton'
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                }
              ></Modal>
              {allCategoryItem?.category_items && length > 0 ? (
                <Col span={24}>
                  <Row justify='center' style={{ margin: 30 }}>
                    <Pagination
                      total={allCategoryItem?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default CategoryItems;
