import constants from '../Utils/constants';
const initialState = {
  isTablesloading: false,
  allTables: [],
  isCreateTablesoading: false,
  isDeleteTablesLoading: false,
  isUpdateTablesLoading: false,
  setTablesId: '',
  Tables: {},
  isTablesByIdLoading: false,
  Error: '',
};

export const tablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_TABLES_REQUEST:
      return {
        ...state,
        isTablesloading: true,
      };
    case constants.GET_TABLES_SUCCESS:
      return {
        ...state,
        isTablesloading: false,
        allTables: action.payload,
      };
    case constants.GET_TABLES_FAILURE:
      return {
        ...state,
        isTablesloading: false,
      };
    case constants.CREATE_TABLES_REQUEST:
      return {
        ...state,
        isCreateTablesoading: true,
      };
    case constants.CREATE_TABLES_SUCCESS:
      return {
        ...state,
        isCreateTablesoading: false,
      };
    case constants.CREATE_TABLES_FAILURE:
      return {
        ...state,
        isCreateTablesoading: false,
        Error: action.payload?.name,
      };
    case constants.DELETE_TABLES_REQUEST:
      return {
        ...state,
        isDeleteTablesLoading: true,
      };
    case constants.DELETE_TABLES_SUCCESS:
      return {
        ...state,
        isDeleteTablesLoading: false,
      };
    case constants.DELETE_TABLES_FAILURE:
      return {
        ...state,
        isDeleteTablesLoading: false,
      };
    case constants.UPDATE_TABLES_REQUEST:
      return {
        ...state,
        isUpdateTablesLoading: true,
      };
    case constants.UPDATE_TABLES_SUCCESS:
      return {
        ...state,
        isUpdateTablesLoading: false,
      };
    case constants.UPDATE_TABLES_FAILURE:
      return {
        ...state,
        isUpdateTablesLoading: false,
      };
    case constants.GET_TABLES_BY_ID_REQUEST:
      return {
        ...state,
        isTablesByIdLoading: true,
      };
    case constants.GET_TABLES_BY_ID_SUCCESS:
      return {
        ...state,
        isTablesByIdLoading: false,
        Tables: action.payload,
      };
    case constants.GET_TABLES_BY_ID_FAILURE:
      return {
        ...state,
        isTablesByIdLoading: false,
      };
    case constants.GET_SELECTED_TABLES_ID:
      return {
        ...state,
        setTablesId: action.payload,
      };
    default:
      return state;
  }
};
