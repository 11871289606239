import { Button, Col, Modal, Popconfirm, Row, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import Delete from '../../assests/Delete.svg';
import Edit from '../../assests/Edit.svg';
import Duplicate from '../../assests/Duplicate.svg';
import '../CustomTable/CustomTable.css';
import '../Restaurant/Restaurant.css';
import { useState } from 'react';

export const DeleteColumnAction = (props) => {
  const { id, actionArray, customMethod } = props;
  const dispatch = useDispatch();
  const handleDeleteMethod = (Id) => {
    dispatch(actionArray[0](Id, handleSuccessDeleteMethod));
  };
  const handleSuccessDeleteMethod = () => {
    customMethod();
  };
  return (
    <div>
      <Popconfirm
        icon={<Typography></Typography>}
        title={
          <div>
            <Typography className='warning'>Warning!</Typography>
            <Typography className='popconfirmTitle'>
              Do you really want to delete this?
            </Typography>
          </div>
        }
        onConfirm={() => handleDeleteMethod(id)}
        okButtonProps={{ className: 'popconfirmButton' }}
        cancelButtonProps={{ className: 'popconfirmButton' }}
        okText='Confirm'
        cancelText='Cancel'
        placement='topLeft'
      >
        <Tooltip title='Delete'>
          <Button type='link' icon={<img src={Delete} />}></Button>
        </Tooltip>
      </Popconfirm>
    </div>
  );
};

export const EditColumnAction = (props) => {
  const { id, customMethod } = props;
  const handleEditMethod = (Id) => {
    customMethod(Id);
  };

  return (
    <Tooltip title='Edit'>
      {' '}
      <Button
        type='link'
        onClick={() => handleEditMethod(id)}
        icon={<img src={Edit} />}
      ></Button>
    </Tooltip>
  );
};
