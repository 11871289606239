import constants from '../Utils/constants';
const initialState = {
  isUserThemesloading: false,
  isUpdateUserThemesloading: false,
  allUserThemes: [],
  UserThemes: {},
};

export const UserThemesReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USER_THEMES_REQUEST:
      return {
        ...state,
        isUserThemesloading: true,
      };
    case constants.GET_USER_THEMES_SUCCESS:
      return {
        ...state,
        isUserThemesloading: false,
        allUserThemes: action?.payload,
      };
    case constants.GET_USER_THEMES_FAILURE:
      return {
        ...state,
        isUserThemesloading: false,
      };
    case constants.UPDATE_USER_THEME_REQUEST:
      return {
        ...state,
        isUpdateUserThemesloading: true,
      };
    case constants.UPDATE_USER_THEME_SUCCESS:
      return {
        ...state,
        isUpdateUserThemesloading: false,
      };
    case constants.UPDATE_USER_THEME_FAILURE:
      return {
        ...state,
        isUpdateUserThemesloading: false,
      };
    default:
      return state;
  }
};
