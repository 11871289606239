import Axios from 'axios';
const setOrg = (setorg) => {
  if (setorg) {
    Axios.defaults.headers.common['Set-Organization'] = setorg;
  } else {
    delete Axios.defaults.headers.common['Set-Organization'];
  }
};

export default setOrg;
