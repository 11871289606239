import constants from '../Utils/constants';
const initialState = {
  isOrganizationTableloading: false,
  allOrganizationTable: [],
  isCreateOrganizationTableoading: false,
  isDeleteOrganizationTableLoading: false,
  isUpdateOrganizationTableLoading: false,
  setOrganizationTableId: '',
  OrganizationTable: {},
  isOrganizationTableByIdLoading: false,
};

export const organizationTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ORGANIZATION_TABLE_REQUEST:
      return {
        ...state,
        isOrganizationTableloading: true,
      };
    case constants.GET_ORGANIZATION_TABLE_SUCCESS:
      return {
        ...state,
        isOrganizationTableloading: false,
        allOrganizationTable: action.payload,
      };
    case constants.GET_ORGANIZATION_TABLE_FAILURE:
      return {
        ...state,
        isOrganizationTableloading: false,
      };
    case constants.CREATE_ORGANIZATION_TABLE_REQUEST:
      return {
        ...state,
        isCreateOrganizationTableoading: true,
      };
    case constants.CREATE_ORGANIZATION_TABLE_SUCCESS:
      return {
        ...state,
        isCreateOrganizationTableoading: false,
      };
    case constants.CREATE_ORGANIZATION_TABLE_FAILURE:
      return {
        ...state,
        isCreateOrganizationTableoading: false,
      };
    case constants.DELETE_ORGANIZATION_TABLE_REQUEST:
      return {
        ...state,
        isDeleteOrganizationTableLoading: true,
      };
    case constants.DELETE_ORGANIZATION_TABLE_SUCCESS:
      return {
        ...state,
        isDeleteOrganizationTableLoading: false,
      };
    case constants.DELETE_ORGANIZATION_TABLE_FAILURE:
      return {
        ...state,
        isDeleteOrganizationTableLoading: false,
      };
    case constants.UPDATE_ORGANIZATION_TABLE_REQUEST:
      return {
        ...state,
        isUpdateOrganizationTableLoading: true,
      };
    case constants.UPDATE_ORGANIZATION_TABLE_SUCCESS:
      return {
        ...state,
        isUpdateOrganizationTableLoading: false,
      };
    case constants.UPDATE_ORGANIZATION_TABLE_FAILURE:
      return {
        ...state,
        isUpdateOrganizationTableLoading: false,
      };
    case constants.GET_ORGANIZATION_TABLE_BY_ID_REQUEST:
      return {
        ...state,
        isOrganizationTableByIdLoading: true,
      };
    case constants.GET_ORGANIZATION_TABLE_BY_ID_SUCCESS:
      return {
        ...state,
        isOrganizationTableByIdLoading: false,
        OrganizationTable: action.payload,
      };
    case constants.GET_ORGANIZATION_TABLE_BY_ID_FAILURE:
      return {
        ...state,
        isOrganizationTableByIdLoading: false,
      };
    case constants.GET_SELECTED_ORGANIZATION_TABLE_ID:
      return {
        ...state,
        setOrganizationTableId: action.payload,
      };
    default:
      return state;
  }
};
